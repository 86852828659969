import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons'
import UtilFunctions from '../UtilFunctions';
import serviceauth from '../auth/serviceauth';
import isEmpty from 'lodash.isempty';

class SeatAvailability extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            reservedCapacityInfo:{}
        }

        this.handleOpenDrawer = this.handleOpenDrawer.bind(this);
    }

    componentDidMount() {

        
        
        if (this.props.detailsPage) {
            const c = this.props.classItem.CLAS
            fetch(serviceauth.getRequestObject(UtilFunctions.getReservedSeatsAdditionalInfoUrl(c.STRM,c.CLASSNBR,
                c.ACADCAREER,c.CRSEID,c.CRSEOFFERNBR,c.CLASSSECTION,c.SESSIONCODE,c.CLASSTYPE), "GET"))
            .then((response) => {
                
                if (response.status === 401) {
                    serviceauth.reauth();
                }else if(response.status === 403){
                    return {
                        output: false
                    }
                }else
                    return response.json();
            })
            .then( data => {
                // console.log(data)
                this.setState({
                    reservedCapacityInfo:data
                })


            }).catch(function (e) {
                console.log(e);
            });






          
        }
    }

    handleOpenDrawer(e){
      
        this.props.onClick(e,this.props.classItem.CLAS.CRSEID);
    }

    render() {

        var available = null
        if (this.props.seatInfo !== undefined)
            available = (this.props.seatInfo.ENRL_CAP - this.props.seatInfo.ENRL_TOT) > 0 ? this.props.seatInfo.ENRL_CAP - this.props.seatInfo.ENRL_TOT : 0

        var reservedSeats = this.props.reservedSeatsInfo
        var cohortCount = this.props.cohortCountInfo
        var countRows = getCountRow(cohortCount, reservedSeats, this.props.classItem)
        // console.log(this.state.reservedCapacityInfo)
        // console.log(this.props.classItem.CLAS.HASACTIVERESERVEDSEATS)
        // console.log(reservedSeats)
        return (
            <>
                {
                    this.props.seatInfo != null && !this.props.detailsPage &&
                    <div className="text-nowrap">
                        {available} of {this.props.seatInfo.ENRL_CAP}
                        {
                            countRows < available && available > 0 && <FontAwesomeIcon icon={faCircle} className="ms-0 ms-lg-1 me-1 me-lg-0  circle pull-left float-lg-none" />
                        }
                        {
                            available === 0 && <FontAwesomeIcon icon={faTimes} className="ms-0 ms-lg-1 me-1 me-lg-0 times pull-left float-lg-none" />
                        }
                        {
                            countRows >= available && available !== 0 && this.props.classItem.CLAS.HASACTIVERESERVEDSEATS === "N" && <FontAwesomeIcon icon={faCircle} className="ms-0 ms-lg-1 me-1 me-lg-0 circle pull-left float-lg-none" />
                        }
                        {
                            countRows >= available && available !== 0 && this.props.classItem.CLAS.HASACTIVERESERVEDSEATS === "Y" && 
                            <>
                                {
                                    //do not show link in triangle if displaying in related classes dropdown
                                    this.props.isRelatedClass && <FontAwesomeIcon icon={faPlay} className="  triangle ms-1 "  />
                                }
                                {
                                    //show link in triangle if displaying in related classes dropdown
                                    !this.props.isRelatedClass && 
                                    <button className='transparent ms-0 ms-lg-1 pull-left float-lg-none me-1 ' onClick={this.handleOpenDrawer} aria-label="Class Details">
                                        <FontAwesomeIcon icon={faPlay} className="  triangle "  />
                                    </button>
                                }
                                
                            </>
                        }
                        <span className="d-lg-none ">&nbsp;open seats</span>
                    </div>

                }
                {
                    this.props.seatInfo != null && this.props.detailsPage &&
                    <>
                        {
                            isEmpty(this.state.reservedCapacityInfo) && countRows < available && available > 0 && <p>This class has open seats.</p>
                        }
                        {
                            isEmpty(this.state.reservedCapacityInfo) && available === 0 && <p>This class has no open seats.</p>
                        }
                        {
                            isEmpty(this.state.reservedCapacityInfo) && countRows >= available && available !== 0 && this.props.classItem.CLAS.HASACTIVERESERVEDSEATS === "N" && <p>This class has open seats.</p>
                        }
                        {
                            // countRows >= available && available !== 0 && 
                            !isEmpty(this.state.reservedCapacityInfo) &&
                            this.state.reservedCapacityInfo  &&
                            <>
                                <h5 className="font-weight-bold">Reserved Seat Information
                                
                                    {/* <span className="d-lg-none ">&nbsp;open seats</span> */}
                                    {
                                        countRows >= available && available !== 0 && this.props.classItem.CLAS.HASACTIVERESERVEDSEATS === "Y" && <FontAwesomeIcon icon={faPlay} className="ms-1  me-1  triangle " />
                                    }
                                </h5>
                                <p>Seats in this class have been reserved for students in the specified programs, majors or groups listed below. Reserved seats are subject to change without notice.</p>
                                 <table className="table table-sm reserved-seats">
                                    <thead>
                                        <tr>
                                            <th scope="col">Reserved Groups</th>
                                            <th scope="col">Reserved Available Seats</th>
                                            <th scope="col">Students Enrolled</th>
                                            <th scope="col">Total Seats Reserved</th>
                                            <th scope="col">Reserved Until</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            getReservedCapTableBody(this.state.reservedCapacityInfo,available)
                                        }

                                        
                                    </tbody>
                                </table> 

                            </>
                        }
                    </>
                }
            </>
        );
    }
}

export default SeatAvailability



function getCountRow(cohortCount, reservedSeats, classItem) {
    var countrow = 0;
    // countRows = reservedSeats
    if (reservedSeats !== undefined) {
        reservedSeats.forEach(rs => {
            var rosterCount = 0
            if (cohortCount !== undefined) {
                cohortCount.forEach(c => {
                    if (c.RSRV_CAP_NBR === rs.RSRV_CAP_NBR)
                        rosterCount = c.COUNT
                })
            }
            if (classItem.CLAS.CLASSTYPE === "N") {
                rosterCount = rs.STUDENT_ENROLLED
            }

            //  var reserved = { "ENRL_CAP": element.ENRL_CAP, "ENRL_TOT": element.ENRL_TOT,"STUDENT_ENROLLED":element.STUDENT_ENROLLED,"RSRV_CAP_NBR":element.RSRV_CAP_NBR,"ASU_SR_COHORT_CD":element.ASU_SR_COHORT_CD,"ASU_SR_COHORT_CD2":element.ASU_SR_COHORT_CD2 }
            var cohort = rs.RSRV_CAP_NBR >= 101 ? rs.ASU_SR_COHORT_CD2 : rs.ASU_SR_COHORT_CD

            if (cohort != null)
                countrow += (rs.ENRL_CAP - rosterCount);
            else
                countrow += (rs.ENRL_CAP - rs.ENRL_TOT);
        });
    }



    return countrow;
}

function getReservedCapTableBody(reservedCapacityInfo,available){
    var body = []
    var total = 0
    for (let index = 0; index < reservedCapacityInfo.length; index++) {
        const rs = reservedCapacityInfo[index];
        var reservedAvailableSeats = 0
        if(rs.cohort !== ""){
            reservedAvailableSeats = rs.enrlCap - rs.rosterCount
        }else{
            reservedAvailableSeats = rs.enrlCap - rs.enrlTot
        }
        reservedAvailableSeats = reservedAvailableSeats < 0 ? 0 : reservedAvailableSeats
        total += reservedAvailableSeats
        var tr = <tr>
                    <td>
                        {
                            rs.cohort !== "" &&
                            <>{rs.stdnGrp}&nbsp;{rs.cohort}</>
                        }
                        {
                            rs.cohort === "" &&
                            <>{rs.descr254a}</>
                        }
                    </td>
                    <td>
                            { reservedAvailableSeats < 0 ? 0 : reservedAvailableSeats }
                    </td>
                    <td>
                            {
                                rs.cohort !== "" &&
                                <>{rs.rosterCount}</>
                            }
                            {
                                rs.cohort === "" &&
                                <>{rs.enrlTot}</>
                            }
                    </td>
                    <td>
                        {rs.enrlCap}
                    </td>
                    <td>
                        {getFormattedDate(rs.startDt)}
                    </td>
                    

                </tr>
        
        body.push(tr)
    }
    var nonReserved = available - total
    nonReserved = (nonReserved < 0 || available === 0) ? 0 : nonReserved
    body.push(<tr>
                <td colSpan="5">
                    Non Reserved Available Seats: {nonReserved}
                </td>
            </tr>)

    
    return body

    
}

function getFormattedDate(date) {
    if (date === undefined )
        return ""
    if(date === "n/a")
        return date
    try {
        const dateOnly = date.split("T")[0]
        const digits = dateOnly.split("-")
        return (`${digits[1]}/${digits[2]}/${digits[0]}`);


        // var d = new Date(dateOnly)
        // console.log(dateOnly)
        // console.log(date)
        // let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        // let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        // let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        // console.log(mo)
        // return (`${mo}/${da}/${ye}`);
    } catch (error) {
       
    }
    
    
    
    return ""
}