// import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
//import '@asu-design-system/bootstrap4-theme/dist/css/bootstrap-asu.min.css';
// import CatalogSearch from './CatalogSearch';
// import ClassSearch from './ClassSearch';
// import serviceauth from './auth/serviceauth'
import * as serviceWorker from './serviceWorker';
import App from './App';



ReactDOM.render(App.App(), document.getElementById('root'));




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

