import React, { useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

const ClassAccordion = ({ accordionTitle, accordionId, children}) => {
    const [open, setOpen] = useState(false)
    const [isClickable, setIsClickable] = useState(true);

    // Catch space bar and interpret it as a click
    const handleKeyDown = (event, action) => {
        if (event.key === ' ') {
            event.preventDefault(); // stop default space bar from moving the page
            action(event);
        }
    }

    // Pass through click for accordion header link
    const handleClick = (event) => {
        if (!isClickable) {
            event.preventDefault();
            return;
        }

        setIsClickable(false);
        document.getElementById("header-" + accordionId).click();
        setOpen(!open);

        setTimeout(() => {
            setIsClickable(true);
        }, 1000)
    };

    return (
        <React.Fragment>
            <div className="accordion">
                <div className="accordion-item mt-3">
                    <div className="accordion-header">
                        <h3>
                            <a
                                id={"header-" + accordionId}
                                style={{ pointerEvents: isClickable ? "auto" : "none" }}
                                data-bs-toggle="collapse"
                                className="collapsed focus-header"
                                href={"#content-" + accordionId}
                                role="button"
                                aria-expanded="false"
                                aria-controls={accordionId}
                                data-ga="This card unfolds"
                                data-ga-name="onclick"
                                data-ga-event="collapse"
                                data-ga-type="click"
                                data-ga-region="main content"
                                data-ga-section="default"
                                onClick={handleClick}
                                onKeyDown={(event) => handleKeyDown(event, handleClick)}
                            >
                                <span className="faq-accordion-link-text">
                                    {accordionTitle}
                                </span>
                                <FontAwesomeIcon className="faq-accordion-icon" icon={open ? faChevronUp : faChevronDown} />
                            </a>
                        </h3>
                    </div>

                    <div
                        id={"content-" + accordionId}
                        className="collapse"
                        aria-labelledby="card"
                        data-bs-parent=""
                        >
                        <div className="accordion-body">
                            {children}
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default ClassAccordion;
