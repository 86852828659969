import React, { Component } from 'react';
import UtilFunctions from './UtilFunctions';
import isEqual from 'lodash.isequal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import CourseItem from './components/CourseItem';
import Pagination from './components/Pagination'
import Alert from 'react-bootstrap/Alert'
import queryString from 'query-string';
import SortingColumns from './components/SortingColumns'
// import StaticData from './StaticData';
// import HashSet from 'hashset'
//  import isEmpty from 'lodash.isempty';
 import serviceauth from "./auth/serviceauth";
// import MultipleChoiceSelect from './components/MultipleChoiceSelect'

import MediaQuery from 'react-responsive';
// import Card from 'react-bootstrap/Card';
// import Button from "react-bootstrap/Button";
import FilterPaneCatalog from './components/FilterPaneCatalog';

import { Spinner } from '@simply007org/react-spinners';
import SpinnerImage from 'react-bootstrap/Spinner';
import { spinnerService } from '@simply007org/react-spinners';

//import { utimes } from 'fs';

const PAGINATION_LIMIT = 100

class Routed extends Component {
    controller = null
    signal = null
    constructor(props) {
        super(props)
   //      console.log("entered constructor")

        this.state = {
            subject: "",
            searchParams: null,
            results: null,
            waitImage: true,
            aggregations: null,
            locationList: null,
            campusSelectedFilters: [],
            loadForMount: false,
            seatList: null,
            filters: [],
            showError:false,
            showAddFilterError:false
        }

        //this.handleChange = this.handleChange.bind(this)
        // this.handleRemoveFilter = this.handleRemoveFilter.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSortingChange = this.handleSortingChange.bind(this);
        this.handleApplyMoreFiltersChange = this.handleApplyMoreFiltersChange.bind(this)
    }



    componentDidMount() {
        // console.log("entered component did mount")
        const searchParams = UtilFunctions.parseUrl(this.props.location.search)

        

        //get preselected campuses
        // var campusSelectedFilters = [];
        // if (searchParams.campus != null && searchParams.campus !== "") {
        //     campusSelectedFilters = searchParams.campus.split(",")
        // }

        this.setState({
            // locationList: StaticData.locationList,
           // campusSelectedFilters: campusSelectedFilters,
            // searchParams:searchParams,
            loadForMount: true,
            searchTitle: getSearchTitle(searchParams),
            
            
        },()=>{
            
            //spinnerService.hide('mySpinner');
        })

       

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("entered componend did update")


        // const before = Date.now();
        const newParams = UtilFunctions.parseUrl(this.props.location.search)
        const oldParams = UtilFunctions.parseUrl(prevProps.location.search)
        
       

        
           
            
        
            if (!isEqual(oldParams, newParams) || this.state.loadForMount) {
                if(/*hastMinimumSearchFilters(newParams)*/true){
                    //console.log("Entering  component did update fetching data")
                    if (this.controller != null) {
                        this.controller.abort()
                    }
                    this.controller = new AbortController()
                    this.signal = this.controller.signal
                    this.setState({
                        // searchParams: newParams,
                        waitImage: true,
                        loadForMount: false,
                        searchTitle: getSearchTitle(newParams)
                    }, async () => {
                        //  const url = UtilFunctions.getClassSearchUrl(newParams)
                        //  console.log(url)
                        spinnerService.show('mySpinner');
                        if(newParams.term === undefined || newParams.term === ""){//cscc-525
                            newParams.term = await getTerm();
                            this.props.history.push(this.props.match.path + '?' + queryString.stringify(newParams));//push so we have the correct term in the url for future filtering
                        }
                        fetch(serviceauth.getRequestObject(UtilFunctions.getCourseSearchUrl(newParams),"GET"))
                        .then((response) => {
                            if (response.status === 401) {
                              serviceauth.reauth();
                            }
                            return response.json();
                          })
                            .then(data => {
                            // console.log("processing filters")

                            let paginationMax = data.length > PAGINATION_LIMIT ? PAGINATION_LIMIT : data.length

                                this.setState((prevState) => {
                                    const state = {
                                        results: data,
                                        waitImage: false,
                                        searchParams: newParams,
                                        paginationMin:1,
                                        paginationMax:paginationMax ,
                                        totalPages:Math.ceil(data.length/PAGINATION_LIMIT),
                                        page:1,
                                        showAddFilterError:false,
                                        //filters: (!newParams.hasFilter || prevState.filters.length === 0) ? getFilters(data) : prevState.filters,
                                    }
                                    spinnerService.hide('mySpinner');
                                    // console.log("Loading All results took:" + (Date.now() - before) / 1000 + " seconds");
                                    return state
                                })
                                //console.log("Data size " + data.length)
                            }).catch((error) => {
                                console.log(error)
                                this.setState({
                                    waitImage: false,
                                    results: null,
                                    
                                    showError:true
                                },()=>{
            
                                    spinnerService.hide('mySpinner');
                                })
                            });



                    });
                }/*else{
                    this.setState({
                        
                        showAddFilterError:true,
                        results: null,
                        waitImage: false,
                        totalPages:0,
                   
                    },()=>{
                
                        spinnerService.hide('mySpinner');
                    })
                }*/
            } /*else {
                //  console.log("entered component did update but not fetching data")
            }*/
       
       
       
       
        


    }

    // shouldComponentUpdate(nextProps, nextState) {
        
    //     const oldParams = this.state.searchParams
    //     const newParams = UtilFunctions.parseUrl(nextProps.location.search)
    //       console.log("oldParams-->" + JSON.stringify(oldParams) + "<--")
    //       console.log("newParams-->" + JSON.stringify(newParams)+ "<--")
    //     //  console.log("Are they equal? "+ isEqual(oldParams,newParams) ) 
    //     return (!isEqual(oldParams, newParams))


    //     //return true

    // }


     //for checkboxes lists
     handleFilterChange(event, checkedItems) {

        // console.log(checkedItems)
        var array = [];
        checkedItems.forEach((value, key) => {
            if (value) {
                array.push(key);
            }
        })


        var name = event.target.name
        var filters = array.join(',')
        // console.log(filters)




        const searchParams = UtilFunctions.parseUrl(this.props.location.search)
        searchParams[name] = filters
        // console.log(searchParams)

        // console.log(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))

        this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))

    }

    // handleRemoveFilter(filter){
    //     const searchParams = UtilFunctions.parseUrl(this.props.location.search)
    //    // console.log(filter)
    //     searchParams[filter] = null;
    //     this.props.history.push(this.props.match.path + '?' + UtilFunctions.getCourseQueryString(searchParams))
    // }


    handlePageChange(activePage, paginationMin,paginationMax){
        this.setState({
            paginationMin:paginationMin,
            paginationMax:paginationMax,
            page:activePage
        },()=>{
            this.forceUpdate()
            
        })
        spinnerService.hide('mySpinner');
        window.scrollTo(0, 400);
    }

    handleSortingChange(column, direction) {
        // console.log("sorting by "+column +" " +direction)
        const searchParams = UtilFunctions.parseUrl(this.props.location.search)
        if(column === ""){
            delete searchParams.sortby
            delete searchParams.direction
        }else{
            searchParams.sortby = column
            searchParams.direction = direction
        }
        
        // this.setState({
        //     sortColumn:column,
        //     sortDirection:direction
        // })

        this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))
    }

    handleApplyMoreFiltersChange(allFilters) {
        // console.log(allFilters)

        var searchParams = UtilFunctions.parseUrl(this.props.location.search)
        for (var [name, checkedItems] of allFilters) {
                
               
            var array = [];
            if(checkedItems instanceof Map){
                for (const [key, value] of checkedItems){
                    
                    if (value) {
                        array.push(key);
                    }
                }
                var filters = array.join(',')
                searchParams[name] = filters
            }

        }
        this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))
    }

    render() {
        var rowCount = 0
       // var showHeaders = true;
       // var showHeadersMobile = true;
        return (


            <React.Fragment>
                 {/* {this.state.waitImage &&
                    <div className="text-center">
                        <button className="btn btn-primary " type="button" disabled >
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>

                       

                    </div>
                   
                } */}
                 
                 <span className="spinner-span">
                    <Spinner name="mySpinner" >
                        <div variant="primary" disabled className="spinner-button mt-5 spinner-button-styles">
                        <SpinnerImage
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                        />
                        Loading ...
                        </div>
                    </Spinner>
                    </span>   
                 
               {
                   (this.state.showError || this.state.showAddFilterError) && 
                   <Container className="mt-4">
                    <Alert variant="warning" >
                            {
                                this.state.showAddFilterError &&
                                "Please select at least one search parameter."
                            }
                            {
                                 this.state.showError && 
                                "There was an error retrieving the courses. Please try again."
                            }
                    </Alert>
                   </Container>
               }
                
               

                {this.state.results != null &&
                    <Container  className="class-results mt-4 mb-5">
                        <Row>
                            
                            <Col>
                                {
                                    this.state.paginationMax !== 0 &&
                                    <p className="mb-3">Showing {this.state.paginationMin} to {this.state.paginationMax} of {this.state.results.length} courses</p>
                                }
                                
                                <h2 className="search-title">
                                        {/* {this.props.searchParams.subject} {this.props.searchParams.catalogNbr} {this.props.searchParams.keywords} */}
                                        {
                                            this.state.paginationMax !== 0 &&
                                            this.state.searchTitle

                                        }
                                        {
                                            this.state.paginationMax === 0  &&
                                            <>No courses were found that matched your criteria. Please update your search criteria and try again.</>

                                        }

                                    </h2>
                                    {
                                            this.state.paginationMax !==  0 &&
                                            <div className='row'>
                                                <div className="col">
                                                    <div className="float-sm-end">
                                                        <SortingColumns 
                                                        onChange={this.handleSortingChange} 
                                                        sortby={this.props.location.search.sortby} 
                                                        direction={this.props.location.search.direction} 
                                                        isCourseCatalog={true} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    {/* { this.state.paginationMax !== 0 && */}
                                         <FilterPaneCatalog 
                                        data={this.state.results}
                                        searchParams={this.state.searchParams}
                                        onChange={this.handleFilterChange}
                                        onApplyMoreFiltersChange={this.handleApplyMoreFiltersChange}
                                        />
                                    {/* } */}
                                {/* Mobile view */}
                                <MediaQuery query="(max-device-width: 575px)">
                                    
                                        {
                                            this.state.results.map((myClass, index) => {
                                                if(this.state.paginationMin <= (index + 1) && (index + 1) <= this.state.paginationMax){
                                                   //var header = getSubjectsTitle(index, this.state.results,showHeadersMobile)
                                                    //showHeadersMobile = false;
                                                    return  <>
                                                        {/* {header} */}
                                                        {/* <Card body className="mb-3 shadow-sm course-card" key={index}> */}
                                                        <div className="row">
                                                            <div className="card mb-3" key={index}>
                                                                <div className="card-header" />
                                                                    <div className="card-body">
                                                                         <CourseItem course={myClass} 
                                                                        collapse ={this.state.searchParams.collapse} 
                                                                        term={this.state.searchParams.term}
                                                                        key={index}/> 
                                                                        {/* <p class="card-text">this is my card body</p>  */}
                                                                    </div>
                                                            </div>
                                                            {/* <hr /> */}
                                                        </div>
                                                    </>
                                                }else
                                                    return ""
                                               
                                            })
                                        }
                                   
                                </MediaQuery>

                                {/* Desktop/landscape view */}
                                <MediaQuery query="(min-device-width: 576px)">
                                    {
                                        getSubjectsTitle()
                                    }
                                    <ListGroup variant="flush">
                                        {
                                            this.state.results.map((myClass, index) => {
                                                var oddClass = (rowCount % 2 === 0) ? 'even' : 'odd';
                                                rowCount++
                                                if(this.state.paginationMin <= (index + 1) && (index + 1) <= this.state.paginationMax){
                                                   // var header = getSubjectsTitle(index, this.state.results,showHeaders)
                                                    //showHeaders = false;
                                                    return <>
                                                        
                                                        <ListGroup.Item key={index} variant={oddClass} > 
                                                            <CourseItem course={myClass}
                                                                        collapse ={this.state.searchParams.collapse}
                                                                        term={this.state.searchParams.term}
                                                                        key={myClass.CRSEID}
                                                            />
                                                        </ListGroup.Item> 
                                                    </>

                                                }else
                                                    return ""
                                            })}
                                    </ListGroup>
                                </MediaQuery>

                                
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                            {
                                    this.state.paginationMax !== 0 &&
                                    <p className="mb-5">Showing {this.state.paginationMin} to {this.state.paginationMax} of {this.state.results.length} courses</p>
                            }               
                            </Col>
                        </Row> */}
                        {
                            this.state.results.length > 0 &&
                            <Row className='mt-5'>
                                <Col>
                                    <Pagination totalPages={this.state.totalPages} totalItems={this.state.results.length} totalItemsPerPage={PAGINATION_LIMIT} onChange={this.handlePageChange} page={this.state.page}/>
                                </Col>
                            </Row>
                        }
                    </Container>
                }

            </React.Fragment>
        );
    }
}

export default Routed;

// function hastMinimumSearchFilters(data){
//    return  (!isEmpty(data.term) && 
//     (!isEmpty(data.subject) || !isEmpty(data.catalogNbr) ||
//     !isEmpty(data.college) || !isEmpty(data.gen_studies) ||
//     !isEmpty(data.keywords) || !isEmpty(data.level) ) );
// }

function getSearchTitle(searchParams) {
    var title = ""
    if (searchParams.subject)
        title = searchParams.subject

    if (searchParams.catalogNbr) {
        if (title !== "")
            title += " "
        title += searchParams.catalogNbr
    }

    if (searchParams.keywords) {
        if (title !== "")
            title += " "
        title += searchParams.keywords
    }

    return title

}

function getSubjectsTitle(/*index,results,showHeaders*/){
    var title = []
    // if(index===0 || results[index].SUBJECT !==results[index-1].SUBJECT || showHeaders){

        // let titleFormat = 'd-sm-block mt-4'
        // if (index === 0 || index % 100 === 0) {
        //     titleFormat = `d-sm-block mt-0`
        // }

        // title.push(<Row>
        //     <Col className={titleFormat} sm={8}><h3 key={index}>{results[index].SUBJECT} {results[index].SUBJECTDESCR}</h3></Col>
        // </Row>)
        title.push(<Row className='mt-3 gx-0'>
            <Col className='d-none d-sm-block mt-1 font-weight-bold ' sm={2}><span className='ms-1'>Course</span></Col>
            <Col className='d-none d-sm-block mt-1 font-weight-bold ' sm={6}><span className='ms-minus-1'>Title</span></Col>
            <Col className='d-none d-sm-block mt-1 font-weight-bold text-center' sm={1}>Units</Col>
            <Col className='d-none d-sm-block mt-1 font-weight-bold text-center' sm={1}>General&nbsp;Studies</Col>
            <Col className='d-none d-sm-block mt-1 font-weight-bold text-center' sm={1}></Col>
        </Row>)
    // }

    return title
}

async function getTerm(){
    const response = await fetch(serviceauth.getRequestObject(UtilFunctions.getTermListUrl(),"GET"))
    .then((response) => {
    if (response.status === 401) {
        serviceauth.reauth();
    }
        return response.json();
    })
    //console.log(response)
    return response.currentTerm[0].value;
}

// function emptySearchParams(s){
    
//     return s.searchParams === null
// }


// function getFilters(results) {
//     //var locationSet = new HashSet();
//     var collegeSet = new HashSet();
//     var collegeArray = []
    
//     var levelIdSet = new HashSet();
//     var levelSet = new HashSet();
//     var unitsSet = new HashSet();

//     results.forEach((myCourse, index) => {
//         //locationSet.add(myClass.ORIGCRS.CAMPUS)
        
//         //COLLEGES
//         myCourse.COLLEGEMAP.forEach(college => {
            
//             if(collegeSet.add(college.INFO.ACADORG)){
//                 collegeArray.push({id:college.INFO.ACADGROUP,name:college.INFO.DESCRFORMAL});
//             }
//         })
        



//         unitsSet.add((!isEmpty(myCourse.UNITSMAXIMUM) && myCourse.UNITSMAXIMUM < "7") ? myCourse.UNITSMAXIMUM : "7")

//         const levels = getClassLevel(myCourse.CATALOGNBR)
//         levels.forEach((level) => {
//             if (!levelIdSet.contains(level.id)) {
//                 levelIdSet.add(level.id)
//                 levelSet.add(level)
//             }

//         })



//     })





//     //label,id,list
//     return [
//        // ["Location", "campus", mapNames(locationSet.toArray(), StaticData.locationList)],
//         ["College", "college", collegeArray],
        
//         ["Level", "level", levelSet.toArray()],
//         ["Units", "units", mapNames(unitsSet.toArray(), StaticData.unitsList)],
//     ]
        

// }


// function getClassLevel(catalogNbr) {
//     const before = Date.now()
//     var levels = []
//     if (catalogNbr != null && catalogNbr.length > 0) {
//         try {
//             const nbr = parseInt(catalogNbr.substring(0, 3))
//             if (0 <= nbr && nbr <= 499) {
//                 levels.push({ id: "undergrad", name: "Undergrad" })
//             }

//             if (500 <= nbr) {
//                 levels.push({ id: "grad", name: "Graduate" })
//             }

//             if (0 <= nbr && nbr <= 299) {
//                 levels.push({ id: "lowerdivision", name: "Lower division" })
//             }

//             if (300 <= nbr && nbr <= 499) {
//                 levels.push({ id: "upperdivision", name: "Upper division" })
//             }

//             if (100 <= nbr && nbr <= 199) {
//                 levels.push({ id: "100-199", name: "100-199" })
//             } else if (200 <= nbr && nbr <= 299) {
//                 levels.push({ id: "200-299", name: "200-299" })
//             } else if (300 <= nbr && nbr <= 399) {
//                 levels.push({ id: "300-399", name: "300-399" })
//             } else if (400 <= nbr && nbr <= 499) {
//                 levels.push({ id: "400-499", name: "400-499" })
//             }

//         } catch (error) {
//             console.log("Error getting level filters " + error.stack)
//         }
//     }

//     // if(levels.length === 0)
//     //     levels.push({id:"x",name:"x"})

//     console.log("Loading filters  took:" + (Date.now() - before) / 1000 + " seconds");
//     return levels
// }


// //map ids to names, such has DTPX to Downtown Phoenix
// function mapNames(array, nameList) {
//     var results = []


//     array.forEach(element => {
//         var name = null;
//         if (nameList !== null) {
//             name = nameList.find(item => {
//                 return item.id === element
//             })
//         } else {
//             name = { id: element, name: element }
//         }

//         if (name != null)
//             results.push(name)
//     })



//     return results
// }