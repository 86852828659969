import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import serviceauth from './auth/serviceauth';
import HashSet from 'hashset'
import StaticData from './StaticData';
// import Popover from 'react-bootstrap/Popover'
import Modal from 'react-bootstrap/Modal'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'
// import React from 'react';
// import Button from 'react-bootstrap/Button';
// import SpinnerImage from 'react-bootstrap/Spinner';
// import {  SpinnerComponent as Spinner } from '@simply007org/react-spinners';


const HOST_URL = process.env.REACT_APP_API_URL
const SERVICE_URL = HOST_URL + "/catalog-microservices/api/v1/search"

const ENV = process.env.REACT_APP_HOST_ENV
const ENVIRONMENT = (ENV !== "" && ENV !== "prod") ? "-" + ENV : ""
//"https://eadvs-cscc-catalog-microservices-qa.caas-sandbox.asu.edu/catalog-microservices/api/v1/search"
//const SERVICE_URL = "http://catalog-lb-957775361.us-west-2.elb.amazonaws.com/course-catalog/api/v1/search"
// const SERVICE_URL = "http://localhost:5000/course-catalog/api/v1/search"



const UtilFunctions = {
    SERVICE_URL: SERVICE_URL,
    ENV: ENV,
    ENVIRONMENT: ENVIRONMENT,
    //gets url, and returns object with all url fields
    parseUrl(url, filters) {
        //remove anchor
        try {
            url = url.split("#")[0]
        } catch (error) { }

        var parsed = queryString.parse(url);
        // console.log(parsed)


        parsed.campusOrOnlineSelection = this.getCampusOrOnlineSelection()
        return parsed
    },

    getClassSearchUrl(params, filters) {
        // console.log(params)
        var url = SERVICE_URL + "/classes?&refine=Y&" + queryString.stringify(params)
        // if(filters != null){
        //     url = url + "&" +queryString.stringify(filters)
        // }
        // console.log(url)
        return url//SERVICE_URL + "/classes?&refine=Y&" + queryString.stringify(params)
    },
    getClassSearchUrlWithScroll(params, scrollId) {
        return this.getClassSearchUrl(params) + "&scrollId=" + scrollId
    },
    getCourseSearchUrl(params) {
        console.log(SERVICE_URL+"/courses?&refine=Y&" + queryString.stringify(params))
        return SERVICE_URL + "/courses?refine=Y&" + queryString.stringify(params);
    },
    getTermListUrl() {

        return SERVICE_URL + "/terms"
    },
    getUserUrl() {

        return HOST_URL + "/catalog-microservices/api/v1/user/get"
    },
    getSubjectListUrl(term) {

        return SERVICE_URL + "/subjects?term=" + term
    },
    getSubjectSimpleListUrl(term) {

        return SERVICE_URL + "/subjects?sl=Y&term=" + term
    },
    getQueryString(data) {
        
        data.term = data.term != null ? data.term : "2197"
        data.searchType = (data.searchType != null && data.searchType !== "") ? data.searchType : "all"
        data.campusOrOnlineSelection = this.getCampusOrOnlineSelection()//data.campusOrOnlineSelection != null ? data.campusOrOnlineSelection : "C"

        // var params = {
        //     term: data.term != null ? data.term : "2197"
        // }
        // params.searchType = data.searchType != null ? data.searchType : "all"
        // params.campusOrOnlineSelection= data.campusOrOnlineSelection != null ? data.campusOrOnlineSelection : "C"

        // if(data.keywords != null)
        //     params.keywords = data.keywords

        // if(data.college != null)
        //     params.college = data.college

        // if(data.campus != null)
        //     params.campus  = data.campus 

        // if(data.session != null)
        //     params.session = data.session 

        // if(data.level != null)
        //     params.level = data.level 

        // if(data.daysOfWeek != null)
        //     params.daysOfWeek = data.daysOfWeek 

        // if(data.catalogNbr != null)
        //     params.catalogNbr = data.catalogNbr

        // if(data.hon != null)
        //     params.hon = data.hon

        // if(data.subject != null)
        //     params.subject = data.subject

        // if(data.promod != null)
        //     params.promod = data.promod

        // if(data.filter != null)
        //     params.filter = data.filter



        return queryString.stringify(data)
    },
    getCourseQueryString(data) {
        var params = {
            term: data.term != null ? data.term : "2197"
        }
        params.searchType = data.searchType != null ? data.searchType : "all"
        //params.campusOrOnlineSelection= data.campusOrOnlineSelection != null ? data.campusOrOnlineSelection : "C"

        if (data.keywords != null)
            params.keywords = data.keywords

        if (data.college != null)
            params.college = data.college

        // if(data.campus != null)
        //     params.campus  = data.campus 

        if (data.session != null)
            params.session = data.session

        if (data.level != null)
            params.level = data.level

        if (data.daysOfWeek != null)
            params.daysOfWeek = data.daysOfWeek

        return queryString.stringify(params)
    },

    getQueryStringFromState(data) {
        

       
        const params = {
            term: isEmpty(data.term) ? "2191" : data.term,
            subject: isEmpty(data.subject) ? undefined : data.subject,
            catalogNbr: isEmpty(data.catalogNbr) ? undefined : data.catalogNbr,
            searchType: isEmpty(data.searchType) ? "all" : data.searchType,
            keywords: isEmpty(data.keywords) ? undefined : data.keywords,
            college: isEmpty(data.college) ? undefined : (Array.from(data.college)).join(","),
            campus: isEmpty(data.campus) ? undefined : (Array.from(data.campus)).join(","),//we use Array.from to make sure this is an array, from admin page it could be a set
            session: isEmpty(data.session) ? undefined : (Array.from(data.session)).join(","),
            campusOrOnlineSelection: this.getCampusOrOnlineSelection(),//isEmpty(data.campusOrOnlineSelection) ? "C" : data.campusOrOnlineSelection,
            gen_studies: isEmpty(data.gs) ? undefined : (Array.from(data.gs)).join(","),
            instructorName: isEmpty(data.instructor) ? undefined : data.instructor,
            level: isEmpty(data.level) ? undefined : (Array.from(data.level)).join(","),
            classNbr: isEmpty(data.classNbr) ? undefined : data.classNbr,
            promod: data.promod ? "T" : "F",
            honors: data.honors ? "T" : "F",
            // honorsEnrichmentContracts: data.honors ? false : undefined,
            units: isEmpty(data.units) ? undefined : (Array.from(data.units)).join(","),
            daysOfWeek: isEmpty(data.daysOfWeek) ? undefined : (Array.from(data.daysOfWeek)).join(","),
            startDate: isEmpty(data.startDate) ? undefined : this.convertDateForUrl(data.startDate),
            endDate: isEmpty(data.endDate) ? undefined : this.convertDateForUrl(data.endDate),
            startTime: isEmpty(data.startTime) ? undefined : data.startTime,
            endTime: isEmpty(data.endTime) ? undefined : data.endTime,
            advanced: data.advanced,
        }

        return queryString.stringify(params)
    },
    getCatalogQueryStringFromState(data) {
        const params = {
            term: isEmpty(data.term) ? "2197" : data.term,
            subject: isEmpty(data.subject) ? undefined : data.subject,
            catalogNbr: isEmpty(data.catalogNbr) ? undefined : data.catalogNbr,
            // searchType: isEmpty(data.searchType ) ?  "all" :data.searchType ,
            keywords: isEmpty(data.keywords) ? undefined : data.keywords,
            college: isEmpty(data.college) ? undefined : (Array.from(data.college)).join(","),
            campus: isEmpty(data.campus) ? undefined : data.campus.join(","),
            session: isEmpty(data.session) ? undefined : data.session.join(","),
            //campusOrOnlineSelection: isEmpty(data.campusOrOnlineSelection ) ?  "C" : data.campusOrOnlineSelection,
            gen_studies: isEmpty(data.gs) ? undefined : (Array.from(data.gs)).join(","),
            instructorName: isEmpty(data.instructor) ? undefined : data.instructor,
            level: isEmpty(data.level) ? undefined : (Array.from(data.level)).join(","),
            classNbr: isEmpty(data.classNbr) ? undefined : data.classNbr,
            // promod: data.promod ? "T" : "F" ,
            // hon : data.honors ? "T" : "F" ,
            honorsEnrichmentContracts: data.honors ? false : undefined,
            units: isEmpty(data.units) ? undefined : data.units,
            daysOfWeek: isEmpty(data.daysOfWeek) ? undefined : data.daysOfWeek.join(","),
            startDate: isEmpty(data.startDate) ? undefined : data.startDate,
            endDate: isEmpty(data.endDate) ? undefined : data.endDate,
            startTime: isEmpty(data.startTime) ? undefined : data.startTime,
            endTime: isEmpty(data.endTime) ? undefined : data.endTime,
            collapse: isEmpty(data.collapse) ? undefined : true,
             advanced: data.advanced,
        }

        return queryString.stringify(params)
    },
    async getProcessedTermList(fullTermList) {
        var finalList = []
        for (let index = 0; index < fullTermList.length; index++) {
            const element = fullTermList[index];
            finalList.push({ value: element.term, label: element.descr })
        }
        return finalList
    },
    async getProcessedCurrentTerm(fullTermList, currentTerm) {
        const term = await fullTermList.find(item => {
            return item.term === currentTerm
        })



        return await this.getProcessedTermList([term])
    },
    arraysAreEqual(a1, a2) {
        if (a1 !== undefined && a2 !== undefined) {
            return JSON.stringify(a1) === JSON.stringify(a2)
        }
        return false
    },
    isEmpty(s) {
        return s === null || s.length === 0;
    },
    fetchHeaders:
    {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },


    },

    async getClassSeats(classList, seatList, cohortCount, reservedSeats) {

        var classesWithSeats = []


        try {
            for (var i = 0; i <= classList.length; i++) {

                var myClass = classList[i]
                //console.info(myClass)
                if (myClass !== undefined) {
                    //console.log(myClass.CLAS.CLASSNBR)
                    myClass.seatInfo = seatList[myClass.CLAS.CLASSNBR]
                    myClass.cohortCountInfo = cohortCount[myClass.CLAS.CLASSNBR]
                    myClass.reservedSeatsInfo = reservedSeats[myClass.CLAS.CRSEID + myClass.CLAS.CRSEOFFERNBR + myClass.CLAS.CLASSSECTION + myClass.CLAS.SESSIONCODE]
                    classesWithSeats.push(myClass)
                }
            }
            // console.log(classesWithSeats)
            return classesWithSeats
        } catch (error) {
            console.log("seatlist " + seatList)
            console.log("cohortCount " + cohortCount)
            console.log("reservedSeats " + reservedSeats)
            console.log("error in getClassSeats " + error.message);
            return []
        }


    },

    async sortBySeats(classesWithSeats, direction) {

        classesWithSeats.sort((a, b) => {
            var availableA = (a.seatInfo.ENRL_CAP - a.seatInfo.ENRL_TOT) > 0 ? a.seatInfo.ENRL_CAP - a.seatInfo.ENRL_TOT : 0
            var availableB = (b.seatInfo.ENRL_CAP - b.seatInfo.ENRL_TOT) > 0 ? b.seatInfo.ENRL_CAP - b.seatInfo.ENRL_TOT : 0
            var enrlCapA = a.seatInfo.ENRL_CAP
            var enrlCapB = b.seatInfo.ENRL_CAP

            if (direction === "asc")
                return availableA - availableB || enrlCapA - enrlCapB;
            else
                return availableB - availableA || enrlCapB - enrlCapA;


        })

        classesWithSeats.forEach(a => {
            var available = (a.seatInfo.ENRL_CAP - a.seatInfo.ENRL_TOT) > 0 ? a.seatInfo.ENRL_CAP - a.seatInfo.ENRL_TOT : 0
            console.log(available)
        });

        return classesWithSeats
    },

    async getSeatList(term) {

        const url = SERVICE_URL + '/seats?term=' + term;
        // //console.log("seats fetch url " +url)
        // let response = await fetch(serviceauth.getRequestObject(url, "GET"))
        // let seatList = await response.json()
        // return seatList
        return await fetch(serviceauth.getRequestObject(url, "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            }).then(seatList => {
                return seatList
            }).catch(function (e) {
                console.log(e);
            });
    },

    async getCohortCount(term) {

        const url = SERVICE_URL + '/cohortcount?term=' + term;
        // //console.log("seats fetch url " +url)
        // let response = await fetch(serviceauth.getRequestObject(url, "GET"))
        // let cohortCount = await response.json()
        // return cohortCount
        return await fetch(serviceauth.getRequestObject(url, "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            }).then(cohortCount => {
                return cohortCount
            }).catch(function (e) {
                console.log(e);
            });
    },

    async getReservedSeats(term) {

        const url = SERVICE_URL + '/reservedseats?term=' + term;
        //console.log("seats fetch url " +url)
        // let response = await fetch(serviceauth.getRequestObject(url, "GET"))
        // let reservedSeats = await response.json()
        // return reservedSeats

        return await fetch(serviceauth.getRequestObject(url, "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            }).then(reservedSeats => {
                return reservedSeats
            }).catch(function (e) {
                console.log(e);
            });

    },
    getReservedSeatsAdditionalInfoUrl(term, classNbr, acadCareer, crseId, crseOfferNbr, classSection, sessionCode, classType) {


        const url = SERVICE_URL + "/reservedseatsadditionalinfo?term=" + term + "&classNbr=" + classNbr + "&acadCareer=" + acadCareer
            + "&crseId=" + crseId + "&crseOfferNbr=" + crseOfferNbr + "&classSection=" + classSection + "&sessionCode=" + sessionCode + "&classType=" + classType

        // console.log(url)
        return url


    },
    // spinner() {
    //     return <Spinner name="mySpinner" group="foo">
    //             <Button variant="primary" disabled className="spinner-button mt-5">
    //              <SpinnerImage
    //                  as="span"
    //                  animation="grow"
    //                  size="sm"
    //                  role="status"
    //                  aria-hidden="true"
    //                  className="mr-2"
    //              />
    //              Loading ...
    //          </Button>
    //     </Spinner>
    //     //  <>
    //     //     <Button variant="primary" disabled className="spinner-button mt-5">
    //     //         <Spinner
    //     //             as="span"
    //     //             animation="grow"
    //     //             size="sm"
    //     //             role="status"
    //     //             aria-hidden="true"
    //     //             className="mr-2"
    //     //         />
    //     //         Loading...
    //     //     </Button>
    //     // </>
    // },
    getMyScheduleURL() {

        return "https://webapp4" + ENVIRONMENT + ".asu.edu/myasu/schedule"
    },
    getAddURL(term) {

        return "https://www" + ENVIRONMENT + ".asu.edu/go/addclass?STRM=" + term
    },
    getDropURL(term) {

        return "https://www" + ENVIRONMENT + ".asu.edu/go/dropclass?STRM=" + term
    },
    getSwapURL(term) {

        return "https://www" + ENVIRONMENT + ".asu.edu/go/swapclass?STRM=" + term
    },
    getEditURL(term) {

        return "https://www" + ENVIRONMENT + ".asu.edu/go/editclass?STRM=" + term
    },
    getClassBlockURL(term, career) {

        return "https://www" + ENVIRONMENT + ".asu.edu/go/classblocks?STRM=" + term + "&ACAD_CAREER=" + career
    },
    getCheckAdminUserURL() {

        return HOST_URL + "/catalog-microservices/api/v1/admin/isadmin"
    },
    getBookstoreURL() {

        return "https://webapp4" + ENVIRONMENT + ".asu.edu/bookstore/XmlRpcServerDigester?xmlRpcServerUrl="
    },
    getBookstoreURLHOST() {

        return "https://webapp4" + ENVIRONMENT + ".asu.edu/bookstore/XmlRpcServer"
    },
    async getHeaderNavTree(term,page) {

        const appSettings = await this.getAppSettings()
        // console.log(appSettings)
        var navTree = [
            { href: "/catalog/classes", 
              text: "Home", 
              type : "icon-home",
              class: "home" ,
              selected: false}
          ]
          navTree.push(
            { href:  "/catalog/classes", text: "Class Search",selected:page === "classes" }
          )

          navTree.push(
            { href:  "/catalog/courses", text: "Course Catalog Search",selected:page === "courses" }
          )

          navTree.push(
            { href:  "/catalog/faq", text: "FAQ and Guide",selected:page === "faq" }
          )
          
          if(sessionStorage.getItem(serviceauth.SS_IS_STUDENT) === "Y"   && term !==  null){
            navTree.push({
                href: UtilFunctions.getMyScheduleURL(), text: "View My Schedule"
            },
                {
                    text: "Registration",
                    href: "#",
                    "items": [
                        [
                            {
                                href: this.getAddURL(term),
                                text: "Add/Shopping Cart"
                            },
                            {
                                href: this.getDropURL(term),
                                text: "Drop/Withdraw"
                            },
                            {
                                href: this.getSwapURL(term),
                                text: "Swap"
                            },
                            {
                                href: this.getEditURL(term),
                                text: "Edit/Update"
                            }
                            ,
                            {
                                href: this.getClassBlockURL(term, sessionStorage.getItem(serviceauth.SS_CAREER)),
                                text: "Search Class Blocks"
                            }
                        ]
                    ]
                })

            if (sessionStorage.getItem(serviceauth.SS_IS_ONLINE) === "N") {
                // const url = await this.getAppSettings()
                // console.log(url.get("scheduler_url"))
                navTree.push(
                    { href: appSettings.get("scheduler_url"), text: "Schedule Planner" }
                )
            }
        }
        if (appSettings.get("psEnv")) {
            navTree.push(
                { text: "ENVIRONMENT: " + appSettings.get("psEnv") }
            )
        }


        return navTree

        // var nav = [
        //             {
        //                 href : "/catalog/classes",
        //                 text : "Home",
        //                 type : "icon-home",
        //                 selected : true,
        //                 class : "test-class",
        //             },
        //         ]
        // return nav
    },
    async getAppSettings() {

        var settings = sessionStorage.getItem("app.settings")
        var settingsExpireDate = sessionStorage.getItem("app.settings.expire.date")

        if (settings == null || settingsExpireDate < new Date()) {
            // console.log("fetching settings")

            var mySettingsMap = await fetch(serviceauth.getRequestObject(SERVICE_URL + "/appsettings", "GET"))
                .then((response) => {
                    if (response.status === 401) {
                        serviceauth.reauth();
                    }
                    return response.json();
                })
                .then(async (settingsList) => {
                    var settingsMap = new Map()
                    for (let index = 0; index < settingsList.length; index++) {
                        const element = settingsList[index];
                        // console.log(element)
                        settingsMap.set(element.name, element.value)
                    }



                    return settingsMap
                }).catch(err => { console.log(err) });
            sessionStorage.setItem("app.settings", JSON.stringify(Array.from(mySettingsMap.entries())))
            const diff = 5 //minutes
            var newDateObj = new Date((new Date()).getTime() + diff * 60000);
            sessionStorage.setItem("app.settings.expire.date", newDateObj)
            return mySettingsMap
        } else {
            //convert to map
            return new Map(JSON.parse(settings));
        }
    },
    getSelectedFiltersFromSearchParams(searchParams,filtersList) {
        var filters = new HashSet()
        //  for (let i = 0; i < filtersList.length; i++) {
        //      const element = filtersList[i];
        //      if(searchParams[element] !== undefined){
        //          filters[element] = searchParams[element]
        //      }
    
        //  }
    //    console.log(filtersList)
        for (let i = 0; i < filtersList.length; i++) {
            const element = filtersList[i];
            if (searchParams[element] !== undefined) {
                const f = searchParams[element].split(",")
                
                for (let j = 0; j < f.length; j++) {//some filters come separated by , like "TEMPE,WEST"
                    if(f[j].trim() !== "" && StaticData.offCampArray.includes(f[j]) ){//making all off camp one bucket
                        //alert(f[j])
                        filters.add(StaticData.offCampList)
                     } else
                        filters.add(f[j])
    
                }
    
            }
    
        }
        // console.log(Array.from(filters))
        return filters.toArray()
    },
    getCampusOrOnlineSelection(){
        //Overwrite campusOrOnlineSelection so we can force different results if user is authenticated
        const currentUserIsOnline = sessionStorage.getItem(serviceauth.SS_IS_ONLINE)
        const currentUserIsStudent = sessionStorage.getItem(serviceauth.SS_IS_STUDENT)
        const currentUserIsStaff = sessionStorage.getItem(serviceauth.SS_IS_STAFF)
        var campusOrOnlineSelection = "A"
        
        if(currentUserIsStaff !== null && currentUserIsStaff === "N" && currentUserIsStudent !== null && currentUserIsStudent === "Y" && currentUserIsOnline !== null){
            campusOrOnlineSelection = currentUserIsOnline === "Y" ? "O" : "C"
        }
        //console.log("Current user is Online: "+currentUserIsOnline + ". Current User is student: "+currentUserIsStudent+". Current user is staff: "+currentUserIsStaff + ". Campusoronlineselection: "+campusOrOnlineSelection)
    
        return campusOrOnlineSelection
    },
    getCurrentTermList(termList) {
        var currentTermList = []
        for (let index = 0; index < termList.length; index++) {
          const termFromTermList = termList[index];
          if (index < 6) {
            currentTermList.push(termFromTermList)
      
          } else
            break
        }
        //  console.log(currentTermList)
        return currentTermList
      },
      
      getPastTermList(termList) {
        var pastTermList = []
        for (let index = 0; index < termList.length; index++) {
          const termFromTermList = termList[index];
          if (index >= 6) {
            pastTermList.push(termFromTermList)
      
          }
        }
      
        return pastTermList
      }, 
      showArchive(term, termList) {
        var showArchive = false
        for (let index = 0; index < termList.length; index++) {
          const termFromTermList = termList[index];
          if (term === termFromTermList.value && index >= 6) {
            showArchive = true
            break
          }
        }
      
        return showArchive
      },
      getLateAddModal(classItem,modalShow,modalHide) {
        const url = getLateRegistrationURL(classItem)
        return  <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={modalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Late Registration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
        
        <p>The deadline to register for this class has passed. You may not register for the
                     class unless you have authorization from the instructor and the college/school that offers the class.</p>
                    <p> Some departments require additional authorizations.</p>
                {
                     url !== "" &&
                     <p><a href={url} className="font-weight-bold">Late Registration Instructions</a></p>
                }
        </Modal.Body>
        
      </Modal>
        
        
        // <Popover id="late-enroll-popover" className="shadow-lg">
        //     <Popover.Title as="h3" className="mt-0">
        //         Late Registration
        //         <i className="glyphicon glyphicon-user pull-right pointer" onClick={() => document.body.click()} aria-label="Close">
        //             <FontAwesomeIcon icon={faTimes} />
        //         </i>
      
      

        //     </Popover.Title>
        //     <Popover.Content className="">
        //         <p>The deadline to register for this class has passed. You may not register for the
        //             class unless you have authorization from the instructor and the college/school that offers the class.
        //             Some departments require additional authorizations.</p>
        //         {
        //             url !== "" &&
        //             <p><a href={url} className="maroon-bold-hyperlink">Late Registration Instructions</a></p>
        //         }

        //     </Popover.Content>
        // </Popover>
    },
      convertDateToDisplay(date) {
        try {
          var d = date.split("/")
          // console.log(d[2] + "-" + d[0] + "-" + d[1])
          return d[2] + "-" + d[0] + "-" + d[1]
        } catch (error) { }
        return date
      },
      convertDateForUrl(date){
        var x = date.split("-")
        return  x[1] + "/" + x[2] + "/" + x[0]
      },
      isPastEnrollmentDeadline(classEnrollDeadline){//returns true if current date is greater than enrollment deadline date
        const currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        var enrollDeadline = new Date("2522/01/01")//date in the future, just in case enrollment date is undefined
       
        if(classEnrollDeadline){
            enrollDeadline = new Date(classEnrollDeadline.split(" ")[0].replace(/-/g, "/"))//so it works in all browsers
            return enrollDeadline < currentDate
        }
        return false
      },
    //   getGSDesignations(gs){
    //         let gsObj = {
    //             maroon:gs,
    //             gold:"",
    //         }
    //         if(gs && gs.trim() !== ""){
    //             //javascript regex that returns true if there are 4 characters only or 4 characters followed by a space and then the word "OR"  and then anything
    //             const pattern = /^([a-zA-Z]{4}|[a-zA-Z]{4}\sOR\s.*$)$/;
    //             if(pattern.test(gs)){
    //                 let gsArray = gs.split("OR");
    //                 gsObj.gold = gsArray[0].trim();
    //                 gsObj.maroon = gsArray.length > 1 ? gsArray[1].trim() : "";//in case no maroon available
    //             }
    //         }
    //         // console.log(gsObj);
    //         return gsObj;
    //   },
      getGSDesignationsDisplay(gsMaroon,gsGold){
       
        return <div className='gs-block'>
            {
                gsGold && gsGold !== "" &&
                <div>
                    <div className='gs-label gs-pill gs-label-gold'>Gold</div>
                    <div className='gs-text'>{gsGold}</div>
                </div>
            }
            {
                gsMaroon && gsMaroon !== "" &&
                <div className="mt-1p">
                    <div className='gs-label gs-pill gs-label-maroon'>Maroon</div>
                    <div className='gs-text'>{gsMaroon}</div>
                </div>
            }
            
            
        </div>
      },
      getGSText(gsMaroon, gsGold){
    
        let text = [];
        
    
        if(gsGold !== ""){
            text.push(<>
                <div className='gs-label gs-label-gold gs-header mt-2'>General Studies Gold</div>
                <p className='mb-2 mt-1'>{StaticData.GS_GOLD_TEXT}</p>
                <p><span className='font-weight-bold mr-1'> {gsGold}:</span> {StaticData.gsMap.get(gsGold)}</p>
            </>)
        }
    
        if(gsMaroon !== ""){
            
            text.push(<>
                <div className='gs-label gs-label-maroon gs-header mt-4'>General Studies Maroon</div>
                <p className='mb-2 mt-1'>{StaticData.GS_MAROON_TEXT}</p>
                
            </>)
    
            const maroonArray = gsMaroon.split(" ");
            maroonArray.forEach((item) => {
                item = item.replace("(","").replace(")","");
                if(item !== "&" && item !== "or")//remove "&"
                    text.push(<p className='mb-1p'><span className='font-weight-bold mr-1'> {item}:</span> {StaticData.gsMap.get(item)}</p>);
            });
        }
    
        return text
    },
    getGSDesignationsFromURL(gs){//expects a comma separated string
        let gsDesignations = {
            gold:"",
            maroon:[],
        }

        if(gs  && gs.trim() !== ""){
            let gsArray = gs.split(",");

            gsArray.forEach(g => {
                if(g.length === 7){//gold
                    gsDesignations.gold = g;
                }else{
                    gsDesignations.maroon.push(g);
                }
            });
        }


        return gsDesignations;
    }






}

export default UtilFunctions;

function getLateRegistrationURL(classItem) {
    var url = "https://students.asu.edu/late-registration/"
    var page = ""
    switch (classItem.CLAS.ACADGROUP) {
        case "AS":
            page = "new-college"
            break;
        case "BA":
            page = "business"
            break;
        case "CS":
            page = "journalism"
            break;
        case "ES":
            page = "engineering"
            break;
        case "GC":
            page = "graduate"
            break;
        case "HI":
            page = "design-arts"
            break;
        case "HO":
            page = "barrett"
            break;
        case "LA":
            page = "clas"
            break;
        case "LS":
            page = "cisa"
            break;
        case "LW":
            page = "law"
            break;
        case "NU":
            page = "nursing"
            break;
        case "PP":
            page = "public-service"
            break;
        case "TE":
            page = "education"
            break;
        case "UC":
            page = "uc"
            break;
        case "GF":
            page = "global_futures"
            break;
        case "NH":
            page = "health-solutions"
            break;
        case "TB":
            page = "thunderbird"
            break;
        default:
            url = ""
            break;
    }
    return url + page
}


//check if url object includes any filters
// function hasFilter(obj){
//     if("campus" in obj 
//         || "college" in obj
//         || "session" in obj  )
//         return true

//     return false
// }





