var sessionsList =
  [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "DYN", label: "Other" },


  ]
 
const OFFCAMPLIST = "OFFCAMP,OTHERUS,OTHERAZ,OUTSIDEUS,CHANDGILBT,GLENDALE,MESATEMPE,PHOENIX,SCOTTSDALE,SHANGHAI,MEXICOCITY MESACITYCR"
const OFFCAMPARRAY = OFFCAMPLIST.split(",")
var locationList = [
  { value: "TEMPE", label: "Tempe" },
  { value: "WEST", label: "West Valley" },
  { value: "POLY", label: "Polytechnic" },
  { value: "DTPHX", label: "Downtown Phoenix" },
  { value: "ICOURSE", label: "Online: iCourse" },
  { value: "ASUONLINE", label: "ASU Online" },
  { value: "ASUSYNC", label: "ASU Sync" },
  { value: "MESACITYCR", label: "Mesa City Center" },
  { value: "CALHC", label: "Lake Havasu" },
  { value: "AWC", label: "AZ Western College" },
  { value: "CAC", label: "Central AZ College" },
  { value: "COCHISE", label: "Cochise College" },
  { value: "EAC", label: "Eastern AZ College" },
  { value: "TUCSON", label: "Tucson" },
  { value: "LOSANGELES", label: "Los Angeles" },
  { value: "WASHDC", label: "Washington D.C." },
  { value: OFFCAMPLIST, label: "Off-campus" }
  
 
  
  
  // { value: "OTHERAZ", label: "Off-campus" },
  // { value: "OUTSIDEUS", label: "Off-campus" },
  // { value: "CHANDGILBT", label: "Off-campus" },
  // { value: "GLENDALE", label: "Off-campus" },
  // { value: "MESATEMPE", label: "Off-campus" },
  // { value: "PHOENIX", label: "Off-campus" },
  // { value: "SCOTTSDALE", label: "Off-campus" },
  // { value: "AWC", label: "Off-campus" },
  // { value: "EAC", label: "Off-campus" },
  // { value: "TUCSON", label: "Off-campus" },
  // { value: "CAC", label: "Off-campus" },
  // { value: "CALHC", label: "Off-campus" },
  // { value: "COCHISE", label: "Off-campus" },  



]

var onlineLocationList = [
  { value: "ASUONLINE", label: "ASU Online" }
]

var gsListMaroon = [
  { value: "GS-C", label: "C: Cultural Diversity in the U.S." },
  { value: "GS-CS", label: "CS: Computer/Statistics/Quantitative Applications" },
  { value: "GS-G", label: "G: Global Awareness" },
  { value: "GS-H", label: "H: Historical Awareness" },
  { value: "GS-HU", label: "HU: Humanities, Arts and Design" },
  { value: "GS-L", label: "L: Literacy and Critical Inquiry" },
  { value: "GS-MA", label: "MA: Mathematical Studies" },
  { value: "GS-SB", label: "SB:  Social-Behavioral Sciences" },
  { value: "GS-SG", label: "SG: Natural Science - General" },
  { value: "GS-SQ", label: "SQ: Natural Science - Quantitative" }
]

var gsListGold = [
  { value: "GS-HUAD", label: "HUAD: Humanities, Arts and Design" },
  { value: "GS-SOBE", label: "SOBE: Social and Behavioral Sciences" },
  { value: "GS-SCIT", label: "SCIT: Scientific Thinking in Natural Sciences" },
  { value: "GS-QTRS", label: "QTRS: Quantitative Reasoning" },
  { value: "GS-MATH", label: "MATH: Mathematics" },
  { value: "GS-AMIT", label: "AMIT: American Institutions" },
  { value: "GS-CIVI", label: "CIVI: Governance and Civic Engagement" },
  { value: "GS-GCSI", label: "GCSI: Global Communities, Societies and Individuals" },
  { value: "GS-SUST", label: "SUST: Sustainability" },
]

var gsList = gsListMaroon.concat(gsListGold);

let gsMap = new Map()
  gsMap.set("L","Literacy and Critical Inquiry" )
  gsMap.set("MA", "Mathematical Studies" )
  gsMap.set("CS",  "Computer/Statistics/Quantitative Applications" )
  gsMap.set("HU",  "Humanities, Arts and Design" )
  gsMap.set("SB", "Social-Behavioral Sciences" )
  gsMap.set("SQ",  "Natural Science - Quantitative" )
  gsMap.set("SG",  "Natural Science - General" )
  gsMap.set("C", "Cultural Diversity in the U.S." )
  gsMap.set("G", "Global Awareness" )
  gsMap.set("H", "Historical Awareness")

  gsMap.set("HUAD","Humanities, Arts and Design" )
  gsMap.set("SOBE","Social and Behavioral Sciences" )
  gsMap.set("SCIT","Scientific Thinking in Natural Sciences" )
  gsMap.set("QTRS","Quantitative Reasoning" )
  gsMap.set("MATH","Mathematics" )
  gsMap.set("AMIT","American Institutions" )
  gsMap.set("CIVI","Governance and Civic Engagement" )
  gsMap.set("GCSI","Global Communities, Societies and Individuals" )
  gsMap.set("SUST","Sustainability" )
 

var collegeList = [
  { value: "BA", label: "Business", acad_org: "CBA" },
  { value: "HI", label: "Design and the Arts Institute", acad_org: "CHI" },
  { value: "TE", label: "Education", acad_org: "CTE" },
  { value: "ES", label: "Engineering", acad_org: "CES" },
  // { value: "FI", label: "Future of Innovation in Society", acad_org: "CFI" },
  { value: "GF", label: "Global Futures", acad_org: "CGF" },
  { value: "TB", label: "Global Management", acad_org: "CTB" },
  { value: "GC", label: "Graduate College", acad_org: "CGC" },
  { value: "NH", label: "Health Solutions", acad_org: "CHL" },
  { value: "HO", label: "Honors", acad_org: "CHO" },
  { value: "LS", label: "Integrative Sciences and Arts", acad_org: "CLS" },
  { value: "CS", label: "Journalism", acad_org: "CCS" },
  { value: "LW", label: "Law", acad_org: "CLW" },
  { value: "LA", label: "Liberal Arts and Sciences", acad_org: "CLA" },
  { value: "AS", label: "New College", acad_org: "CAS" },
  { value: "NU", label: "Nursing and Health", acad_org: "CNU" },
  { value: "PP", label: "Public Service", acad_org: "CPP" },
  // { value: "SU", label: "Sustainability", acad_org: "CSS" },
  { value: "UC", label: "University College", acad_org: "CUC" }
]

var levelList = [
  { value: "lowerdivision", label: "Lower division" },
  { value: "upperdivision", label: "Upper division" },
  { value: "undergrad", label: "Undergraduate" },
  { value: "grad", label: "Graduate" },
  { value: "100-199", label: "100-199" },
  { value: "200-299", label: "200-299" },
  { value: "300-399", label: "300-399" },
  { value: "400-499", label: "400-499" },



]

var unitsList = [
  { value: "0", label: "Less than 1" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7 or more" },


]

var daysList = [
  { value: "MON", label: "Monday" },
  { value: "TUES", label: "Tuesday" },
  { value: "WED", label: "Wednesday" },
  { value: "THURS", label: "Thursday" },
  { value: "FRI", label: "Friday" },
  { value: "SAT", label: "Saturday" },
  { value: "SUN", label: "Sunday" },
  { value: "M", label: "Monday" },
  { value: "T", label: "Tuesday" },
  { value: "W", label: "Wednesday" },
  { value: "Th", label: "Thursday" },
  { value: "F", label: "Friday" },
  { value: "S", label: "Saturday" },
  { value: "Sa", label: "Sunday" },
]

var daysOfWeekList = [
  { value: "MON", label: "Monday" },
  { value: "TUES", label: "Tuesday" },
  { value: "WED", label: "Wednesday" },
  { value: "THURS", label: "Thursday" },
  { value: "FRI", label: "Friday" },
  { value: "SAT", label: "Saturday" },
  { value: "SUN", label: "Sunday" },
]

let GS_GOLD_TEXT = <>Required for undergraduate students in <span className='font-weight-bold'>2024 or later catalog years</span>. Find your catalog year by visiting <a href='https://my.asu.edu' target='_blank' rel="noreferrer" >My ASU</a>, under My Programs.</>

let GS_MAROON_TEXT = <>Required for undergraduate students in <span className='font-weight-bold'>2023 or earlier catalog years</span>. Find your catalog year by visiting <a href='https://my.asu.edu' target='_blank' rel="noreferrer" >My ASU</a>, under My Programs.</>

// var levelList = [
//   {value:"grad",label:"Graduate"},
//   {value:"lowerdivision",label:"Lower division"},
//   {value:"upperdivision",label:"Upper division"},
//   {value:"100-199",label:"100-199"},
//   {value:"200-299",label:"200-299"},
//   {value:"300-399",label:"300-399"},
//   {value:"400-499",label:"400-499"},
//   {value:"undergrad",label:"Undergrad"},
// ]




const StaticData = {

  sessionsList: sessionsList,

  locationList: locationList,

  onlineLocationList: onlineLocationList,

  gsList: gsList,

  gsListGold: gsListGold,

  gsListMaroon: gsListMaroon,

  collegeList: collegeList,

  levelList: levelList,

  unitsList: unitsList,

  daysList: daysList,
  daysOfWeekList:daysOfWeekList,
  fullList: locationList.concat(collegeList).concat(unitsList).concat(daysList).concat(sessionsList).concat(levelList).concat(gsList),

  gsMap: gsMap,

  offCampList: OFFCAMPLIST,

  offCampArray: OFFCAMPARRAY,

  GS_GOLD_TEXT: GS_GOLD_TEXT,
  GS_MAROON_TEXT: GS_MAROON_TEXT,



}

export default StaticData