import React, { Component } from "react";
import "../css/App.css";
import Container from "react-bootstrap/Container";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import Col from "react-bootstrap/Col";
import AdminUtils from "./AdminUtils";
import SuccessDialogBox from "./SuccessDialogBox";
import ErrorAlert from "./ErrorAlert";
import serviceauth from "../auth/serviceauth";

class UserImpersonation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewAs:"",
            modalShow: false,
            showErrorAlert:false
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        
        fetch(AdminUtils.getRequestObject(AdminUtils.getViewAs(), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }else if(response.status === 403){
                    return {
                        output: false
                    }
                }else
                    return response.json();

            }).then( (response) => {
                // console.log(response.output)
                this.setState({
                    viewAs : response.output
                })
            }).catch(err => { 
                console.log(err);
                this.setState({
                    showErrorAlert:true
                });
            });


        
    }

    handleChange(e) {
        this.setState(
            {
                viewAs: e.target.value
            }
        );
      }

    handleSave() {
        
        fetch(AdminUtils.getRequestObject(AdminUtils.getUpdateViewAsUrl(), "POST", JSON.stringify({
            value: this.state.viewAs
        }))).then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }else if(response.status === 403){
                    return {
                        output: false
                    }
                }else
                    return response.json();

            }).then(async (response) => {
                this.setState({
                    modalShow: true
                })
                // if(response.output === 1){
                //     this.setState({
                //         modalShow: true
                //     })
                // }else{
                //     this.setState({
                //         showErrorAlert:true
                //     })
                // }
            }).catch(err => { 
                console.log(err);
                this.setState({
                    showErrorAlert:true
                });
            });
    }

    handleCancel(e) {
        this.setState(
            {
                viewAs: ""
            }
        ,() => {
            this.handleSave(e)
        });
    }

    render() {
        return (<Container>

            <Form>
                <div className="row">
                    
                    {/* <div className="col" controlId="impersonate">
                        <label>View As (please enter asurite only)</label>
                        <input type="text" value={this.state.viewAs} onChange={this.handleChange}/>
                    </div> */}

                    <form class="uds-form">
                        <div class="form-group">
                        <label for="myTextInput">View As (please enter asurite only)</label>
                        <input
                            type="text"
                            class="form-control"
                            id="myTextInput"
                            placeholder="asurite"
                            value={this.state.viewAs} 
                            onChange={this.handleChange}
                        />
                        </div>
                    </form>
                </div>

                <button type="button" className="btn btn-maroon " onClick={this.handleSave }>
                    Submit
                </button>
                {
                    this.state.viewAs !== null && this.state.viewAs.trim() !== "" &&
                    <button type="button" className="btn btn-maroon ms-3 ms-3" onClick={this.handleCancel}>
                        Cancel View As
                    </button>
                }
                
            </Form>
            <SuccessDialogBox
                show={this.state.modalShow}
                onHide={() => this.setState({modalShow: false})}
                successMessage="View as was updated"
            />

            <ErrorAlert
                show={this.state.showErrorAlert}
                onHide={() => this.setState({ showErrorAlert: false })}
            />

        </Container>)
    }
}

export default UserImpersonation;