import React, { Component } from 'react';
import ClassRow from './ClassRow';
// import Table from 'react-bootstrap/Table';
import UtilFunctions from '../UtilFunctions';
import isEqual from 'lodash.isequal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from './Pagination'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { spinnerService } from '@simply007org/react-spinners';


import SortingColumns from './SortingColumns'
// import SearchFilters from './SearchFilters'


import FilterPane from './FilterPane'
// import HashSet from 'hashset'
// import isEmpty from 'lodash.isempty';
// import StaticData from '../StaticData';

const ALL = "all"

const MAX_RESULTS = 100;

// const columns = [
//     { title: "Course", id: "course", sort: null },
//     { title: "Title", id: "title", sort: null },
//     { title: "Number", id: "classnbr", sort: null },
//     { title: "Instructor", id: "instructor", sort: null },
//     { title: "Days", id: "days", sort: null },
//     { title: "Start", id: "starttime", sort: null },
//     { title: "End", id: "endtime", sort: null },
//     { title: "Location", id: "location", sort: null },
//     { title: "Dates", id: "dates", sort: null },
//     { title: "Units", id: "units", sort: null },
//     { title: "Seats", id: "seats", sort: null },
//     { title: "", id: "syllabus", sort: null },
//     { title: "GS", id: "gs", sort: null },


// ]

// var seatList = null
// var cohortCount = null
// var reservedSeats = null


class ClassTable extends Component {
    constructor(props) {
        super()



        this.state = {
            // spinner: true,
            showBanner2: "N",
            banner2: null,
            showBanner3: "N",
            banner3: null,
            clearFilters: false
            // ifFiltering:false//to control reload of filters, and not reloading if user is filtering
        }
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleHonorsContractsPageChange = this.handleHonorsContractsPageChange.bind(this)
        this.handleSortingChange = this.handleSortingChange.bind(this)
        this.handleUniqueValueFilterChange = this.handleUniqueValueFilterChange.bind(this)
        this.handleApplyMoreFiltersChange = this.handleApplyMoreFiltersChange.bind(this)
        this.handleClearFilters = this.handleClearFilters.bind(this)

    }



    async componentDidMount() {

        //  console.log("Updating seats 1")
        // seatList = await UtilFunctions.getSeatList(this.props.searchParams.term)
        // cohortCount = await UtilFunctions.getCohortCount(this.props.searchParams.term)
        // reservedSeats = await UtilFunctions.getReservedSeats(this.props.searchParams.term)

        var paginatedClassList = await getPaginatedClassList(this.props.classResults, 1, this.props.searchParams.searchType === ALL, getSortBySeatsFromParams(this.props.searchParams))
        var honorsContractsPaginatedClassList = []

        if (this.props.honorsContracts === "T") {
            honorsContractsPaginatedClassList = await getPaginatedClassList(this.props.honorsContractsClassResults, 1, this.props.searchParams.searchType === ALL, getSortBySeatsFromParams(this.props.searchParams))
        }


        const appSettings = await UtilFunctions.getAppSettings()

        //console.log(appSettings)

        this.setState({
            classResults: paginatedClassList,
            totalClassCount: paginatedClassList.length,
            totalClassShown: paginatedClassList.length < MAX_RESULTS ? paginatedClassList.length : MAX_RESULTS,
            paginationMin: 1,
            paginationMax: MAX_RESULTS,
            page: 1,
            honorsContractsClassResults: honorsContractsPaginatedClassList,
            honorsContractsTotalClassCount: honorsContractsPaginatedClassList.length,
            honorsContractsTotalClassShown: honorsContractsPaginatedClassList.length < MAX_RESULTS ? honorsContractsPaginatedClassList.length : MAX_RESULTS,
            honorsContractsPaginationMin: 1,
            honorsContractsPaginationMax: MAX_RESULTS,
            honorsContractsPage: 1,
            // seatList: seatList,
            // cohortCount: cohortCount,
            // reservedSeats: reservedSeats,
            searchTitle: getSearchTitle(this.props.searchParams),
            showBanner2: appSettings.get("banner_status_2"),
            banner2: appSettings.get("banner_text_2"),
            showBanner3: appSettings.get("banner_status_3"),
            banner3: appSettings.get("banner_text_3")
            // waitImage:false

        }, () => {
            spinnerService.hide('mySpinner');
        })
    }

    async componentDidUpdate(prevProps, prevState) {

        // spinnerService.show('mySpinner');
        //when paginating
        if ((prevState.paginationMin !== undefined && prevState.paginationMin !== this.state.paginationMin) ||
            (prevState.honorsContractsPaginationMin !== undefined && prevState.honorsContractsPaginationMin !== this.state.honorsContractsPaginationMin)) {
            // spinnerService.hide('mySpinner');
            // console.log("Updating seats 2")
            // seatList = await UtilFunctions.getSeatList(this.props.searchParams.term)
            // cohortCount = await UtilFunctions.getCohortCount(this.props.searchParams.term)
            // reservedSeats = await UtilFunctions.getReservedSeats(this.props.searchParams.term)
            var paginatedClassList = await getPaginatedClassList(this.props.classResults, this.state.paginationMin, this.props.searchParams.searchType === ALL, getSortBySeatsFromParams(this.props.searchParams))
            var honorsContractsPaginatedClassList = []
            if (this.props.honorsContracts === "T") {
                honorsContractsPaginatedClassList = await getPaginatedClassList(this.props.honorsContractsClassResults, this.state.honorsContractsPaginationMin, this.props.searchParams.searchType === ALL, getSortBySeatsFromParams(this.props.searchParams))
            }

            this.setState({
                classResults: paginatedClassList,
                honorsContractsClassResults: honorsContractsPaginatedClassList
                // spinner: false
            }, () => {
                spinnerService.hide('mySpinner');
            })
        }

        else if (!isEqual(this.props.classResults, prevProps.classResults) || (!isEqual(this.props.honorsContractsClassResults, prevProps.honorsContractsClassResults)) || this.props.searchParams.searchType !== prevProps.searchParams.searchType) {

            // spinnerService.hide('mySpinner');
            // if (!this.props.backgroundLoad || seatList === null || cohortCount === null || reservedSeats === null) {
            //     // console.log("Updating seats 3")
            //     seatList = await UtilFunctions.getSeatList(this.props.searchParams.term)
            //     cohortCount = await UtilFunctions.getCohortCount(this.props.searchParams.term)
            //     reservedSeats = await UtilFunctions.getReservedSeats(this.props.searchParams.term)
            // }


            // console.log(JSON.stringify(this.props.classResults))
            paginatedClassList = await getPaginatedClassList(this.props.classResults, this.state.paginationMin, this.props.searchParams.searchType === ALL, getSortBySeatsFromParams(this.props.searchParams))
            //var honorsContractPaginatedClassList = []
            if (this.props.honorsContracts === "T") {
                honorsContractsPaginatedClassList = await getPaginatedClassList(this.props.honorsContractsClassResults, this.state.honorsContractsPaginationMin, this.props.searchParams.searchType === ALL, getSortBySeatsFromParams(this.props.searchParams))
            } else {
                honorsContractsPaginatedClassList = []
            }
            //console.log("my size is "+this.props.classResults.length +" pagmin "+this.state.paginationMin + " pagmax " + this.state.paginationMax)
            // console.log(this.props.__self.state.scrollId)
            // console.log("backgroundLoad-->"+this.props.backgroundLoad)
            this.setState({
                classResults: paginatedClassList,
                honorsContractsClassResults: honorsContractsPaginatedClassList,
                // seatList: seatList,
                // cohortCount: cohortCount,
                // reservedSeats: reservedSeats,
                totalClassCount: paginatedClassList.length,
                totalClassShown: paginatedClassList.length < MAX_RESULTS ? paginatedClassList.length : MAX_RESULTS,
                paginationMin: 1,
                paginationMax: MAX_RESULTS,
                searchTitle: getSearchTitle(this.props.searchParams),
                page: 1,
                honorsContractsTotalClassCount: honorsContractsPaginatedClassList.length,
                honorsContractsTotalClassShown: honorsContractsPaginatedClassList.length < MAX_RESULTS ? honorsContractsPaginatedClassList.length : MAX_RESULTS,
                honorsContractsPaginationMin: 1,
                honorsContractsPaginationMax: MAX_RESULTS,
                honorsContractsPage: 1,
                // spinner: false

            }, () => {
                spinnerService.hide('mySpinner');
            })
        } else {//else, just update the count
            // console.log("component did update 3")
            var total = this.props.classResults.length
            var honorsContractsTotal = 0
            if (this.props.honorsContracts === "T") {
                honorsContractsTotal = this.props.honorsContractsClassResults.length
            }
            if (this.props.searchParams.searchType !== ALL) {
                total = await getAllOpenSeatsCount(this.props.classResults)
                if (this.props.honorsContracts === "T") {
                    honorsContractsTotal = await getAllOpenSeatsCount(this.props.honorsContractsClassResults)
                }
            }

            if (total !== this.state.totalClassCount || honorsContractsTotal !== this.state.honorsContractsTotalClassCount) {
                //only process list if user is hiding the full classes, so that count is correct
                var totalClassCount = total !== this.state.totalClassCount ? total : this.state.totalClassCount
                var honorsContractsTotalClassCount = honorsContractsTotal !== this.state.honorsContractsTotalClassCount ? honorsContractsTotal : this.state.honorsContractsTotalClassCount

                this.setState({
                    totalClassCount: totalClassCount,
                    honorsContractsTotalClassCount: honorsContractsTotalClassCount,
                    // spinner:false

                }, () => {
                    console.log("state change")
                    spinnerService.hide('mySpinner');
                })
            }else{
                //only update the search title since there is a possibility that the search title is different but the results are the same
                if(this.state.searchTitle !== getSearchTitle(this.props.searchParams)){
                    this.setState({ searchTitle: getSearchTitle(this.props.searchParams) })
                }
            }




        }



    }

    handleFilterChange(event, newCheckedItems) {

        this.props.onChange(event, newCheckedItems);
    }

    handleApplyMoreFiltersChange(newCheckedItems) {
        //console.log(newCheckedItems)
        this.props.onApplyMoreFiltersChange(newCheckedItems);
    }

    // handleFilterChange(name,newCheckedItems){

    //     this.props.onChange(name, newCheckedItems);
    // }

    handleUniqueValueFilterChange(e) {
        //alert(e.target.value)
        this.props.onUniqueValueFilterChange(e)
    }

    handlePageChange(page, paginationMin, paginationMax) {

        this.setState({
            paginationMin: paginationMin,
            paginationMax: paginationMax,
            page: page
        }, () => {
            window.scrollTo(0, 400)
        })
    }

    handleHonorsContractsPageChange(page, paginationMin, paginationMax) {

        this.setState({
            honorsContractsPaginationMin: paginationMin,
            honorsContractsPaginationMax: paginationMax,
            honorsContractsPage: page
        }, () => {
            window.scrollTo(0, document.body.scrollHeight / 2);
        })
    }

    handleSortingChange(column, direction) {
        // console.log("sorting by "+column +" " +direction)
        this.props.onSorting(column, direction)
    }

    handleClearFilters() {

        this.props.clearFilters();
    }

    render() {
        //  console.log(JSON.stringify(this.props.searchParams))
        //  console.log("this.state.honors "+this.state.honors)
        // if (this.state.totalClassCount <= 0) {//hide spinner if no results found
        //     console.log("about to hide 6")
        //     spinnerService.hide('mySpinner');
        // }
        var filtersData = this.props.classResults
        if (this.props.honorsContracts === "T" && this.props.honorsContractsClassResults.length > 0) {
            filtersData = this.props.classResults.concat(this.props.honorsContractsClassResults)
        }
        //to control css background of each row
        var odd = false;


        return (
            <>
                {
                    this.state.showBanner3 === "Y" &&
                    ((this.props.searchParams.subject === "ENG" && this.props.searchParams.catalogNbr === "101") ||
                        (this.props.searchParams.keywords && this.props.searchParams.keywords.toUpperCase().trim() === "ENG 101")) &&
                    UtilFunctions.getCampusOrOnlineSelection() !== "O" &&

                    // <Row className="pt-3 pb-3 advancedPane flex">
                    //     <Col sm="1">
                    //         <h4 className="m-0 float-md-end float-start"><FontAwesomeIcon icon={faInfoCircle} className="" /></h4>
                    //     </Col>
                    //     <Col sm="7">
                    //         <div dangerouslySetInnerHTML={{ __html: this.state.banner3 }} />
                    //     </Col>
                    // </Row>
                    <div className="row g-0">
                        <div
                            className="col uds-full-width"
                            id="html-root"
                        >
                            <section className="banner-gray">
                                <div
                                    className="banner uds-content-align"
                                    role="banner"
                                >
                                    <div className="banner-icon">
                                        <FontAwesomeIcon icon={faBell} className="me-2" title="Banner" />
                                    </div>
                                    <div className="banner-content pt-1">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.banner3 }} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }
                <Container fluid={true} className="class-results">

                    <Row>
                        <Col>
                            {/* {
                                this.state.spinner &&
                                UtilFunctions.spinner()
                            } */}

                            {
                                this.state.classResults != null &&

                                <React.Fragment>
                                    {
                                        this.state.totalClassCount > 0 &&
                                        //this.props.honorsContracts !== "T" &&
                                        <div className="row mb-1 mt-4">
                                            <div className="col-sm-auto ">
                                                <p >Showing {this.state.paginationMin} to {this.state.paginationMin + this.state.classResults.length - 1} of {this.state.totalClassCount}  </p>
                                            </div>

                                        </div>
                                    }

                                    <div className="row">
                                        <div className="col-sm-7 col-md-10">
                                            {
                                                //this.state.totalClassCount > 0 &&
                                                //   this.props.honorsContracts !== "T" &&
                                                <h2 className="search-title  mt-2">{this.state.searchTitle}</h2>

                                            }


                                        </div>
                                        {
                                            this.state.totalClassCount > 0 &&
                                            <div className="col-sm-5 col-md-2">
                                                <div className=' float-md-end'>
                                                    <SortingColumns onChange={this.handleSortingChange} sortby={this.props.searchParams.sortby} direction={this.props.searchParams.direction} />
                                                </div>
                                            </div>
                                        }

                                    </div>


                                    {
                                        // this.props.honorsContracts !== "T" &&
                                        // (this.props.searchParams.honors === "T" || this.state.classResults.length > 0 || this.state.honorsContractsClassResults.length > 0) &&
                                        <FilterPane data={filtersData}
                                            searchParams={this.props.searchParams}
                                            onChange={this.handleFilterChange}
                                            onUniqueValueFilterChange={this.handleUniqueValueFilterChange}
                                            onApplyMoreFiltersChange={this.handleApplyMoreFiltersChange}
                                            resultsCount={this.state.classResults.length}
                                            clearFilters={this.handleClearFilters}
                                        />
                                    }
                                    {/* {
                                 columns != null &&
                                 <SortingColumns columns={columns} onChange={this.handleSortingChange}/>
                             } */}
                                    <div className="row">
                                        <div className="col-sm-7 ">
                                            {
                                                this.state.totalClassCount <= 0 &&
                                                this.props.honorsContracts !== "T" &&
                                                <>
                                                    <h2 className="search-title ">No classes found</h2>
                                                    <p className='font-16'>We couldn't find any classes with those search terms or filter options.
                                                        Please check spelling, try a different keyword, or choose fewer filter options.
                                                        If you need help determining how to search for classes,
                                                        please visit the <a href='https://docs.google.com/document/d/e/2PACX-1vRskOVXbAyDzc_rSXaKzqGouKxajil92K2InHxL43vzAkqz6Vp3vpPoKkZWdhiDBYcPnHicMCnRRETl/pub' target="_blank" rel='noreferrer'>Class Search FAQ and Guide</a>.</p>
                                                </>

                                            }

                                        </div>
                                    </div>

                                    <Row>

                                        <Col>
                                            {
                                                this.state.classResults.length > 0 &&
                                                <div id="class-results" >
                                                    <div className="class-results-rows" >
                                                        <div id="class-results-header" className="class-results-header">
                                                            {/* <div className="class-results-class-header-title-container"> */}
                                                            <div id="" className="course class-results-header-cell">Course</div>
                                                            <div id="" className="title class-results-header-cell">Title</div>
                                                            <div id="" className=" number nowrap class-results-header-cell">Number</div>
                                                            {/* </div> */}

                                                            <div id="" className="instructor class-results-header-cell">Instructor(s)</div>
                                                            <div id="" className="days class-results-header-cell">Days</div>
                                                            <div id="" className="start class-results-header-cell">Start</div>
                                                            <div id="" className="end class-results-header-cell">End</div>
                                                            <div id="" className="location class-results-header-cell">Location</div>
                                                            <div id="" className="dates class-results-header-cell">Dates</div>
                                                            <div id="" className="units class-results-header-cell">Units</div>
                                                            <div id="" className="seats class-results-header-cell">Open Seats</div>
                                                            {/* <div id="" className="syllabus class-results-header-cell"></div> */}
                                                            <div id="" className="gs class-results-header-cell">General Studies</div>
                                                            <div id="" className="add class-results-header-cell"></div>
                                                        </div>


                                                        {this.state.classResults.map((myClass, index) => {
                                                            odd = !odd
                                                            return <ClassRow classItem={myClass} key={index} searchType={this.props.searchParams.searchType} oddEvenClass={odd} />
                                                        })}
                                                    </div>

                                                </div>
                                            }
                                        </Col>

                                    </Row>

                                    {

                                        this.state.classResults.length > 0 &&

                                        <Row className="pb-5 pt-3">

                                            <Col>
                                                <Pagination
                                                    totalPages={Math.ceil(this.state.totalClassCount / MAX_RESULTS)}
                                                    totalItemsPerPage={MAX_RESULTS}
                                                    onChange={this.handlePageChange}
                                                    page={this.state.page}
                                                    classResultsTotal={this.props.classResults.length}
                                                    classTotal={this.props.classTotal} />
                                            </Col>

                                        </Row>
                                    }
                                    {
                                        this.props.honorsContracts === "T" && this.state.honorsContractsTotalClassCount > 0 &&
                                        <>
                                            <div className="font-weight-bold container pb-5">The classes listed below offer Honors Enrichment Contracts; to inquire about contracts for classes not identified below, please contact the instructor.</div>
                                            <Row>
                                                <Col>
                                                    <div id="class-results" >
                                                        <div className="class-results-rows" >
                                                            <div id="class-results-header" className="class-results-header">
                                                                {/* <div className="class-results-class-header-title-container"> */}
                                                                <div id="" className="course class-results-header-cell">Course</div>
                                                                <div id="" className="title class-results-header-cell">Title</div>
                                                                <div id="" className=" number nowrap class-results-header-cell">Number</div>
                                                                {/* </div> */}

                                                                <div id="" className="instructor class-results-header-cell">Instructor(s)</div>
                                                                <div id="" className="days class-results-header-cell">Days</div>
                                                                <div id="" className="start class-results-header-cell">Start</div>
                                                                <div id="" className="end class-results-header-cell">End</div>
                                                                <div id="" className="location class-results-header-cell">Location</div>
                                                                <div id="" className="dates class-results-header-cell">Dates</div>
                                                                <div id="" className="units class-results-header-cell">Units</div>
                                                                <div id="" className="seats class-results-header-cell">Open Seats</div>
                                                                {/* <div id="" className="syllabus class-results-header-cell"></div> */}
                                                                <div id="" className="gs class-results-header-cell">General Studies</div>
                                                                <div id="" className="add class-results-header-cell"></div>
                                                            </div>


                                                            {this.state.honorsContractsClassResults.map((myClass, index) => {
                                                                odd = !odd
                                                                return <ClassRow classItem={myClass} key={index} searchType={this.props.searchParams.searchType} oddEvenClass={odd} />
                                                            })}
                                                        </div>

                                                    </div>


                                                </Col>

                                            </Row>


                                            <Row className="pb-5 pt-3">

                                                <Col>
                                                    <Pagination totalPages={Math.ceil(this.state.honorsContractsTotalClassCount / MAX_RESULTS)} totalItemsPerPage={MAX_RESULTS} onChange={this.handleHonorsContractsPageChange} page={this.state.honorsContractsPage} />
                                                </Col>

                                            </Row>
                                        </>
                                    }


                                </React.Fragment>
                            }
                            {
                                // (this.state.classResults === null || this.state.totalClassCount === 0) &&
                                // <div className="mb-5 mt-5 container">
                                //         {/* <Alert variant="warning" > */}
                                //         No classes were found that matched your criteria. Please update your search criteria and try again.
                                //     {/* </Alert> */}


                                // </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </>








        );
    }
}

export default ClassTable

async function getPaginatedClassList(classesWithSeats, paginationMin, showAllClasses, sortDirection) {

    var paginatedClassList = []
    //Sort by seats if needed
    if (sortDirection) {
        await UtilFunctions.sortBySeats(classesWithSeats, sortDirection)
    }
    //var classesWithSeats = await UtilFunctions.getClassSeats(classList, seatList, cohortCount, reservedSeats)

    //first add seat availability
    //console.log(classesWithSeats)



    try {

        var classesWithSeatsIndex = (paginationMin - 1)

        for (var i = 0; i < MAX_RESULTS;) {
            var myClass = classesWithSeats[classesWithSeatsIndex++]

            if (myClass !== undefined) {
                // console.log(showAllClasses)
                if (myClass.seatInfo === undefined || showAllClasses || ((myClass.seatInfo.ENRL_CAP - myClass.seatInfo.ENRL_TOT) > 0)) {
                    paginatedClassList.push(myClass)
                    i++
                }
            } else {

                break;
            }
        }

        return paginatedClassList
    } catch (error) {
        console.log("error in getPaginatedClassList " + error.message);
    }

}

//to count 
async function getAllOpenSeatsCount(classesWithSeats) {

    var count = 0
    // var classesWithSeats = await UtilFunctions.getClassSeats(classList, seatList, cohortCount, reservedSeats)




    try {


        for (var i = 0; i < classesWithSeats.length; i++) {
            var myClass = classesWithSeats[i]
            if (myClass !== undefined) {

                if (myClass.seatInfo === undefined || ((myClass.seatInfo.ENRL_CAP - myClass.seatInfo.ENRL_TOT) > 0)) {

                    count++
                }
            } else {

                break;
            }
        }


    } catch (error) {

        console.log("error in getPaginatedClassList " + error.message);
    }

    return count

}

function getSearchTitle(searchParams) {
    // console.trace() 
    //console.log(searchParams)
    var title = ""
    if (searchParams.subject)
        title = searchParams.subject



    if (searchParams.catalogNbr) {
        if (title !== "")
            title += "  "
        title += searchParams.catalogNbr
    }

    if (searchParams.keywords) {
        if (title !== "")
            title += "  "
        title += searchParams.keywords
    }

    return "Results for " + title

}

function getSortBySeatsFromParams(params) {
    if (params.sortby && params.sortby === "openseats")
        return params.direction
    else
        return undefined
}

// async function getClassSeats(classList, seatList,cohortCount,reservedSeats) {
//    //console.info(cohortCount)
//     var classesWithSeats = []


//     try {
//         for (var i = 0; i <= classList.length; i++) {

//             var myClass = classList[i]

//             if (myClass !== undefined) {
//                 //console.log(myClass.CLAS.CLASSNBR)
//                 myClass.seatInfo = seatList[myClass.CLAS.CLASSNBR]
//                 myClass.cohortCountInfo = cohortCount[myClass.CLAS.CLASSNBR]
//                 myClass.reservedSeatsInfo = reservedSeats[myClass.CLAS.CRSEID + myClass.CLAS.CRSEOFFERNBR + myClass.CLAS.CLASSSECTION + myClass.CLAS.SESSIONCODE]
//                 classesWithSeats.push(myClass)
//             }
//         }

//         return classesWithSeats
//     } catch (error) {
//         console.log("error in getClassSeats " + error.message);
//         return []
//     }


// }



// async function getSeatList(term) {

//     const url = UtilFunctions.SERVICE_URL + '/seats?term=' + term;
//     //console.log("seats fetch url " +url)
//     let response = await fetch(serviceauth.getRequestObject(url, "GET"))
//     let seatList = await response.json()
//     return seatList

// }

// async function getCohortCount(term) {

//     const url = UtilFunctions.SERVICE_URL + '/cohortcount?term=' + term;
//     //console.log("seats fetch url " +url)
//     let response = await fetch(serviceauth.getRequestObject(url, "GET"))
//     let cohortCount = await response.json()
//     return cohortCount

// }

// async function getReservedSeats(term) {

//     const url = UtilFunctions.SERVICE_URL + '/reservedseats?term=' + term;
//     //console.log("seats fetch url " +url)
//     let response = await fetch(serviceauth.getRequestObject(url, "GET"))
//     let reservedSeats = await response.json()
//     return reservedSeats

// }





