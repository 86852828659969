import React, { Component } from "react";
import "../css/App.css";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import serviceauth from "../auth/serviceauth";
import UtilFunctions from "../UtilFunctions";
import Select from 'react-select';
import AdminUtils from "./AdminUtils";
import SuccessDialogBox from "./SuccessDialogBox";
import ErrorAlert from "./ErrorAlert";

class ActivateTerm extends Component {
   intervalID;
    constructor(props) {
        super(props);
        this.state = {
            currentTerm: "",
            allTerms: [],
            modalShow: false,
            showErrorAlert: false,
            currentESTermList: null,
            pastESTermList: null,
            currentTermDescr:"",
            esRefreshStatus:null,
            showESSettings: false,
            showActivateButton:true,
        };
        this.handleDeleteESSetting = this.handleDeleteESSetting.bind(this);
        this.handleESRefresh = this.handleESRefresh.bind(this);
    }

    componentDidMount() {
        //show/hide es setting buttons
        fetch(serviceauth.getRequestObject(UtilFunctions.getUserUrl(), "GET"))
            .then((response) => {
                
                if (response.status === 401) {
                    serviceauth.reauth();
                }else if(response.status === 403){
                    return {
                        output: false
                    }
                }else
                    return response.json();
            })
            .then(async data => {
                //console.log(data)
                if (data.user === "lcabre" || data.user === "rchave2"){
                    this.setState({
                        showESSettings: true
                    })
                }else{
                    this.setState({
                        showESSettings: false
                    })
                }


            }).catch(function (e) {
                console.log(e);
            });

        

        //get current term elasticsearch index list
        fetch(AdminUtils.getRequestObject(AdminUtils.getCurrentElasticsearchIndexes(), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(
                (indexList) => {
                    this.setState({
                        currentESTermList: getIndexData(indexList)
                    });
                }
            ).catch(err => {
                console.log(err);
                this.setState({
                    showErrorAlert: true
                });
            });

        //get past term elasticsearch index list
        fetch(AdminUtils.getRequestObject(AdminUtils.getPastElasticsearchIndexes(), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(
                (indexList) => {
                    this.setState({
                        pastESTermList: getIndexData(indexList)
                    });
                }
            ).catch(err => {
                console.log(err);
                this.setState({
                    showErrorAlert: true
                });
            });

            this.getTimerESData()
            this.fetchTermInfo()
    }

    componentWillUnmount() {
        /*
          stop getTimerESData() from continuing to run even
          after unmounting this component.
        */
        clearTimeout(this.intervalID);
      }

    getTimerESData = () => {
        // console.log("Running ES status fetch")
        fetch(AdminUtils.getRequestObject(AdminUtils.getSettingUrl("elastic_search_active_creating_aws"), "GET"))
        .then((response) => {
            if (response.status === 401) {
                serviceauth.reauth();
            }
            return response.json();
        })
        .then(
            (data) => {
                if(this.state.esRefreshStatus !== "" && data.output === null){
                    this.fetchTermInfo()
                }
                this.setState({
                    esRefreshStatus: data.output,
                    showActivateButton: data.output === null ? true : false
                });
               this.intervalID = setTimeout(this.getTimerESData.bind(this), 30000);//every 30 seconds
            }
        ).catch(err => {
            console.log(err);
            
        });





        
      }

    fetchTermInfo = () => {
        fetch(AdminUtils.getRequestObject(UtilFunctions.getTermListUrl(), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(
                (termList) => {
                    
                    const currentTerm = termList.currentTerm[0].value
                    const termStr = termList.fullList.find(t => t.value === currentTerm);
                    // console.log(termStr.label)
                    this.setState({
                        currentTerm: currentTerm,
                        allTerms: termList.fullList,
                        currentTermDescr:termStr.label + " (" + currentTerm + ")"
                    });
                }
            ).catch(err => {
                console.log(err);
                this.setState({
                    showErrorAlert: true
                });
            });
    }

    handleTermChange(e) {
        console.log("selected term:", e)
        this.setState({
            currentTerm: e.value
        });
    }

    activateSelectedTerm(e) {
        const term = this.state.currentTerm;
        if(window.confirm(`You are about to activate term ${term}. Would you like to continue? `)){
            console.log("submit button clicked on activate term page")
            e.preventDefault();
        
            fetch(AdminUtils.getRequestObject(AdminUtils.getESTermActivationUrl(term), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(
                (data) => {
                    // console.log(data)
                    if(data.output === 1)
                        this.setState({
                            modalShow: true
                        })
                    else{
                        this.setState({
                            showErrorAlert: true
                        })
                        
                    }
                    this.setState({
                        currentTerm: "",
                        showActivateButton:false
                    });
                }
            ).catch(err => {
                console.log(err);
                
            });
        }
    }

    handleDeleteESSetting(e){
        fetch(AdminUtils.getRequestObject(AdminUtils.getDeleteSettingUrl(), "DELETE", JSON.stringify({
            key: "elastic_search_active_creating_aws"
        })))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then((responseData) => {
                if (responseData.output === 1) {
                    alert("ES setting deleted!")
                } else {
                    alert("There was an error deleting ES setting!")
                }
            }).catch(err => { 
                console.log(err);
                
            });
    }

    handleESRefresh(e){
        fetch(AdminUtils.getRequestObject(AdminUtils.getESRefreshUrl(), "GET"))
        .then((response) => {
            if (response.status === 401) {
                serviceauth.reauth();
            }
            return response.json();
        })
        .then(
            (data) => {
                console.log(data)
                if(data.output === 1)
                    alert("Refresh will begin shortly")
            }
        ).catch(err => {
            console.log(err);
            
        });
    }

    render() {
        return (<>
            <Container className="mb-3">
                    <h4>
                        <mark>Terms have up to 5 min caching delay.</mark>
                    </h4>
            </Container>
            {
                this.state.showESSettings &&
                <>
                    <Container className="mb-5">
                        <p>Only visible to LCABRE and RCHAVE2</p>
                        <button type="button" className="btn btn-maroon " onClick={this.handleESRefresh}>Refresh Environment</button>
                        <button type="button" className="btn btn-maroon me-3 ms-3"
                        onClick={e =>
                            window.confirm("Are you sure you wish to delete this item?") &&
                            this.handleDeleteESSetting(e)
                        }>Delete DB Settings</button>
                        {
                            this.state.esRefreshStatus !== null &&
                            <p className="pt-2">
                                {this.state.esRefreshStatus}
                            </p>
                        }

                    </Container>
                    
                </>
            }
            <Container>
                <h4>Current Active Term: {this.state.currentTermDescr}</h4>
                {
                    this.state.esRefreshStatus !== null &&
                    <h4>
                        <mark>Elasticsearch refresh/term activation is in progress.</mark>
                    </h4>
                }
                <hr className="mt-5"/> 
                
                <Form>
                    <Form.Group controlId="activateTermSelect">
                        <Form.Label>Activate Term</Form.Label>
                        
                        <Select options={
                            // this.state.allTerms.filter(term => term.value !== this.state.currentTerm)
                            this.state.allTerms
                            }
                            onChange={(e) => this.handleTermChange(e)} />
                    </Form.Group>

                    {
                        this.state.showActivateButton &&
                        <button type="button" className="btn btn-maroon mt-3" onClick={(e) => this.activateSelectedTerm(e)}>
                            Submit
                        </button>
                    }
                    
                </Form>

                <SuccessDialogBox
                    show={this.state.modalShow}
                    onHide={() => this.setState({ modalShow: false })}
                    successMessage="The process to activate the new term was initiated. Please wait a few minutes to see the change refplected on Class Search."
                />

                <ErrorAlert
                    show={this.state.showErrorAlert}
                    onHide={() => this.setState({ showErrorAlert: false })}
                />

            </Container>
            <hr className="mt-5"/> 
            <Container className="pt-4">
                <h4 className="mb-5">Terms in Elasticsearch</h4>
                {
                    this.state.currentESTermList !== null &&
                    <table class="table ">
                        <thead>
                            <tr>
                                <th scope="col">Term</th>
                                <th scope="col">Index</th>
                                <th scope="col"># of Classes</th>
                                <th scope="col">Creation date/time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.currentESTermList.map(i => {
                                    return <tr>
                                        <th scope="row">{i.term}</th>
                                        <td>{i.index}</td>
                                        <td>{i.docsCount}</td>
                                        <td>{i.time}</td>
                                    </tr>
                                })
                            }




                        </tbody>
                    </table>
                }

            </Container>

            <Container className="pt-5">
                <h4 className="mb-5">Past Terms in Elasticsearch</h4>
                {
                    this.state.pastESTermList !== null &&
                    <table class="table ">
                        <thead>
                            <tr>
                                <th scope="col">Term</th>
                                <th scope="col">Index</th>
                                <th scope="col"># of Classes</th>
                                <th scope="col">Creation date/time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.pastESTermList.map(i => {
                                    return <tr>
                                        <th scope="row">{i.term}</th>
                                        <td>{i.index}</td>
                                        <td>{i.docsCount}</td>
                                        <td>{i.time}</td>
                                    </tr>
                                })
                            }




                        </tbody>
                    </table>
                }

            </Container>
        </>)
    }
}

export default ActivateTerm;

function getIndexData(data){
    try {
        var newData = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if(element.index !== ".kibana"){
                var term = ""
                var time = ""
                if(element.index.indexOf("course") >= 0){
                    term = "course " + element.index.split("-")[1]
                    time = element.index.split("-")[2] + "/" + element.index.split("-")[3] + "/" + element.index.split("-")[4] + "   " + element.index.split("-")[5] + ":" + element.index.split("-")[6] + ":" + element.index.split("-")[7]
                }else{
                    term = element.index.split("-")[0]
                    time = element.index.split("-")[1] + "/" + element.index.split("-")[2] + "/" + element.index.split("-")[3] + "   " + element.index.split("-")[4] + ":" + element.index.split("-")[5] + ":" + element.index.split("-")[6]
                }
                

                newData.push({
                    term:term,
                    index:element.index,
                    docsCount: element["docs.count"],
                    time:time 
                })
               

            }
        }
        newData.sort((a, b) => (a.index > b.index) ? 1 : -1)
        // console.log(newData)
        return newData
    } catch (error) {
        console.log(error)
    }
    return null
}