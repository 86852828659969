import React, { Component } from 'react';
import Container from "react-bootstrap/Container";
import UtilFunctions from "./UtilFunctions";
import serviceauth from "./auth/serviceauth";
import isEmpty from "lodash.isempty";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Autosuggestions from "./components/Autosuggestions";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown'
import StaticData from './StaticData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch,faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ClassBanner from "./components/ClassBanner";
// import Button from "react-bootstrap/Button";

const globalFields = {
    advanced:true,
    navTree: [],
    showBanner1: "N",
    banner1: null,
    showBanner2: "N",
    banner2: null,
    turnOffApp: false,
    termList: [],
    fullTermList: [],
    term: "",
}
const formFields = {
    
    subject: "",
    catalogNbr: "",
    keywords: "",
    level: new Set(),
    gs: new Set(),
    gsgold: "",
    gsmaroon: new Set(),
    college: new Set(),
}

function getDropdownLabel(data,filter,placeholder){
    var label = "" 
    var count = -1
    data.forEach(option => {
        if(filter.has(option.value) ){
            if(count < 0)
                label = option.label + " "
            
            count++
        }
            
    });

    if(label === "")
        return <div className='btn-placeholder' dangerouslySetInnerHTML={{__html: placeholder}}></div>
    else{
        if (count > 0)
            return <><div className='label-wrap'>{label}</div>+{count}</>
        else
            return <div className='label-wrap'>{label}</div>
    }

}

function getDropdown(label, name, data, filter, onChange) {


   

    return <Dropdown className="filter-dropdown">
        <Dropdown.Toggle id="dropdown-basic" active={false} bsPrefix="custom-dropdown-toggle" variant="advanced-search">
            {getDropdownLabel(data,filter,label)}
            <FontAwesomeIcon icon={faChevronDown} className="advanced-search-dropdown-button-chevron" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {

                data.map((item, index) => {
                    return <React.Fragment key={item.label}>
                        <Form.Check
                            type="checkbox"
                            label={item.label}
                            checked={filter.has(item.value)}
                            value={item.value}
                            onChange={onChange}
                            name={name}
                            mylabel={item.label}
                            className="filter-check "
                            tabIndex={0}
                            id={item.value}
                        />
                    </React.Fragment>
                })

            }
        </Dropdown.Menu>

    </Dropdown>
}


class AdvancedSearchCatalog extends Component {
    constructor(props) {
        super(props);


        this.state = Object.assign(globalFields,formFields);

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDateFocus = this.handleDateFocus.bind(this)
    }

    async componentDidMount() {
        const appSettings = await UtilFunctions.getAppSettings()
        if ((appSettings != null && appSettings.get("system_enabled") != null && appSettings.get("system_enabled") === "N") ||
            appSettings == null) {//turn off app
            this.setState({
                turnOffApp: true
            })
        } else {
            fetch(serviceauth.getRequestObject(UtilFunctions.getTermListUrl(), "GET"))
                .then((response) => {
                    if (response.status === 401) {
                        serviceauth.reauth();
                    }
                    return response.json();
                })
                .then(async (termList) => {
                    const data = UtilFunctions.parseUrl(this.props.location.search);
                    // console.log(this.props.location.search)
                    const term = isEmpty(data.term)
                        ? [termList.currentTerm[0].value]
                        : [data.term];

                    //split gs from url
                    let gsDesignations = UtilFunctions.getGSDesignationsFromURL(data.gen_studies);

                    this.setState({
                        currentTerm: termList.currentTerm[0].value,
                        term: term,
                        termList: termList.futureList.concat(termList.fullList),
                        navTree: await UtilFunctions.getHeaderNavTree(term),
                        subject: isEmpty(data.subject) ? "" : data.subject,
                        catalogNbr: isEmpty(data.catalogNbr) ? "" : data.catalogNbr,
                        keywords: isEmpty(data.keywords) ? "" : data.keywords,
                        level: isEmpty(data.level) ? new Set() : new Set(data.level.split(",")),
                        gs: isEmpty(data.gen_studies) ? new Set() : new Set(data.gen_studies.split(",")),
                        gsgold: gsDesignations.gold,
                        gsmaroon: gsDesignations.maroon.length === 0 ? new Set() : new Set(gsDesignations.maroon),
                        college: isEmpty(data.college) ? new Set() : new Set(data.college.split(",")),
                        showBanner2: appSettings.get("banner_status_2"),
                        banner2: appSettings.get("banner_text_2"),
                    });
                }).catch(err => { console.log(err) });
        }
    }

    handleChange(event) {
        // console.log(event.target.name + " " + event.target.value + " " + event.target.checked + " " +event.target.type)
        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked
            })
        } else {
            var value = event.target.value
            if(event.target.name === "subject")
                value = value.toUpperCase()
            this.setState({
                [event.target.name]: value
            })
        }

    }

    handleCheck(event) {
        // console.log(event.target.name + " " + event.target.value + " " + event.target.checked)
        const chkName = event.target.name
        const chkValue = event.target.value
        var selectedList = this.state[chkName]
        //if checkbox is checked
        if (event.target.checked) {
            selectedList.add(chkValue)
        } else {//if checkbox is NOT checked
            selectedList.delete(chkValue)
        }
        this.setState({
            [chkName]: selectedList
        })

    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            this.props.history.push(`/catalog/courses/courselist?${UtilFunctions.getCatalogQueryStringFromState(this.state)}`)
        }
    }

    handleSearch(e) {
        let gsMaroonFromState = this.state.gsmaroon;
        if(this.state.gsgold !== "")
            gsMaroonFromState.add(this.state.gsgold);

        this.setState({
            gs:gsMaroonFromState,
        },()=>{
            this.props.history.push(`/catalog/courses/courselist?${UtilFunctions.getCatalogQueryStringFromState(this.state)}`)
        });
    }

    

    handleDateFocus(e){//fix for ios safari not clearing date fields
        e.currentTarget.defaultValue = '';
    }

    render() {
        return (
            <React.Fragment>
                 
                {
                    this.state.turnOffApp &&
                    <Container>
                        App is Down
                    </Container>
                }

                {
                    !this.state.turnOffApp &&
                    <>
                    <Container fluid={true} className="p-0 " id="main">
                        {
                            this.state.showBanner2 === "Y" &&
                            <ClassBanner bannerColor="gray" bannerHtml={this.state.banner2}></ClassBanner>
                        }
                        <Header navTree={this.state.navTree} />
                        <Container className=" mb-5">
                            <h2 className=" pt-4">
                                <span className="highlight-black">
                                    Course Catalog Search
                                </span>
                            </h2>
                            <div className='row'>
                                <div className='col-12 col-md-6'><h3 className='mt-5'>Advanced Search</h3></div>
                                <div className='col-12 col-md-6  mt-md-5 '><a className='float-md-end' href= "/catalog/advanced/courses" tabIndex={0} >Clear all filters</a></div>
                            </div>
                            
                            <form className='admin uds-form' id="skip-to-content">
                                <fieldset>
                                <div className="row">
                                    <div className="col-lg-2 mt-2 mt-lg-3">
                                        <label>Term</label>
                                        <select className="form-control asu form-select"
                                            id="term"
                                            value={this.state.term}
                                            onChange={this.handleChange}
                                            name="term"
                                            tabIndex={0}
                                            aria-label="Select Term">
                                            {this.state.termList != null &&
                                                this.state.termList.map((term) => {
                                                    return (
                                                        <option value={term.value} key={term.value}>
                                                            {term.label}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                        <label>Subject</label>
                                        <Autosuggestions
                                            term={this.state.term}
                                            name="subject"
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            value={this.state.subject}
                                            className="asu form-control"
                                            id="subject"
                                            placeholder="Enter Subject"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                        <label>Number</label>
                                        <input type="text"
                                            className="form-control asu"
                                            inputMode="numeric"
                                            placeholder="Enter Number"
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            name="catalogNbr"
                                            value={this.state.catalogNbr}
                                        />
                                    </div>
                                    <div className="col-lg-3 mt-2 mt-lg-3">
                                        <label>Keyword search</label>
                                        <input type="text"
                                            placeholder="Search by keyword"
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            name="keywords"
                                            className="asu form-control"
                                            value={this.state.keywords}
                                            id="keyword" />
                                    </div>

                                    <div className="col-lg-3  col-12 mt-2 mt-lg-3">
                                        <label>Level</label>
                                        {getDropdown("Select Level", "level", StaticData.levelList, this.state.level, this.handleCheck)}
                                    </div>
                                    
                                </div>
                                <div className="row ">
                                    <div className="col-lg-4  col-12 mt-2 mt-lg-3">
                                        <div className='gs-label gs-label-gold gs-header'>General Studies Gold</div>
                                        <p className=' mt-1'>{StaticData.GS_GOLD_TEXT}</p>
                                        {/* {getDropdown("Select General Studies Gold", "gs", StaticData.gsListGold, this.state.gs, this.handleCheck, "select general studies")} */}
                                    
                                        <select className="form-control asu form-select"
                                            id="gsgold"
                                            value={this.state.gsgold}
                                            onChange={this.handleChange}
                                            name="gsgold"
                                            tabIndex={0}
                                            aria-label="Select General Studies Gold">
                                                <option value={""} key={"empty"} className=''>
                                                    Select General Studies Gold
                                                </option>
                                            {
                                                StaticData.gsListGold.map((myGs) => {
                                                    return (
                                                        <option value={myGs.value} key={myGs.value}>
                                                            {myGs.label}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="col-lg-4  col-12 mt-2 mt-lg-3">
                                        <div className='gs-label gs-label-maroon gs-header'>General Studies Maroon</div>
                                        <p className=' mt-1'>{StaticData.GS_MAROON_TEXT}</p>
                                        {getDropdown("Select General Studies Maroon", "gsmaroon", StaticData.gsListMaroon, this.state.gsmaroon, this.handleCheck, "select general studies")}
                                    </div>

                                    <div className="col-lg-4 mt-2 mt-lg-3 advanced-search-college-padding" >
                                        <label>College/School</label>
                                        {getDropdown("Select College or School", "college", StaticData.collegeList, this.state.college, this.handleCheck)}
                                    </div>
                                    {/* <div className="col-lg-5 mt-2 mt-lg-3">
                                        <label>General Studies</label>
                                        {getDropdown("Select General Studies", "gs", StaticData.gsList, this.state.gs, this.handleCheck)}
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 mt-4">
                                        <button id="search-button"
                                            type="button"
                                            className="btn-xl btn btn-gold "
                                            onClick={this.handleSearch}
                                        >
                                            <FontAwesomeIcon icon={faSearch} className="me-2" />
                                            Search Courses
                                        </button>
                                    </div>
                                </div>
                                </fieldset>
                            </form>
                        </Container>

                        <Footer />

                    </Container>
                    </>
                }
            </React.Fragment>

        )
    }



}
export default AdvancedSearchCatalog;