import React, { Component } from "react";
import "./css/App.css";
// import "./css/bootstrap-asu.css";
import "./css/Autosuggest.css";
import UtilFunctions from "./UtilFunctions";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Jumbotron from "react-bootstrap/Jumbotron";
import Form from "react-bootstrap/Form";
import { Route, Link } from "react-router-dom";
import ClassResults from "./ClassResults";
import isEmpty from "lodash.isempty";
// import Header from "./components/Header";
// import Footer from "./components/Footer";
import HomeSearch from "./HomeSearch"
import queryString from 'query-string';
import serviceauth from "./auth/serviceauth";
import Background from "./images/class-search-background.png";
import archivesBackground from "./images/class-search-archives-background.png";
// import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBell } from "@fortawesome/free-solid-svg-icons";
// import Button from "react-bootstrap/Button";
import Autosuggestions from "./components/Autosuggestions";
import { Spinner } from '@simply007org/react-spinners';
import SpinnerImage from 'react-bootstrap/Spinner';
import { spinnerService } from '@simply007org/react-spinners';
import "./css/ResultsTable.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BrowseBySubject from "./components/BrowseBySubject";
import ClassBanner from "./components/ClassBanner";


const jumbotronBackground = {
  backgroundPosition: "0% 68%",
  backgroundSize: "cover",
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(" + Background + ")",
};
const jumbotronArchivesBackground = {
  backgroundPosition: "0% 68%",
  backgroundSize: "cover",
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(" + archivesBackground + ")",
};

const ENV = process.env.REACT_APP_HOST_ENV;

class Search extends Component {

  constructor(props) {
    super(props);

    // var crypto = require('crypto');

    // var hash = crypto.createHash('sha256');


    this.state = {
      term: "",
      termList: [],
      fullTermList: [],
      showArchive: false,
      subject: "",
      catalogNbr: "",
      searchType: "all",
      keywords: "",
      college: "",
      campus: [],
      campusOrOnlineSelection: "A",
      session: [],
      gs: [],
      instructor: "",
      level: "",
      classNbr: "",
      promod: false,
      honors: false,
      daysOfWeek: [],
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      advancedSearchOpen: localStorage.getItem("classAdvancedSearchOpen"),
      navTree: [],
      showBanner1: "N",
      banner1: null,
      showBanner2: "N",
      banner2: null,
      turnOffApp: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.pushToHistory = this.pushToHistory.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleOpenAdvancedSearch = this.handleOpenAdvancedSearch.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handlePushToHistoryFromHomeSearch = this.handlePushToHistoryFromHomeSearch.bind(this)
    this.getSearchBySubjectURL = this.getSearchBySubjectURL.bind(this);

    this.oldClassSearchUrlNonProd = "https://webapp4-" + ENV + ".asu.edu/catalog";
    this.oldClassSearchUrlProd = "https://webapp4.asu.edu/catalog";



  }


  async componentDidMount() {
    // console.log("componentDidMount")
    //update fields from url
    //const data = UtilFunctions.parseUrl(this.props.location.search)
    //const url = 'http://localhost:5000/course-catalog/api/v1/search/terms';

    window.document.title = "Class Search | Arizona State University";

    const appSettings = await UtilFunctions.getAppSettings()
    // console.log(appSettings.get("system_enabled"))
    if ((appSettings != null && appSettings.get("system_enabled") != null && appSettings.get("system_enabled") === "N") ||
      appSettings == null) {//turn off app
      this.setState({
        turnOffApp: true
      })
    } else {
      fetch(serviceauth.getRequestObject(UtilFunctions.getTermListUrl(), "GET"))
        .then((response) => {
          if (response.status === 401) {
            serviceauth.reauth();
          }
          return response.json();
        })
        .then(async (termList) => {
          //update fields from url

          const data = UtilFunctions.parseUrl(this.props.location.search);
          const term = isEmpty(data.term)
            ? [termList.currentTerm[0].value]
            : [data.term];
          // console.log(termList.currentTerm[0].value)
          // console.log(termList.currentTerm[0].value)
          //  const subjectsList = await getSubjectsList(this.state.term);
          // console.log(subjectsList)
          var showArchiveBool = UtilFunctions.showArchive(term[0], termList.fullList)
          var pastTermList = UtilFunctions.getPastTermList(termList.fullList)
          var currentTermList = UtilFunctions.getCurrentTermList(termList.fullList)
          this.setState({
            term: term,
            termList: showArchiveBool ? pastTermList : currentTermList,
            firstPastTerm: pastTermList[0].value,
            fullTermList: termList.fullList,
            showArchive: showArchiveBool,
            subject: isEmpty(data.subject) ? "" : data.subject,
            catalogNbr: isEmpty(data.catalogNbr) ? "" : data.catalogNbr,
            searchType: isEmpty(data.searchType) ? "all" : data.searchType,
            keywords: isEmpty(data.keywords) ? "" : data.keywords,
            // college: isEmpty(data.college ) ?  undefined : data.college ,
            //campus: isEmpty(data.campus ) ?  undefined : data.campus.join(","),
            //session: isEmpty(data.session ) ?  undefined : data.session.join(",") ,
            // campusOrOnlineSelection: isEmpty(data.campusOrOnlineSelection ) ?  "C" : data.campusOrOnlineSelection,
            // gen_studies:isEmpty(data.gs ) ? undefined : data.gs.join(",") ,
            instructor: isEmpty(data.instructorName) ? "" : data.instructorName,
            // level: isEmpty(data.level ) ? undefined : data.level ,
            classNbr: isEmpty(data.classNbr) ? "" : data.classNbr,
            // promod: data.promod  ,
            // hon : data.honors  ,
            // honorsEnrichmentContracts: data.honors ? false : undefined,
            // units: isEmpty(data.units ) ? undefined : data.units ,
            // daysOfWeek: isEmpty(data.daysOfWeek)
            //   ? []
            //   : data.daysOfWeek.split(","),
            navTree: await UtilFunctions.getHeaderNavTree(term, "classes"),
            showBanner1: appSettings.get("banner_status"),
            banner1: appSettings.get("banner_text"),
            showBanner2: appSettings.get("banner_status_2"),
            banner2: appSettings.get("banner_text_2"),
            // startDate: isEmpty(data.startDate ) ? undefined : data.startDate ,
            // endDate: isEmpty(data.endDate ) ? undefined : data.endDate ,
            // startTime: isEmpty(data.startTime ) ? undefined : data.startTime ,
            // endTime: isEmpty(data.endTime ) ? undefined : data.endTime ,
          });
        }).catch(err => { console.log(err) });
    }
  }

  // componentDidUpdate(){
  //   //this is needed so that a subject is entered in the subject field when using Browse by Subject
  //   const data = UtilFunctions.parseUrl(this.props.location.search);
  //   if(data && data.subject && this.state.subject !== data.subject){
  //     this.setState({
  //       subject: isEmpty(data.subject) ? "" : data.subject,
  //     });
  //   }
  // }

  handleChange(event, checkedItems) {

    const type = event.target.type;
    var name = event.target.name;

    //console.log(checkedItems)

    if (type === "radio" || type.indexOf("select") >= 0) {
      this.setState({ [name]: event.target.value }, () => {
        this.pushToHistory();
      });
    } else if (type === "checkbox") {
      var val = [];
      if (checkedItems != null) {
        //multiple select
        checkedItems.forEach((value, key) => {
          //console.log()
          if (value) {
            //val.push(key);
            val.push(value.value);
          }
        });
      } else {
        //regular checkbox
        val = event.target.checked;
      }

      this.setState(
        {
          [name]: val,
        },
        () => {
          // console.log("after state update")
          this.pushToHistory();
        }
      );
    } else {
      var value = event.target.value;
      if (name === "subject" || name === "catalogNbr")
        value = value.toUpperCase();
      this.setState({ [name]: value });
    }
  }

  handleDateTimeChange(type, start, end) {
    if (type === "date") {
      this.setState(
        {
          startDate: start == null ? "" : start.toLocaleDateString(),
          endDate: end == null ? "" : end.toLocaleDateString(),
        },
        () => {
          // console.log("pushing history date " + this.state.startDate)
          this.pushToHistory();
        }
      );
    } else {
      this.setState(
        {
          startTime: start == null ? "" : start.toTimeString().split(" ")[0],
          endTime: end == null ? "" : end.toTimeString().split(" ")[0],
        },
        () => {
          this.pushToHistory();
        }
      );
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.pushToHistory();

    }
  }

  handleButtonClick() {

    this.pushToHistory();
  }

  handleOpenAdvancedSearch() {
    var open =
      localStorage.getItem("classAdvancedSearchOpen") === null ||
        localStorage.getItem("classAdvancedSearchOpen") === "false"
        ? "true"
        : "false";

    localStorage.setItem("classAdvancedSearchOpen", open);
  }

  handleTermChange(e) {

    var showArchiveBool = UtilFunctions.showArchive(e.target.value, this.state.fullTermList)
    this.setState({
      term: e.target.value,
      termList: showArchiveBool ? UtilFunctions.getPastTermList(this.state.fullTermList) : UtilFunctions.getCurrentTermList(this.state.fullTermList),
      showArchive: showArchiveBool
    });
    this.props.history.push(
      this.props.match.path +
      "?term=" + e.target.value

    );
  }

  getSearchBySubjectURL(subject) {

    const filters = {
      subject: subject,
      term: this.state.term
    }

    return this.props.match.path + "/classlist?" + UtilFunctions.getQueryStringFromState(filters)

  }

  pushToHistory() {
    spinnerService.show('mySpinner');
    const newUrl = this.props.match.path +
      "/classlist?" +
      UtilFunctions.getQueryStringFromState(this.state)
    if ((this.props.location.pathname + this.props.location.search) === newUrl) {//cscc-238 Show spinner when searching again
      setTimeout(() => { spinnerService.hide('mySpinner') }, 1000)

    } else {
      this.props.history.push(newUrl);
    }


  }

  handlePushToHistoryFromHomeSearch(filters, url) {
    // console.log(filters)
    if (url) {

      this.props.history.push(url)
    } else {
      spinnerService.show('mySpinner');
      this.props.history.push(
        this.props.match.path +
        "/classlist?" +
        UtilFunctions.getQueryStringFromState(filters)
      )
    }

  }

  render() {
    // console.log("serviceauth.SS_NAME: "+sessionStorage.getItem(serviceauth.SS_NAME));
    // console.log("serviceauth.SS_JWT_TOKEN: "+sessionStorage.getItem(serviceauth.SS_JWT_TOKEN));
    const parsedQueryString = queryString.parse(this.props.location.search)
    const queryStringCount = Object.keys(parsedQueryString).length
    const showHomeSearch = queryStringCount === 0 || (queryStringCount === 1 && parsedQueryString.term !== undefined)

    //  console.log("showing view as: "+sessionStorage.getItem(serviceauth.SS_VIEW_AS))
    return (

      <React.Fragment>
        {
          this.state.turnOffApp &&
          <Container>
            App is Down
          </Container>
        }
        {
          !this.state.turnOffApp &&
            <Container fluid={true} className="p-0">
              <Header navTree={this.state.navTree}/>
              {/* <Header
              title="Class Search"
              navTree={this.state.navTree}
              loginLink={serviceauth.redirectToServiceauthUrl(true)}
              loggedIn={sessionStorage.getItem(serviceauth.SS_NAME) && sessionStorage.getItem(serviceauth.SS_JWT_TOKEN)}
              userName={sessionStorage.getItem(serviceauth.SS_NAME)}
              logoutLink="/logout"


            /> */}


              {/* <a id="skip-to-content" className="anchor" href="https://catalog.apps.asu.edu/catalog/classes">a</a> */}
              {/* <div id="beta_toggle_bar">
              <div id="beta_toggle_bar_container">
                <div className="row">
                  <div className="col-md-8 beta_toggle_text pb-2 pt-2">

                  Help us improve Class Search! Share your feedback with a quick <a href="https://asu.questionpro.com/a/TakeSurvey?tt=5JQAANO2628ECHrPeIW9eQ%3D%3D" tabIndex={0} >survey</a>.
                  </div> */}

              {/* <div className="col-md-4 beta_toggle_toggle">
                    <table>
                      <tbody>
                        <tr>
                          <td className="beta_label">
                            Beta Class Search
                          </td>
                          <td>
                            <label className="switch1">
                              {ENV !== null && ENV !== 'prod' ? (
                                <a className="slider1 round" href={this.oldClassSearchUrlNonProd} tabIndex={0}><div className="beta_toggle">On</div></a>
                              ) : (
                                <a className="slider1 round" href={this.oldClassSearchUrlProd} tabIndex={0}><div className="beta_toggle">On</div></a>
                              )}

                              <input type="checkbox" tabIndex={-1} />
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
              {/* </div>
              </div>
            </div> */}
              {
                  this.state.showBanner1 === "Y" &&
                  // <Row className="pt-3 pb-1 advancedPane ">
                  //   <Col sm="1 text-right">
                  //     <FontAwesomeIcon icon={faInfoCircle} className="blue-color" />
                  //   </Col>
                  //   <Col sm="7">
                  //     <div dangerouslySetInnerHTML={{ __html: this.state.banner1 }} />
                  //   </Col>
                  // </Row>
                  // <div id="banner">
                  //   <div className="container">
                  //     <Row className="pt-5 pb-5">
                  //       <Col sm="1" >
                  //         <h4 className="m-0"><FontAwesomeIcon icon={faInfoCircle} className=" float-sm-left float-md-right" /></h4>
                  //       </Col>
                  //       <Col sm="7">
                  //         <div dangerouslySetInnerHTML={{ __html: this.state.banner1 }} />
                  //       </Col>


                  //     </Row>
                  //   </div>
                  // </div>

                  <div className="row g-0" id="banner">
                    <div
                        className="col uds-full-width"
                        id="html-root"
                    >
                      <section className="banner-gray">
                        <div
                            className="banner uds-content-align"
                            role="banner"
                        >
                          <div className="banner-icon">
                            <FontAwesomeIcon icon={faBell} className="me-2" title="Banner"/>
                          </div>
                          <div className="banner-content pt-1">
                            <div dangerouslySetInnerHTML={{__html: this.state.banner1}}/>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>

              }

               <section style={this.state.showArchive ? jumbotronArchivesBackground : jumbotronBackground} className="mb-0 pt-5 pb-5" id="main">

                {
                    sessionStorage.getItem(serviceauth.SS_VIEW_AS) === "Y" &&
                    <div className=" alert-warning container" role="alert">
                      <p>You are viewing as user: {sessionStorage.getItem(serviceauth.SS_VIEW_AS_USER)}</p>
                      {
                          sessionStorage.getItem(serviceauth.SS_IS_STUDENT) === "Y" &&
                          sessionStorage.getItem(serviceauth.SS_IS_ONLINE) === "Y" &&
                          <p>{sessionStorage.getItem(serviceauth.SS_VIEW_AS_USER)} is an ONLINE student</p>
                      }
                      {
                          sessionStorage.getItem(serviceauth.SS_IS_STUDENT) === "Y" &&
                          sessionStorage.getItem(serviceauth.SS_IS_ONLINE) === "N" &&
                          <p>{sessionStorage.getItem(serviceauth.SS_VIEW_AS_USER)} is NOT an ONLINE student</p>

                      }
                      <p>AcadCareer: {sessionStorage.getItem(serviceauth.SS_CAREER)}</p>
                      <p>
                        Staff:
                        {
                            sessionStorage.getItem(serviceauth.SS_IS_STAFF) === "Y"
                            &&
                            " Yes"
                        }
                        {
                            sessionStorage.getItem(serviceauth.SS_IS_STAFF) === "N"
                            &&
                            " No"
                        }

                      </p>

                    </div>
                }

                <Container>

                  <Row>

                    <Col xs={"auto"} className="p-0">
                      <h1 className=" mt-0">
                      <span className="highlight-white">
                        {
                            this.state.showArchive &&
                            <>Class Search Archives</>
                        }
                        {
                            !this.state.showArchive &&
                            <>Class Search</>
                        }
                      </span>
                      </h1>
                      {
                          this.state.showArchive &&
                          <a className="white mt-2" href="/catalog/classes">Return to current class search</a>
                      }
                    </Col>
                  </Row>

                  {/* <Row className="mt-md-1 mt-lg-4 p-3 align-items-center white-box">
                  <Col lg=""> */}
                <Row className="mt-md-1 mt-lg-4 align-items-center white-box p-3">
                  <Col lg="2" className="p-0 pt-lg-0 pt-2">
                    <Form.Group className=" mb-0">
                      <Form.Label>Term</Form.Label>
                      <Form.Control
                        as="select"
                        className="asu form-control form-select"
                        onChange={this.handleTermChange}
                        usevalueaslabel="false"
                        id="term"
                        closemenuonselect="true"
                        isclearable="false"
                        value={this.state.term}
                        tabIndex={0}
                        aria-label="Select Term"
                      >
                        {this.state.termList != null &&
                          this.state.termList.map((term) => {
                            return (
                              <option value={term.value} key={term.value}>
                                {term.label}
                              </option>
                            );
                          })
                        }
                        {
                          !this.state.showArchive &&
                          <option value={this.state.firstPastTerm} >
                            Previous Terms
                          </option>
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="1" xs="6" className="p-0 pe-1 pe-lg-0 pt-lg-0 pt-2">
                    <Form.Group className=" mb-0">
                      <Form.Label>Subject</Form.Label>
                      {/* <Form.Control
                          type="text"
                          // placeholder="Subject"
                          onChange={this.handleChange}
                          onKeyDown={this.handleKeyDown}
                          name="subject"
                          value={this.state.subject}
                          className="asu form-control"
                          id="subject"
                        /> */}
                        <Autosuggestions
                            term={this.state.term}
                            name="subject"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            value={this.state.subject}
                            className="asu form-control"
                            id="subject"
                            placeholder="Subject"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="1" xs="6" className="p-0 pt-lg-0 pt-2">
                      <Form.Group className=" mb-0">
                        <Form.Label>Number</Form.Label>
                        <Form.Control
                            type="text"
                            inputMode="numeric"
                            pattern="\d*"
                            placeholder="Number"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            name="catalogNbr"
                            value={this.state.catalogNbr}
                            className="asu"
                            id="catalogNbr"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="3" className="p-0 pt-lg-0 pt-2 ">
                      <Form.Group className=" mb-0">
                        <Form.Label id="keyword-label">Keyword search</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter keywords"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            name="keywords"
                            className="asu form-control"
                            value={this.state.keywords}
                            id="keyword"
                        />
                        {/* <i className="glyphicon glyphicon-user form-control-feedback">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </i> */}
                      </Form.Group>
                    </Col>
                    <Col className="p-0   mt-3 ">
                      <button id="search-button"
                              type="button"
                              className="btn btn-gold ms-lg-2"
                              onClick={this.handleButtonClick}
                      >
                        <FontAwesomeIcon icon={faSearch} className="me-2"/>
                        Search Classes
                      </button>
                    </Col>
                    <Col className="pt-3 ps-md-2 ps-0">

                      <Link
                          to={{
                            pathname: "/catalog/advanced",
                            search: this.props.location.search,
                          }}
                      >
                      <span className="text-nowrap">
                        {
                            this.props.location.search.indexOf("advanced=true") >= 0 &&
                            <>Continue Advanced Search</>
                        }
                        {
                            this.props.location.search.indexOf("advanced=true") < 0 &&
                            <>Advanced Search</>
                        }


                      </span>
                      </Link>


                    </Col>

                  </Row>

                  {/* </Col>


                </Row> */}


                  {/* <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <p className="pageTitle ps-4 d-none d-sm-block">Class Search</p>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-md-center">
                                                <Col lg="2" className="p-0 mb-3">
                                                    <Form.Check
                                                        type="radio"
                                                        name="campusOrOnlineSelection"
                                                <Col lg="2" className="p-0 mb-3">
                                                    <ReactMultiselect
                                                        name="term"
                                                        onChange={this.handleChange}
                                                        isMulti={false}
                                                        options={this.state.termList}
                                                        usevalueaslabel={false}
                                                        id="term"
                                                        closemenuonselect={true}
                                                        isclearable="false"
                                                        selectedOption={this.state.term}
                                                        className="border-radio-left " />

                                                </Col>        value="C"
                                                        checked={this.state.campusOrOnlineSelection === "C"}
                                                        onChange={this.handleChange}
                                                        //onClick={this.handleClick}
                                                        label="In-Person & iCourses"
                                                        className="text-white"
                                                    />

                                                    <Form.Check
                                                        type="radio"
                                                        name="campusOrOnlineSelection"
                                                        value="O"
                                                        checked={this.state.campusOrOnlineSelection === "O"}
                                                        label="ASU Online Classes"
                                                        onChange={this.handleChange}
                                                        className="text-white "
                                                    />


                                                </Col>
                                                <Col lg="2" className="p-0 mb-3">
                                                    <ReactMultiselect
                                                        name="term"
                                                        onChange={this.handleChange}
                                                        isMulti={false}
                                                        options={this.state.termList}
                                                        usevalueaslabel={false}
                                                        id="term"
                                                        closemenuonselect={true}
                                                        isclearable="false"
                                                        selectedOption={this.state.term}
                                                        className="border-radio-left " />

                                                </Col>
                                                <Col lg="1" className="p-0 mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Subject"
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                        name="subject"
                                                        value={this.state.subject}
                                                        className="asu form-control" />

                                                </Col>
                                                <Col lg="1" className="p-0 mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Number"
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                        name="catalogNbr"
                                                        value={this.state.catalogNbr}
                                                        className="asu form-control" />
                                                </Col>
                                                <Col lg="2" className="p-0 mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search for a class"
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                        name="keywords"
                                                        className="asu form-control"
                                                        value={this.state.keywords} />
                                                </Col>
                                                <Col lg="2" className="p-0 mb-3">
                                                    <Button type="button" className="btn-asu asu rounded-right btn btn-primary btn-block btn-md" onClick={this.handleButtonClick}>
                                                        <FontAwesomeIcon icon={faSearch} className="me-2" />
                                                        Search </Button>

                                                </Col>
                                                <Col lg="2" className=" mb-3">
                                                    <Form.Check
                                                        type="radio"
                                                        name="searchType"
                                                        value="open"
                                                        checked={this.state.searchType === "open"}
                                                        onChange={this.handleChange}
                                                        label="Open Seats"
                                                        className="text-white"
                                                    />

                                                    <Form.Check
                                                        type="radio"
                                                        name="searchType"
                                                        value="all"
                                                        checked={this.state.searchType === "all"}
                                                        label="All Classes"
                                                        onChange={this.handleChange}
                                                        className="text-white"
                                                    />

                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col className="p-0 ps-2 " lg="2" >
                                                </Col>
                                                <Col className="p-0 ps-2 " lg="2" >
                                                    <Row>
                                                        <Col className="p-0 ">
                                                            <Button className="btn bg-transparent text-white pt-0 " onClick={this.handleClearAll} variant="link">
                                                                <span className="underline-dot">Clear Filters</span>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="p-0 ">

                                                                variant="link"
                                                                eventKey="advancedSearch"
                                                                className="btn bg-transparent text-white pt-0 border-0 "
                                                                onClick={this.handleOpenAdvancedSearch}>
                                                                <span className="underline-dot">Advanced Search</span>

                                                        </Col>
                                                    </Row>




                                                </Col>



                                            </Row>
                                        </Col>
                                    </Row> */}
                </Container>
              </section>


              {/* <Route exact path="/catalog/classes/classlist" >


              </Route> */}
              {/*Insert Second Banner here*/}
              {
                this.state.showBanner2 === "Y" &&
                  <ClassBanner bannerColor="gray" bannerHtml={this.state.banner2}></ClassBanner>
              }

              {
                  showHomeSearch &&
                  <Route exact path="/catalog/classes">
                    <HomeSearch term={this.state.term} path={this.props.match.path}/>
                  </Route>
              }
              {
                  showHomeSearch &&
                  <Route exact path="/catalog/classes/classlist">
                    <HomeSearch term={this.state.term} path={this.props.match.path}/>
                  </Route>
              }
              <Route path="/catalog/classes/classlist" component={ClassResults}/>
              <Route
                  exact path="/catalog/classes/classlist/browsebysubject"
                  render={() => <BrowseBySubject
                      searchBySubjectURL={this.getSearchBySubjectURL}
                      term={this.state.term}/>}
              />

              <Footer/>
              <span className="spinner-span">
              <Spinner name="mySpinner">
                <div variant="primary" disabled className="spinner-button mt-5 spinner-button-styles">
                  <SpinnerImage
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                  />
                  Loading ...
                </div>
              </Spinner>
            </span>
            </Container>

        }
      </React.Fragment>
    );
  }
}

export default Search;




