import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import UtilFunctions from './../UtilFunctions';
// import StaticData from './../StaticData';
import serviceauth from "../auth/serviceauth";
import "../css/ViewAllSubjects.css";
import { spinnerService } from '@simply007org/react-spinners';
//import { Link } from "react-router-dom";
class BrowseBySubject extends Component {

    constructor(props) {
        super(props)
       
        this.state = {
            subjectsMap: null
        }
    }

    componentDidMount() {
        const term = this.props.term
        if (term !== undefined) {
            fetch(serviceauth.getRequestObject(UtilFunctions.getSubjectSimpleListUrl(term), "GET"))
                .then((response) => {
                    if (response.status === 401) {
                        serviceauth.reauth();
                    }
                    return response.json();
                })
                .then(async subjectsMap => {
                    spinnerService.hide('mySpinner');
                    this.setState({
                        subjectsMap: subjectsMap
                    })

                })
        }
    }

    getSearchBySubjectURL(subject) {
       //console.log("gere")
        return this.props.searchBySubjectURL(subject);

    }

    
  



    render() {

        const subjectsForCollege = (subject) => {
            var url = this.getSearchBySubjectURL(subject.CODE)
            return <a href={url} className="row college-subject  text-left" tabIndex={0} key={subject.CODE}>
                <span className="pr-1 col"><strong>{subject.CODE}</strong>{" "}{subject.DESCR}</span>
            </a>
        }
       
        return (
            
                this.state.subjectsMap !== null && 
                <Container className="mt-12" id="view-all-subjects" >
                    <div id="browse-by-subject">
                        <p className="bigTitle mt-5 mb-4">Browse by subject</p>
                        <div className="pt-2 pb-2 mb-0 all-subjects-list">
                            {/* {StaticData.collegeList.map(({ value, label }) =>
                                <div className="college mb-4" key={value}>
                                    <div className="college-name mb-2">
                                        {label}
                                    </div>
                                    {this.state.subjectsMap[value] !== undefined ? subjectsForCollege(value) : null}
                                </div>
                            )
                            } */}
                            {
                                this.state.subjectsMap.map(subject =>
                                    <div className="mb-2" key={subject.SUBJECT}>
                                        {subjectsForCollege(subject)}
                                    </div>
                                    
                                )
                            }
                        </div>
                    </div>
                </Container>
            
         





        );
    }
}

export default BrowseBySubject


