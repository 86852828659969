import React, { Component } from 'react';
import Toasty from 'react-bootstrap/Toast';



const style = {
    position: 'absolute',
    zIndex:1000,
    marginLeft:30,
    backgroundColor:"#FDF3CD",
    width:200
  }

 

class Toast extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show:this.props.show
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.show !== this.props.show){
            this.setState({
                show:this.props.show
            })
        }
    }
    

    render() {
        return (<React.Fragment>
            <Toasty style={style} delay={2000} autohide onClose={() => this.setState({show:false})} show={this.state.show}>
                <Toasty.Body >{this.props.text}</Toasty.Body>
            </Toasty>



        </React.Fragment>)
    }
}

export default Toast

