import React, { Component } from 'react';
import StaticData from '../StaticData';
// import MultipleChoiceSelect from './MultipleChoiceSelect'
import isEqual from 'lodash.isequal';
import SearchFilterDropdown from "./SearchFilterDropdown"
import SearchFilterMoreFilters from "./SearchFilterMoreFilters"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import { json } from 'sjcl';


const COLLEGE_FILTER = "college"
const LEVEL_FILTER = "level"
const GS_FILTER = "gen_studies"

const filtersList = [COLLEGE_FILTER,LEVEL_FILTER,GS_FILTER]


class FilterPane extends Component {
    constructor(props) {
        super()
        
        this.state = {
            filters: getFilters(),
            selectedFilters: getSelectedFiltersFromSearchParams(props.searchParams),
            searchType: props.searchParams.searchType,
        }
        this.handleDropdownFilterChange = this.handleDropdownFilterChange.bind(this)
        this.handleApplyMoreFilters = this.handleApplyMoreFilters.bind(this);

        
    }

    componentDidUpdate(prevPros) {
        // spinnerService.show('mySpinner');
       
        // console.log(this.props.searchParams )
        // console.log(prevPros.searchParams)
        // console.log(this.props.data);

        if (!isEqual(prevPros.data, this.props.data)) {
            
           // spinnerService.hide('mySpinner');
            const newSelectedFilters = getSelectedFiltersFromSearchParams(this.props.searchParams)
            // console.log(newSelectedFilters);
            
                this.setState({
                    filters: getFilters(),
                    selectedFilters: newSelectedFilters,
                    searchType: this.props.searchParams.searchType,
                })
           
        }
    }


    handleDropdownFilterChange(event, newCheckedItems) {
        // spinnerService.show('mySpinner');
        
        this.props.onChange(event, newCheckedItems);
    }

    handleApplyMoreFilters(event, filters) {
        this.props.onApplyMoreFiltersChange(filters);
    }

   
    render() {
        // var buttonClass = "rounded-pill btn btn-outline-dark filter-button btn-primary"
       
        return (
            <Row className="mb-0 filter mt-3 filter-dropdown " >
                    {this.state.filters &&
                    this.state.filters.map((filter, index) => {
                        return  (filter[3] === "dropdown" &&
                        <Col xs={"auto"} key={index} className="mb-2">
                        <SearchFilterDropdown label={filter[0]}
                        data={filter[2]}
                        placeholder={filter[0]}
                        onChange={this.handleDropdownFilterChange}
                        selectedList={this.state.selectedFilters}
                        name={filter[1]}
                        className={filter[1]}
                        filter="true"
                        /></Col>) 
                       
                    })
                }
                <Col xs={"auto"} className="mb-2">

                    <SearchFilterMoreFilters
                        creditUnitsData={[]}
                        selectedList={this.state.selectedFilters}
                        gsData={this.state.filters[2][2]}
                        collegeData={[]}
                        daysOfWeekData={[]}
                        onChange={this.handleApplyMoreFilters}
                        // onChange{this.handleDropdownFilterChange}
                        // onUniqueValueFilterChange={this.handleUniqueValueFilterChange}
                        searchParams={this.props.searchParams}
                        title=""
                        buttonTitle="General Studies"
                        filter="true" 
                        showHonors={false}
                        showDates={false}/>
                </Col>
               
            </Row>

        )
    }

}

export default FilterPane

function getFilters() {

        return [
             ["Level", LEVEL_FILTER, StaticData["levelList"],"dropdown","levelList"],
             ["Colleges and Schools", COLLEGE_FILTER, StaticData["collegeList"],"dropdown","collegeList"],
             ["General Studies", GS_FILTER, StaticData["gsList"],"","gsList"],
          ]   
}



function getSelectedFiltersFromSearchParams(searchParams) {
    var filters = []
    //  for (let i = 0; i < filtersList.length; i++) {
    //      const element = filtersList[i];
    //      if(searchParams[element] !== undefined){
    //          filters[element] = searchParams[element]
    //      }

    //  }
//    console.log(filtersList)
    for (let i = 0; i < filtersList.length; i++) {
        const element = filtersList[i];
        if (searchParams[element] !== undefined) {
            const f = searchParams[element].split(",")
            for (let j = 0; j < f.length; j++) {//some filters come separated by , like "TEMPE,WEST"
                filters.push(f[j])

            }

        }

    }
    //console.log(filters)
    return filters
}
