/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
// import ReactPagination from 'react-bootstrap/Pagination'
import { spinnerService } from '@simply007org/react-spinners';

const MAX_PAGES = 15
const MAX_PAGES_ON_MEDIUM = 10
const MAX_PAGES_ON_MOBILE = 2
class Pagination extends Component {

    constructor(props) {
        super(props)
        this.state = {
            active: this.props.page,
            totalPages: this.props.totalPages,
            totalItems: this.props.totalItems,
            totalItemsPerPage: this.props.totalItemsPerPage

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(prevState.active +" " + this.props.active)
        // console.log("prevState.active: "+prevState.active)
        // console.log("this.state.active: "+this.state.active)
        // console.log("prevState.totalPages: "+ prevState.totalPages)
        // console.log("this.props.totalPages: "+this.props.totalPages)
        // console.log("prevState.totalItems: "+prevState.totalItems)
        // console.log("this.props.totalItems: "+this.props.totalItems )


        // spinnerService.show('mySpinner');
        if (prevState.active !== this.state.active ||
            prevProps.totalPages !== this.props.totalPages ||
            prevProps.totalItems !== this.props.totalItems) {
            //console.log("change state "+this.props.totalPages)



            this.setState({
                active: this.props.page,
                totalPages: this.props.totalPages,
                totalItems: this.props.totalItems,
                totalItemsPerPage: this.props.totalItemsPerPage
            }
                // ,()=>{
                //     spinnerService.hide('mySpinner');
                // }
            )
        }
    }

    handleKeyDown(number,e) {

        if (e.keyCode === 13) {
           
            this.handleClick(number,e)

        }
    }

    handleClick(number, e) {
        //console.log(number)
        // alert(this.state.active + " " + number)
        //    
        e.preventDefault()
        if (this.state.active !== number) {
            this.setState({
                active: number
            }, () => {
                let highLimit = number * this.state.totalItemsPerPage
                let lowLimit = (highLimit - this.state.totalItemsPerPage) + 1

                if (highLimit > this.state.totalItems)
                    highLimit = this.state.totalItems

                spinnerService.show('mySpinner');
                this.props.onChange(number, lowLimit, highLimit)
            })
        }




    }


    render() {
        

        var paginationBasic = "";
        if (this.state.totalPages > 1){//CSCC-208
            paginationBasic = (
                <button className=" spinner-button-styles pull-right" type="button" >
                    <span className="spinner-grow spinner-grow-sm mr-1 " role="status" aria-hidden="true"></span>
                    <span>Loading...</span>
                </button>
            )
        }
        if (this.props.classResultsTotal === this.props.classTotal && this.state.totalPages > 1) {//CSCC-208
            var maxPages = MAX_PAGES;
            if(window.screen.width < 1921)
                maxPages = MAX_PAGES_ON_MEDIUM;
            if(window.screen.width < 575)
                maxPages = MAX_PAGES_ON_MOBILE;
            let max = this.state.totalPages > maxPages ? maxPages : this.state.totalPages
            // console.log(this.state.totalPages)
            let items = [];
            if (this.state.totalPages > max) {
                items.push(<li className="page-item">
                    <a
                        aria-label='First page'
                        className="page-link"
                        onClick={(e) => this.handleClick(1, e)}
                        onKeyDown={(e) => this.handleKeyDown(1, e)}
                        tabIndex={0}
                    >
                        First
                    </a>
                </li>)
            }
            var ariaDisabled = this.state.active > 1 ? "false" : "true";
            var disabled = this.state.active > 1 ? "" : "disabled";
            items.push(<li className={`page-item ${disabled}`}>
                <a
                    aria-label='Previous page'
                    aria-disabled={ariaDisabled}
                    className="page-link page-link-icon"
                    tabIndex={disabled === "disabled" ? -1 : 0}
                    onClick={(e) => this.handleClick(this.state.active - 1, e)}
                    onKeyDown={(e) => this.handleKeyDown(this.state.active - 1, e)}>
                    Prev
                </a>
            </li>)

            var number = 1;
            let maxToShow = max;
            // let showFirstEllipsis = false;
            // let showLastEllipsis = false;
            if(this.state.active > max){
                number = this.state.active - (maxPages - 1);
                maxToShow = this.state.active
                items.push(<li className="page-item font-weight-bold pt-1">...</li>)
                
            }
           

            // console.log("number "+number)
            // console.log("maxToShow "+maxToShow)
            
            for (; number <= maxToShow; number++) {
                let myPageNumber = number
                items.push(
                    // <li key={number} active={number === this.state.active ? "active" : ""} className={`page-item ${dnone}`} >
                    //     <a href="x" className={`page-link ${number === this.state.active ? "active" : ""}`} onClick={(e) => this.handleClick(number, e)} >{number}</a>
                    // </li>
                    <li key={number} className={`page-item ${number === this.state.active ? "active" : ""}`} >
                        <a
                            aria-label={`page ${number}`}
                            className="page-link"
                            data-ga={`page ${number}`}
                            data-ga-action="click"
                            data-ga-event="select"
                            data-ga-name="onclick"
                            data-ga-region="main content"
                            data-ga-section="pagination title"
                            data-ga-type="pagination"
                            onClick={(e) => this.handleClick(myPageNumber, e)}
                            onKeyDown={(e) => this.handleKeyDown(myPageNumber, e)}
                            tabIndex={0}
                            aria-current={number === this.state.active ? "page" : "false"} 
                        >
                            {number}
                        </a>
                    </li>
                );

            }
            if(maxToShow < this.state.totalPages){
                items.push(<li className="page-item font-weight-bold pt-1">...</li>)
            }

            ariaDisabled = this.state.active < this.state.totalPages ? "false" : "true";
            disabled = this.state.active < this.state.totalPages ? "" : "disabled";
            items.push(<li className={`page-item ${disabled}`}>
                <a
                    aria-disabled={ariaDisabled}
                    className="page-link page-link-icon"
                    tabIndex={disabled === "disabled" ? -1 : 0}
                    onClick={(e) => this.handleClick(this.state.active + 1, e)}
                    onKeyDown={(e) => this.handleKeyDown(this.state.active + 1, e)}
                    aria-label="Next page"
                    
                >
                    Next
                </a>
            </li>)

            if (this.state.totalPages > max) {
                items.push(<li className="page-item">
                    <a aria-label="Last page"
                        className="page-link"
                        onClick={(e) => this.handleClick(this.state.totalPages, e)}
                        onKeyDown={(e) => this.handleKeyDown(this.state.totalPages, e)}
                        tabIndex={0}
                    >
                        Last
                    </a>
                </li>)
            }

            paginationBasic = (
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <nav aria-label="pagination" >
                                    <ul className="pagination">
                                        {items}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (paginationBasic);


    }
}

export default Pagination

