import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faLink } from '@fortawesome/free-solid-svg-icons'
import isEmpty from 'lodash.isempty';
// import Button from 'react-bootstrap/Button'
// import Form from 'react-bootstrap/Form'
import Toast from './Toast'
import UtilFunctions from '../UtilFunctions';

// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';


class CourseItem extends Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        // this.handleKeyDown = this.handleKeyDown.bind(this)
        this.state = {
            // collapse: this.props.collapse ? true : false,
            collapse: !!(this.props?.collapse && this.props.collapse === 'N'),
            hiddenCourseUrl: "",
            toast: false,
        }
        this.hiddenCourseUrl = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleCopyLink = this.handleCopyLink.bind(this);
        // console.log("constructor")
    }

 

    handleClick() {
        this.setState((prevState) => {
            // console.log(prevState.collapse)
            return { collapse: !prevState.collapse }
        })
    }

    handleCopyLink(e,subject, catalogNbr) {
       // console.log(window.location.href.split('?')[0])
        e.stopPropagation()
        const url = window.location.href.split('?')[0] + "?subject=" + subject + "&catalogNbr=" + catalogNbr + "&term=" + this.props.term + "&collapse=N";
        // console.log(url)
        this.setState({
            hiddenCourseUrl: url,
            toast: true,
        }, () => {
            this.hiddenCourseUrl.current.select();
            document.execCommand('copy');//copy to clipboard
            this.hiddenCourseUrl.current.blur();
        })
    }

    handleCopyToClipboard() { }

    


    render() {
        const course = this.props.course;
        //  console.log(JSON.stringify(this.props.seatList))
        return (


            <React.Fragment>
                <textarea
                    value={this.state.hiddenCourseUrl}
                    className="hidden-course-url"
                    onChange={(e) => this.handleCopyToClipboard()}
                    ref={this.hiddenCourseUrl}
                    tabIndex={-1}
                />

                <Row onClick={this.handleClick} className='pointer'>
                    <Col xs={12} sm={11} className="ps-0">

                            <Row className='gx-0'>
                                <Col xs={12} sm={2} className="d-none d-sm-block font-weight-bold ps-1">
                                    {course.SUBJECT} {course.CATALOGNBR}
                                </Col>

                                <Col xs={12} sm={7} className=''>
                                    <p className="mb-1 title font-weight-bold ">{course.COURSETITLELONG}
                                        
                                    </p>
                                    <p className="d-block d-sm-none ">{course.SUBJECT} {course.CATALOGNBR}</p>
                                    <p className="">{course.DESCRLONG}</p>
                                    <p className="d-block d-sm-none asu-font-color">
                                        LEARN MORE 
                                        <FontAwesomeIcon 
                                        icon={this.state.collapse ? faChevronUp : faChevronDown} className="pull-right"
                                        />
                                    </p>
                                </Col>
                                <Col className='d-none d-sm-block text-center' sm={1}>{course.HOURS}</Col>
                                <Col className=' text-center' sm={1}>{UtilFunctions.getGSDesignationsDisplay(course.GSMAROON,course.GSGOLD)}</Col>
                                <Col sm={1} className='text-center'>
                                <button className="pull-right d-none d-sm-block button-link black" tabIndex="0" >
                                            <FontAwesomeIcon 
                                            icon={this.state.collapse ? faChevronUp : faChevronDown} 
                                             />
                                        </button>
                                </Col>
                           </Row>

                            <Row className={this.state.collapse ? '' : 'd-none'}>
                               
                                    <Col xs={12} sm={{ span: 11, offset: 2 }} className="align-self-end ps-1">
                                        <hr className="d-block d-sm-none" />
                                        <p className=" d-sm-block mb-1 font-weight-bold">Units</p>
                                        <p className=" d-sm-block">{course.HOURS}</p>



                                        {
                                            course.DESCR4 !== "" &&
                                            <>
                                                <p className='pt-1'>{UtilFunctions.getGSText(course.GSMAROON,course.GSGOLD)}</p>
                                            </>
                                        }



                                        <p className=" mb-1 font-weight-bold">Offering Schools/Colleges</p>
                                        {
                                            course.COLLEGEMAP.map((org, index) => {

                                                return !isEmpty(org) &&
                                                    <div key={index}>

                                                        <p><a href={org.INFO.ASUCOLLEGEURL} className="asulink">{org.INFO.DESCRFORMAL + " - " + org.DEPARTMENT}</a></p>
                                                        {org.INFO.ENROLLREQ !== "" &&
                                                            <React.Fragment>
                                                                {/* <p className="text-secondary mb-1">Pre-requisite(s)</p> */}
                                                                <p className='ps-3'>{org.INFO.ENROLLREQ}</p>
                                                            </React.Fragment>
                                                        }

                                                    </div>
                                            })
                                        }

                                        <div className="mb-3">
                                            {getTopicsList(course)}
                                        </div>

                                        <p className=" mb-1 font-weight-bold">Allow multiple enrollments</p>
                                        <p>{course.ALLOWMULTENROLL === "Y" ? "Yes" : "No"}</p>

                                        <p className=" mb-1 font-weight-bold">Repeatable for credit</p>
                                        <p>{course.CRSEREPEATABLE === "Y" ? "Yes" : "No"}</p>

                                        <p className=" mb-1 font-weight-bold">Primary course component</p>
                                        <p>{course.COMPONENTDESCR}</p>

                                        <p className=" mb-1 font-weight-bold">Grading method</p>
                                        <p>{course.GRADINGBASISDESCR}</p>
                                        <p>
                                            
                                            <button 
                                            className="button-link underline mt-2 " 
                                            onClick={(e) => this.handleCopyLink(e,course.SUBJECT, course.CATALOGNBR)} >
                                                <FontAwesomeIcon icon={faLink} className="" />
                                                <span className='ms-1'>Copy Course Link</span>
                                            </button>
                                        </p>
                                        <Toast show={this.state.toast} text="Copied to Clipboard!" />

                                    </Col>
                                {/* </Accordion.Collapse> */}
                            </Row>
                        {/* </Accordion> */}
                    </Col>
                </Row>


            </React.Fragment>





        );
    }
}

export default CourseItem;

function getTopicsList(course){
    var topics = []
    if(course.TOPICSLIST && course.TOPICSLIST.length > 0){
        topics.push(<p className=" mb-1 font-weight-bold">Topics</p>)
        for (let index = 0; index < course.TOPICSLIST.length; index++) {
            const topic = course.TOPICSLIST[index];
            topics.push(<p class="mb-1">{topic}</p>)
        }
    }
    return topics;
}


// function decodeKeywordMatch(course){
//     console.log("Class Item render")

//     var match = course.KEYWORDMATCH.description

//     if(match != null){
//         match = match.replace("ConstantScore","").replace(/\(/g,"").replace(/\)/g,"").replace(" -"," --and NOT ")
//         match = match.replace(/ \+/g," --and ").replace(/\+/g,"").replace(/:/g, ":  ")
//         var matches =  match.split("--");

//         if(match.indexOf("KEYWORDSEARCHSTR") >= 0){
//             matches.push("Keyword Text: "+course.CLAS.KEYWORDSEARCHSTR)
//         }

//         return matches
//     }



//     return [];
// }