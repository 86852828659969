import React from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown'
import isEqual from 'lodash.isequal';
// import isEmpty from 'lodash.isempty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";


class SearchFilterDropdown extends React.Component {

  constructor(props) {
    super();
    var checkedItems = new Map();
    props.data.map(checkBox => {
      return checkedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
    });



    this.state = {
      open: true,//props.open != null ? props.open : false,
      // buttonText: {//this one is used for when the filters are loaded from the url
      //   placeholder: props.placeholder,
      //   text: props.placeholder,
      //   active: false
      // },
      buttonInfo: {//this one is used for when the filters are updated by checking/uncheking options
        first: "",
        selected: [],
        text: props.placeholder,
        placeholder: props.placeholder
      },
      checkedItems: checkedItems,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onToggle = this.onToggle.bind(this);

  }

  componentDidMount() {
    // console.log("in componentdidmount")
    var checkedItems = new Map();
    this.props.data.map(checkBox => {
      return checkedItems.set(checkBox.value, this.props.selectedList.includes(checkBox.value));
    });
    // var buttonText = getButtonText(this.state.buttonText, checkedItems, this.props.data)

    var buttonInfo = updateButtonInfoFromURL(this.state.buttonInfo, checkedItems, this.props.data)

    this.setState({
      // checkedItems: checkedItems,
      buttonInfo: buttonInfo,
      active: buttonInfo.selected.length > 0
    })
  }

  componentDidUpdate(prevProps) {

    //console.log("in componentDidUpdate for "+this.props.placeholder)
    // console.log("old checked items ")
    // console.log(this.state.checkedItems)

    var checkedItems = new Map();
    this.props.data.map(checkBox => {
      return checkedItems.set(checkBox.value, this.props.selectedList.includes(checkBox.value));
    });

    // console.log("new checked items ")
    // console.log(checkedItems)

    if (!isEqual(this.state.checkedItems, checkedItems)) {
      //console.log("I need to update dropdown for "+this.props.placeholder)
      // var checkedItems = new Map();
      // this.props.data.map(checkBox => {
      //   return checkedItems.set(checkBox.value, this.props.selectedList.includes(checkBox.value));
      // });

      // var buttonText = getButtonText(this.state.buttonText, checkedItems, this.props.data)
      var buttonInfo = updateButtonInfoFromURL(this.state.buttonInfo, checkedItems, this.props.data)

      this.setState({
        checkedItems: checkedItems,
        // buttonText: buttonText,
        buttonInfo: buttonInfo,
        active: buttonInfo.selected.length > 0
      })
    } else {
      // console.log("I don't need to update dropdown for "+this.props.placeholder)
    }
  }

  onToggle(isOpen, e, metadata) {
    //console.log("in ontogle " + metadata.source)
    if (metadata.source === "select") {
      // this.setState({
      //   open: true,

      // })

    } else {
      this.setState({
        open: isOpen
      })
    }

  }


  handleChange(e) {
    // console.log("in handlechange")
    const item = e.target.id
    const label = e.target.getAttribute('mylabel')


    const newCheckedItems = this.state.checkedItems;
    newCheckedItems.set(item, !newCheckedItems.get(item));

    // var buttonText = getButtonText(this.state.buttonText, newCheckedItems, this.props.data)

    var buttonInfo = getButtonInfo(this.state.buttonInfo, label, e.target.checked)



    this.setState({
      checkedItems: newCheckedItems,
      buttonInfo: buttonInfo,
    })

    this.props.onChange(e, newCheckedItems);


  }


  render() {
    var data = sortData(this.props.data, "label")
    return (
      // <Dropdown show={this.state.open} onToggle={this.onToggle} >
      //   <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className="rounded-pill btn btn-outline-dark filter-button" active={this.state.active}>
      //     {this.state.buttonText.text} <i className="glyphicon glyphicon-user form-control-feedback pl-2"><FontAwesomeIcon icon={faChevronDown } /></i>
      //   </Dropdown.Toggle>
      //   <Dropdown.Menu className="filters">
      //     {
      //       data.map((item, index) => {
      //         return <Dropdown.Item
      //           key={index}
      //           eventKey={item.value}
      //         >
      //           <Form.Check
      //             type="checkbox"
      //             label={item.label}
      //             checked={this.state.checkedItems.get(item.value)}
      //             id={item.value}
      //             onChange={this.handleChange}
      //             name={this.props.name}
      //             mylabel={item.label }
      //           />
      //         </Dropdown.Item>
      //       })
      //     }
      //   </Dropdown.Menu>
      // </Dropdown>
      <form className='admin uds-form' id="skip-to-content">
        {/* <fieldset> */}
          <Dropdown className="filter-dropdown">
            {/* <Dropdown.Toggle id="dropdown-basic" variant="filter" active={this.state.active}> */}
            <Dropdown.Toggle  active={this.state.active} bsPrefix="custom-dropdown-toggle" variant="filter" >
              {this.state.buttonInfo.text}
              <div className='pull-right ms-1'><FontAwesomeIcon icon={faChevronDown} /></div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {
                data.map((item, index) => {
                  return <React.Fragment key={item.label}>
                    <Form.Check
                      type="checkbox"
                      label={getItemLabel(item.label, this.props.additionalLabelMapping)}
                      checked={this.state.checkedItems.get(item.value)}
                      id={item.value}
                      onChange={this.handleChange}
                      name={this.props.name}
                      mylabel={item.label}
                      className="filter-check  mb-2"
                      tabIndex={0}
                    />

                  </React.Fragment>
                })

              }
            </Dropdown.Menu>

          </Dropdown>
        {/* </fieldset> */}
      </form>
      // data.map((item, index) => {
      //   return <React.Fragment>
      //       <Form.Check
      //         type="checkbox"
      //         label={item.label}
      //         checked={this.state.checkedItems.get(item.value)}
      //         id={item.value}
      //         onChange={this.handleChange}
      //         name={this.props.name}
      //         mylabel={item.label }
      //       />

      //   </React.Fragment>
      // })


    );
  }
}


export default SearchFilterDropdown;

// function getButtonText(buttonText, checkedItems, data) {
//   var selectedValue, selectedLabel, text = ""
//   var count = 0


//   //This loop will tell us how many options are checked, and allow us to save the selection if it is only one
//   checkedItems.forEach(function (value, key) {
//     if (value) {//if checked
//       count++
//       selectedValue = key
//     }
//   })

//   selectedLabel = getLabel(data, selectedValue)

//   if (count === 0) {
//     text = buttonText.placeholder
//   } else if (count === 1) {
//     text = selectedLabel
//   } else {
//     if (buttonText.selectedLabel !== undefined)
//       text = buttonText.selectedLabel + " +" + (count - 1)
//     else
//       text = selectedLabel + " +" + (count - 1)
//   }


//   buttonText = {
//     selectedValue: selectedValue,
//     selectedLabel: selectedLabel,
//     selectedCount: count,
//     placeholder: buttonText.placeholder,
//     text: text
//   }





//   return buttonText
// }

// function getLabel(data, value) {
//   var label = ""
//   data.forEach((item, index) => {
//     if (item.value === value)
//       label = item.label
//     return
//   });

//   return label
// }

function sortData(data, property) {
  data.sort((a, b) => (a[property] > b[property]) ? 1 : -1)
  return data
}

function getItemLabel(label, additionalLabelMapping) {
  if (additionalLabelMapping === undefined)
    return label
  else {
    var extraLabel = ""
    if (additionalLabelMapping.has(label))
      extraLabel = " " + additionalLabelMapping.get(label)

    return label + extraLabel
  }

}

function getButtonInfo(buttonInfo, newSelection, add) {
  if (add) {
    if (buttonInfo.first === "")
      buttonInfo.first = newSelection

    buttonInfo.selected.push(newSelection)
    // console.log("selection made")
  } else {

    //deleting element from selected array
    var index = buttonInfo.selected.indexOf(newSelection);

    if (index !== -1) {
      buttonInfo.selected.splice(index, 1);
    }

    if (buttonInfo.selected.length === 0) {
      buttonInfo.first = ""

    } else {
      if (buttonInfo.first === newSelection)
        buttonInfo.first = buttonInfo.selected[0]
    }



  }


  return buttonInfo
}

function getButtonInfoText(buttonInfo) {
  //now get the label text
  var text = buttonInfo.placeholder


  if (buttonInfo.first && buttonInfo.first !== "")
    text = buttonInfo.first

  if (buttonInfo.selected.length > 1){
    return <><div className='cc-label-wrap pull-left'>{text}</div> + {buttonInfo.selected.length - 1}</>
  }else{
    return <div className='cc-label-wrap pull-left'>{text}</div>
  }
   
}


function updateButtonInfoFromURL(buttonInfo, checkedItems, dropdownInfo) {
  // console.log(checkedItems)
  // console.log("updateButtonInfoFromURL")
  var selected = []
  checkedItems.forEach((value, key) => {
    if (value) {//if checked
      var found = dropdownInfo.find((element) => element.value === key)
      selected.push(found.label)
    }
  })


  buttonInfo.selected = selected

  // console.log(" buttonInfo.first "+ buttonInfo.first)
  if ((!buttonInfo.first || buttonInfo.first === ""))
    buttonInfo.first = selected[0]
  else if (selected.length === 0)
    buttonInfo.first = ""

  buttonInfo.text = getButtonInfoText(buttonInfo)

  return buttonInfo



} 