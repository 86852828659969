import React, { Component } from "react";
import "../css/App.css";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import serviceauth from "../auth/serviceauth";
import UtilFunctions from "../UtilFunctions";
import Select from 'react-select';
import AdminUtils from "./AdminUtils";
import SuccessDialogBox from "./SuccessDialogBox";
import ErrorAlert from "./ErrorAlert";

class DeleteTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTerm: "",
            allTerms: [],
            toBeDeletedTerm: "",
            modalShow: false,
            showErrorAlert:false
        };
    }

    componentDidMount() {
        fetch(AdminUtils.getRequestObject(UtilFunctions.getTermListUrl(), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(
                (termList) => {
                    this.setState({
                        currentTerm: termList.currentTerm[0].value,
                        allTerms: termList.fullList
                    });
                }
            ).catch(err => { 
                console.log(err);
                this.setState({
                    showErrorAlert:true
                });
            });
    }

    selectTermForDeletion(e) {
        // console.log("selected term for deletion:", e)
        this.setState({
            toBeDeletedTerm: e.value
        });
    }

    deleteSelectedTerm(e) {
        e.preventDefault();
        // console.log("Term being deleted is %s", this.state.toBeDeletedTerm);

        fetch(AdminUtils.getRequestObject(AdminUtils.getDeleteTermUrl(), "DELETE", JSON.stringify({
            term: this.state.toBeDeletedTerm
        })))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then((responseData) => {
                if (responseData.output === 1) {
                    // console.log("Successfully deleted selected term!");
                    //reset state values 
                    this.setState({
                        toBeDeletedTerm: "",
                        modalShow: true
                    });
                } else {
                    // console.log("Failed to delete selected term!");
                    this.setState({
                        showErrorAlert:true
                    });
                }
            }).catch(err => { 
                console.log(err);
                this.setState({
                    showErrorAlert:true
                });
            });
    }

    render() {
        return (<Container>
                <h4 className="mb-3">
                    <mark>Terms have up to 5 min caching delay.</mark>
                </h4>
            <Form>
                <Form.Group controlId="deleteTermSelect">
                    <Form.Label>Delete Term</Form.Label>
                    <Select options={this.state.allTerms.filter(term => term.value !== this.state.currentTerm)}
                        onChange={(e) => this.selectTermForDeletion(e)} />
                </Form.Group>

                <button type="button" className="btn btn-maroon mt-3 " onClick={(e) => this.deleteSelectedTerm(e)}>
                    Submit
                </button>
            </Form>

            <SuccessDialogBox
                show={this.state.modalShow}
                onHide={() => this.setState({modalShow: false})}
                successMessage="Deleted selected term successfully"
            />

            <ErrorAlert
                show={this.state.showErrorAlert}
                onHide={() => this.setState({ showErrorAlert: false })}
            />

        </Container>)
    }
}

export default DeleteTerm;