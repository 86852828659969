import React, { Component } from "react";
import "../css/App.css";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import serviceauth from "../auth/serviceauth";
import AdminUtils from "./AdminUtils";
import SuccessDialogBox from "./SuccessDialogBox";
import ErrorAlert from "./ErrorAlert";

class Scheduler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedulerStatus:'N',
            schedulerExcludeGroup:"",
            schedulerUrl:"",
            modalShow:false,
            showErrorAlert:false
        };
        this.loadSettings = this.loadSettings.bind(this);
        this.updateSetting = this.updateSetting.bind(this);
    }

    componentDidMount() {
        this.loadSettings();
    }

    loadSettings() {
        fetch(AdminUtils.getRequestObject(AdminUtils.getSettingsListUrl(), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(
                (settingsList) => {
                    this.setState({
                        schedulerStatus: settingsList.find(setting => setting.KEY_NAME === 'scheduler_status').KEY_VALUE,
                        schedulerExcludeGroup: settingsList.find(setting => setting.KEY_NAME === 'scheduler_exclude_group').KEY_VALUE,
                        schedulerUrl: settingsList.find(setting => setting.KEY_NAME === 'scheduler_url').KEY_VALUE
                    });
                }
            ).catch(err => { 
                console.log(err);
                this.setState({
                    showErrorAlert:true
                });
            });
    }

    async updateSetting(settingKey, settingValue) {
        fetch(AdminUtils.getRequestObject(AdminUtils.getUpdateSettingUrl(), "POST", JSON.stringify({
            key: settingKey,
            value: settingValue
        })))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then((responseData) => {
                if (responseData.output === 1) {
                    // console.log("updated %s setting successully", settingKey);
                    return true;
                } else {
                    // console.log("Failed to update %s setting!", settingKey);
                    return false;
                }
            }).catch(err => { console.log(err); return false; });
    }

    async updateSchedulerSettings(e) {
        e.preventDefault();

        const responses = await Promise.all([
            this.updateSetting('scheduler_status', this.state.schedulerStatus),
            this.updateSetting('scheduler_exclude_group', this.state.schedulerExcludeGroup),
            this.updateSetting('scheduler_url', this.state.schedulerUrl)
        ]);

        if (responses.includes(false)) {
            console.log("failed to update some settings");
            this.setState({
                showErrorAlert:true
            });
            this.loadSettings(); // reload settings from server
        } else { 
            this.setState({
                modalShow: true
            });
        }
    }

    render() {
        return (<Container>

            <Table bordered hover>
                <tbody>
                    {/* <tr>
                        <td>
                            <Form>
                                <Form.Group controlId="enableEadvisor1">
                                    <Form.Label>Enable eAdvisor Scheduler links in My Classes box</Form.Label>
                                    <Form.Check 
                                        label="Yes" 
                                        type="radio" 
                                        id="enableEadvisor1.Radio1" 
                                        checked={this.state.schedulerStatus==='Y'} 
                                        onChange={() => this.setState({ schedulerStatus: 'Y' })}
                                    />
                                    <Form.Check inline 
                                        label="No" 
                                        type="radio" 
                                        id="enableEadvisor1.Radio2" 
                                        checked={this.state.schedulerStatus==='N'} 
                                        onChange={() => this.setState({ schedulerStatus: 'N' })}
                                    />
                                </Form.Group>
                            </Form>
                        </td>
                    </tr> */}

                    {/* <tr>
                        <td>
                            <Form>
                                <Form.Group controlId="enableEadvisor2">
                                    <Form.Label>Students in these groups (comma seperated) wouldn't have eAdvisor schduler linkage</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="SR25,SR0D" 
                                        value={this.state.schedulerExcludeGroup} 
                                        onChange={(event) => this.setState({ schedulerExcludeGroup: event.target.value })}
                                    />
                                    <Form.Text className="text-muted">Default value: SR0D,CT14</Form.Text>
                                </Form.Group>
                            </Form>
                        </td>
                    </tr> */}
                        
                    <tr>
                        <td>
                            <Form>
                                <Form.Group controlId="enableEadvisor3">
                                    <Form.Label>URL for eAdvisor scheduler links</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        width="80%" 
                                        value={this.state.schedulerUrl} 
                                        onChange={(event) => this.setState({ schedulerUrl: event.target.value })}
                                    />
                                </Form.Group>
                            </Form>
                        </td>
                    </tr>

                    <tr style={{backgroundColor:"#D3D3D3"}}>
                        <td align="center">
                            <button type="button" className="btn btn-maroon " onClick={(e) => this.updateSchedulerSettings(e)}>
                                Submit
                            </button>
                        </td>
                    </tr>
                </tbody>
            </Table>

            <SuccessDialogBox
                show={this.state.modalShow}
                onHide={() => this.setState({ modalShow: false })}
                successMessage="Updated scheduler settings successfully"
            />

            <ErrorAlert
                show={this.state.showErrorAlert}
                onHide={() => this.setState({ showErrorAlert: false })}
            />

        </Container>)
    }
}

export default Scheduler;