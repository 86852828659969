import  taggedCourses from './tagged_crses.json'

async function getSuggestions(prefs, data){
   return await filterBasedOnPrefs(prefs, data)
    
}



// async function getTaggedCourses() {
    
//      return fetch("https://lcabre-interactive-course-suggestions.s3-us-west-2.amazonaws.com/tagged_crses.json")
//        .then(response => response.json())

// }

async function filterBasedOnPrefs(prefs,data){
    var res = []
    Object.keys(prefs).forEach((key,index) => {
        data.forEach((sub) => {
            //Count how many times the tag we are checking appears for the subject we are checking
            var count_this_tag = sub['tags'].filter(x => x === key).length;
            //add weight
            var score = count_this_tag * prefs[key]
            res.push([sub["crse"], score])
        })
    })
   // console.log(JSON.stringify(res))
    
    
    var d = {}
    res.forEach(o => {
        if(o[0] in d){
            d[o[0]] += o[1];
        }else{
            d[o[0]] = o[1];
        }
    })


    

  
    return await sortResults(d)
 
}

async function sortResults(results){
    var array = []
    Object.keys(results).forEach(k => {
        array.push({
            s:k,
            val:results[k]
        })
    })

   // console.log(JSON.stringify(array))

    array.sort((a, b)=> b.val - a.val)
    //console.log(JSON.stringify(array))
    
    var sortedObject = {}
    for (let i = 0; i < array.length; i++) {
        sortedObject[array[i].s] = array[i].val
    }
  

    //console.log(JSON.stringify(sortedObject))
    return sortedObject
}


export {taggedCourses,getSuggestions}

