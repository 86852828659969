var SS_JWT_TOKEN= 'catalog.jwt.token'
const SERVICE_URL = process.env.REACT_APP_API_URL + "/catalog-microservices/api/v1/admin"

function getAccessToken() {
	return sessionStorage.getItem(SS_JWT_TOKEN);
}

const AdminUtils = {
    getRequestObject(url,method,reqBody){

		//console.log("called url is:%s and method is %s and body is %s", url, method, reqBody)

		const myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer '+getAccessToken());
        myHeaders.append('Content-Type', 'application/json');
        //myHeaders.append('Accept', 'application/json');

        var initObj = {
			method: method,
			headers: myHeaders,
			mode: 'cors',
			cache: 'default',
			credentials: 'include'
		}

        if(reqBody) {
            initObj['body'] = reqBody;
        }

		const myRequest = new Request(url, initObj);
		return myRequest
	},
	getSaveNewTermUrl() {
		return SERVICE_URL + "/term/save";
	},
	getDeleteTermUrl() {
		return SERVICE_URL + "/term/delete";
	},
	getDeleteSettingUrl() {
		return SERVICE_URL + "/settings/delete";
	},
	getSettingsListUrl() {
		return SERVICE_URL + "/settings/list";
	},
	getSettingUrl(setting) {
		return SERVICE_URL + "/settings/get/"+setting;
	},
	getUpdateSettingUrl() {
		return SERVICE_URL + "/settings/saveorupdate";
	},
	getUpdateViewAsUrl() {
		return SERVICE_URL + "/viewas/saveorupdate";
	},
	getViewAs() {
		return SERVICE_URL + "/viewas/get";
	},
	getCurrentElasticsearchIndexes() {
		return SERVICE_URL + "/es/getindexlist/current";
	},
	getPastElasticsearchIndexes() {
		return SERVICE_URL + "/es/getindexlist/past";
	},getESRefreshUrl() {
		return SERVICE_URL + "/es/refresh";
	},getESTermActivationUrl(term) {
		return SERVICE_URL + "/es/refresh/"+term;
	},
}

export default AdminUtils;