import React from 'react';
import Form from 'react-bootstrap/Form';
// import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import { Card, Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH, faTimes } from '@fortawesome/free-solid-svg-icons'
import StaticData from '../StaticData';


class SearchFilterMoreFilters extends React.Component {

  // constructor(props) {
  //   super();

  //   //units
  //   var creditUnitsCheckedItems = new Map();
  //   props.creditUnitsData.map(checkBox => {
  //     return creditUnitsCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  //   });

  //   //gs
  //   var gsCheckedItems = new Map();
  //   props.gsData.map(checkBox => {
  //     return gsCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  //   });

  //   //college
  //   var collegeCheckedItems = new Map();
  //   props.collegeData.map(checkBox => {
  //     return collegeCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  //   });

  //   //dayOfWeek
  //   var daysOfWeekCheckedItems = new Map();
  //   props.daysOfWeekData.map(checkBox => {
  //     return daysOfWeekCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  //   });



  //   this.state = {
  //     open: props.open != null ? props.open : false,

  //     creditUnitsCheckedItems: creditUnitsCheckedItems,
  //     gsCheckedItems: gsCheckedItems,
  //     collegeCheckedItems: collegeCheckedItems,
  //     daysOfWeekCheckedItems: daysOfWeekCheckedItems,
  //     startDate: props.searchParams.startDate === undefined ? "" : UtilFunctions.convertDateToDisplay(props.searchParams.startDate),
  //     endDate: props.searchParams.endDate === undefined ? "" : UtilFunctions.convertDateToDisplay(props.searchParams.endDate),
  //     // startTime: props.searchParams.startTime === undefined ? "" : props.searchParams.startTime,
  //     // endTime: props.searchParams.endTime === undefined ? "" : props.searchParams.endTime,
  //     honors: props.searchParams.honors === undefined ? false : props.searchParams.honors === "T",
  //     promod: props.searchParams.promod === undefined ? false : props.searchParams.promod === "T",
  //     show: false,
  //     buttonIsActive: isButtonActive(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems, props.searchParams.startDate, props.searchParams.endDate, props.searchParams.startTime, props.searchParams.endTime, props.searchParams.honors, props.searchParams.promod),
  //   };

  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleUniqueValueFilterChange = this.handleUniqueValueFilterChange.bind(this);
  //   this.onToggle = this.onToggle.bind(this);
  //   this.handleShow = this.handleShow.bind(this);
  //   this.handleClose = this.handleClose.bind(this);
  //   this.handleHonorsFocus = this.handleHonorsFocus.bind(this);
  //   this.handleHonorsBlur = this.handleHonorsBlur.bind(this);

  // }

  constructor(props) {
    super();

    var creditUnitsCheckedItems = new Map()
    var gsCheckedItems = new Map()
    var collegeCheckedItems = new Map()
    var daysOfWeekCheckedItems = new Map()
    var startDate = [""]
    var endDate = [""]
    var startTime = ""
    var endTime = ""
    var honors = [false]
    getCheckedItemsFromURL(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems, startDate, endDate, startTime, endTime, honors, props)



    this.state = {
      open: props.open != null ? props.open : false,

      creditUnitsCheckedItems: creditUnitsCheckedItems,
      gsCheckedItems: gsCheckedItems,
      collegeCheckedItems: collegeCheckedItems,
      daysOfWeekCheckedItems: daysOfWeekCheckedItems,
      startDate: startDate[0],
      endDate: endDate[0],
      // startTime: startTime,
      // endTime: endTime,
      honors: honors[0],
      // promod: props.searchParams.promod === undefined ? false : props.searchParams.promod === "T",
      show: false,
      buttonIsActive: isButtonActive(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems,
        startDate[0], endDate[0], startTime, endTime, honors[0], null),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUniqueValueFilterChange = this.handleUniqueValueFilterChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleDateFocus = this.handleDateFocus.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    // console.log("in did update")
    var creditUnitsCheckedItems = new Map()
    var gsCheckedItems = new Map()
    var collegeCheckedItems = new Map()
    var daysOfWeekCheckedItems = new Map()
    var startDate = [""]
    var endDate = [""]
    var startTime = ""
    var endTime = ""
    var honors = [false]
    getCheckedItemsFromURL(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems, startDate, endDate, startTime, endTime, honors, this.props)
    // console.log("honors after function is "+honors[0])

    const buttonIsActive = isButtonActive(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems,
      startDate[0], endDate[0], startTime, endTime, honors[0], null)

    // console.log("isButtonActive "+buttonIsActive)
    if (this.state.buttonIsActive !== buttonIsActive) {
      this.setState({
        buttonIsActive: buttonIsActive
      })
    }

  }

  // componentDidUpdate(prevProps) {
  //   // if(!isEqual(this.props.searchParams,prevProps.searchParams) ){
  //   //   console.log("Things are different, we should change ")
  //   //   console.log(this.props.searchParams)
  //   //   console.log(prevProps.searchParams)
  //   // }
  //   // console.log(this.props.searchParams)


  //   var updateState = false;
  //   //units
  //   var creditUnitsCheckedItems = new Map();
  //   this.props.creditUnitsData.map(checkBox => {
  //     return creditUnitsCheckedItems.set(checkBox.value, this.props.selectedList.includes(checkBox.value));
  //   });

  //   if (!isEqual(this.state.creditUnitsCheckedItems, creditUnitsCheckedItems)) {
  //     updateState = true
  //   }

  //   //gs
  //   var gsCheckedItems = new Map();
  //   this.props.gsData.map(checkBox => {
  //     //console.log(checkBox.value + " " +  this.props.selectedList.includes(checkBox.value))
  //     // console.log(this.props.selectedList)
  //     return gsCheckedItems.set(checkBox.value, this.props.selectedList.includes(checkBox.value));
  //   });

  //   if (!updateState && !isEqual(this.state.gsCheckedItems, gsCheckedItems)) {
  //     updateState = true
  //   }

  //   //college
  //   var collegeCheckedItems = new Map();
  //   this.props.collegeData.map(checkBox => {
  //     //console.log(checkBox.value + " " +  this.props.selectedList.includes(checkBox.value))
  //     return collegeCheckedItems.set(checkBox.value, this.props.selectedList.includes(checkBox.value));
  //   });

  //   if (!updateState && !isEqual(this.state.collegeCheckedItems, collegeCheckedItems)) {
  //     updateState = true
  //   }

  //   //daysOfWeek
  //   var daysOfWeekCheckedItems = new Map();
  //   this.props.daysOfWeekData.map(checkBox => {
  //     return daysOfWeekCheckedItems.set(checkBox.value, this.props.selectedList.includes(checkBox.value));
  //   });

  //   if (!updateState && !isEqual(this.state.daysOfWeekCheckedItems, daysOfWeekCheckedItems)) {
  //     updateState = true
  //   }

  //   if (!updateState && (
  //     this.props.searchParams.startDate !== prevProps.searchParams.startDate ||
  //     this.props.searchParams.endDate !== prevProps.searchParams.endDate ||
  //     this.props.searchParams.startTime !== prevProps.searchParams.startTime ||
  //     this.props.searchParams.endTime !== prevProps.searchParams.endTime
  //   )) {
  //     updateState = true
  //   }

  //   if (!updateState &&
  //     this.props.searchParams.honors !== prevProps.searchParams.honors) {
  //     updateState = true
  //   }

  //   if (!updateState &&
  //     this.props.searchParams.promod !== prevProps.searchParams.promod) {
  //     updateState = true
  //   }


  //   if (updateState) {
  //     this.setState({
  //       creditUnitsCheckedItems: creditUnitsCheckedItems,
  //       gsCheckedItems: gsCheckedItems,
  //       collegeCheckedItems: collegeCheckedItems,
  //       daysOfWeekCheckedItems: daysOfWeekCheckedItems,
  //       startDate: this.props.searchParams.startDate === undefined ? "" : UtilFunctions.convertDateToDisplay(this.props.searchParams.startDate),
  //       endDate: this.props.searchParams.endDate === undefined ? "" : UtilFunctions.convertDateToDisplay(this.props.searchParams.endDate),
  //       startTime: this.props.searchParams.startTime === undefined ? "" : this.props.searchParams.startTime,
  //       endTime: this.props.searchParams.endTime === undefined ? "" : this.props.searchParams.endTime,
  //       honors: this.props.searchParams.honors === undefined ? "F" : this.props.searchParams.honors === "T",
  //       promod: this.props.searchParams.promod === undefined ? "F" : this.props.searchParams.promod === "T",
  //       buttonIsActive: isButtonActive(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems, this.props.searchParams.startDate, this.props.searchParams.endDate, this.props.searchParams.startTime, this.props.searchParams.endTime, this.props.searchParams.honors, this.props.searchParams.promod)
  //     })
  //   }
  // }

  // onToggle(isOpen, e, metadata) {

  //   if (metadata.source === "select") {



  //     this.setState({
  //       open: true,

  //     })


  //   } else {
  //     this.setState({
  //       open: isOpen
  //     })
  //   }

  // }

  onToggle() {//clear un-applied filters before reopening.


    var creditUnitsCheckedItems = new Map()
    var gsCheckedItems = new Map()
    var collegeCheckedItems = new Map()
    var daysOfWeekCheckedItems = new Map()
    var startDate = [""]
    var endDate = [""]
    var startTime = ""
    var endTime = ""
    var honors = [false]

    getCheckedItemsFromURL(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems, startDate, endDate, startTime, endTime, honors, this.props)
    // console.log("in ontogle honors "+honors[0])

    // console.log(startDate)
    this.setState({

      creditUnitsCheckedItems: creditUnitsCheckedItems,
      gsCheckedItems: gsCheckedItems,
      collegeCheckedItems: collegeCheckedItems,
      daysOfWeekCheckedItems: daysOfWeekCheckedItems,
      startDate: startDate[0],
      endDate: endDate[0],
      startTime: startTime,
      endTime: endTime,
      honors: honors[0],
    })

  }


  handleChange(e, checkedItemsName) {
    e.persist()
    const item = e.target.id
    
    const newCheckedItems = this.state[checkedItemsName];
    if(e.target.type  === "radio"){
      // Set all values to false
      newCheckedItems.forEach((value, key, map) => {
        if(key.length === 7)//only reset gold values
          map.set(key, false);
      });
    }
    
    if(item !== "all")
      newCheckedItems.set(item, !newCheckedItems.get(item));
    
    


    this.setState({
      [checkedItemsName]: newCheckedItems,
      buttonIsActive: false
    })

    // this.props.onChange(e, newCheckedItems);


  }

  handleUniqueValueFilterChange(e) {
    e.persist()
    const checkedItemsName = e.target.name
    //console.log(e.target.type)
    // const item = e.target.id

    var newCheckedItems = null

    if (e.target.type === "checkbox")
      newCheckedItems = !this.state[checkedItemsName];
    else
      newCheckedItems = e.target.value

    //console.log(newCheckedItems)
    this.setState({
      [checkedItemsName]: newCheckedItems,
    }, () => {
      // this.props.onUniqueValueFilterChange(e)
    })


  }

  handleShow() {
    this.setState({
      show: true,

    })
  }

  handleClose() {
    // console.log("here")
    this.setState({
      show: false,

    })
  }

  handleHonorsFocus() {
    document.getElementById("honorsSlider").classList.add("focus");
  }

  handleHonorsBlur() {
    document.getElementById("honorsSlider").classList.remove("focus");
  }

  handleDateFocus(e) {//fix for ios safari not clearing date fields
    e.currentTarget.defaultValue = '';
  }

  handleApply(e) {
    var allFilters = new Map()
    allFilters.set("units", this.state.creditUnitsCheckedItems)
    allFilters.set("gen_studies", this.state.gsCheckedItems)
    allFilters.set("college", this.state.collegeCheckedItems)
    allFilters.set("daysOfWeek", this.state.daysOfWeekCheckedItems)

    //if(this.state.startDate !== "")
    allFilters.set("startDate", this.state.startDate)

    // if(this.state.endDate !== "")
    allFilters.set("endDate", this.state.endDate)

    //if(this.state.startTime !== "")
    allFilters.set("startTime", this.state.startTime)

    // if(this.state.endTime !== "")
    allFilters.set("endTime", this.state.endTime)

    allFilters.set("honors", this.state.honors ? "T" : "F")


    // console.log(isButtonActive(this.state.creditUnitsCheckedItems, this.state.gsCheckedItems, this.state.collegeCheckedItems, this.state.daysOfWeekCheckedItems, 
    //   this.state.startDate, this.state.endDate, this.state.startTime, this.state.endTime, this.state.honors ? "T" : "F", null))


    this.setState({
      buttonIsActive: isButtonActive(this.state.creditUnitsCheckedItems, this.state.gsCheckedItems, this.state.collegeCheckedItems, this.state.daysOfWeekCheckedItems,
        this.state.startDate, this.state.endDate, this.state.startTime, this.state.endTime, this.state.honors ? "T" : "F", null),
    })
    this.props.onChange(e, allFilters)
    this.handleClose()
  }

  render() {
    const creditUnitsData = sortData(this.props.creditUnitsData, "value")
    const gsData = sortData(this.props.gsData, "label")
    let gsMaroonData = [];
    let gsGoldData = [];
    //separate gold from maroon gs
    if(gsData){
      gsData.forEach(gs => {
        
          if(gs.value.length === 7)
            gsGoldData.push(gs);
          else
            gsMaroonData.push(gs);
      });
    }
    const collegeData = sortData(this.props.collegeData, "label")
    const daysOfWeekData = sortDays(this.props.daysOfWeekData)


    return (
      <React.Fragment >
        <button onClick={this.handleShow} className={`custom-dropdown-toggle btn btn-filter ${this.state.buttonIsActive ? 'active' : ''}`}>
          <FontAwesomeIcon icon={faSlidersH} id='more-filters-icon' className='me-2' /> {this.props.buttonTitle}
        </button>

        <Modal size="lg" show={this.state.show} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered onShow={this.onToggle}>
          {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Modal heading</Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <Card className="filters">
              <Card.Header>
                <h4 className="card-title ">
                  {this.props.title}
                  {/* <button className="glyphicon glyphicon-user pull-right  pointer closeIcon" onClick={this.handleClose} aria-label="Close" tabIndex={0}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button> */}
                  <button className="btn btn-circle btn-circle-alt-white pull-right" type="button" tabIndex={0} onClick={this.handleClose} aria-label="Close" >
                   <FontAwesomeIcon icon={faTimes} />
                    <span className="visually-hidden">
                      Close
                    </span>
                  </button>
                </h4>
              </Card.Header>
              <div className="card-body ">
                <form className='admin uds-form' id="skip-to-content">
                  <fieldset>
                    { creditUnitsData.length > 0 &&
                        <>
                          <h4>Number of Units</h4>
                          <Row>
                            {

                              creditUnitsData.map((item, index) => {
                                return <Col sm={"auto"} key={index}><Form.Check
                                  type="checkbox"
                                  label={item.label}
                                  checked={this.state.creditUnitsCheckedItems.get(item.value) === undefined ? false : this.state.creditUnitsCheckedItems.get(item.value)}
                                  id={item.value}
                                  onChange={(e) => this.handleChange(e, "creditUnitsCheckedItems")}
                                  name="units"
                                  mylabel={item.label}
                                  className="text-nowrap "
                                /></Col>

                              })
                            }
                          </Row>
                        
                      
                        <hr className="mt-3 mb-3" />
                        </>
                    }
                    <>
                      {
                        gsGoldData.length > 0 &&
                        <>
                          {/* <h4>General Studies</h4> */}
                          <div className='gs-label gs-label-gold gs-header'>General Studies Gold</div>
                          <p className='mt-1'>{StaticData.GS_GOLD_TEXT}</p>
                          <Form.Check
                                type="radio"
                                label="All General Studies Gold"
                                checked={!isGoldGsSelected(this.state.gsCheckedItems)}
                                id="all"
                                onChange={(e) => this.handleChange(e, "gsCheckedItems")}
                                name="gen_studies"
                                mylabel="All General Studies Gold"
                                key="All General Studies Gold"
                          />
                          {
                            gsGoldData.map((item, index) => {
                              return <Form.Check
                                type="radio"
                                label={item.label}
                                checked={this.state.gsCheckedItems.get(item.value) === undefined ? false : this.state.gsCheckedItems.get(item.value)}
                                id={item.value}
                                onChange={(e) => this.handleChange(e, "gsCheckedItems")}
                                name="gen_studies"
                                mylabel={item.label}
                                key={index}
                              />

                            })
                          }
                          
                        </>

                      }
                    </>
                    <>
                      {
                        gsMaroonData.length > 0 &&
                        <>
                          {/* <h4>General Studies</h4> */}
                          <div className='gs-label gs-label-maroon gs-header mt-5'>General Studies Maroon</div>
                          <p className='mt-1'>{StaticData.GS_MAROON_TEXT}</p>
                          {
                            gsMaroonData.map((item, index) => {
                              return <Form.Check
                                type="checkbox"
                                label={item.label}
                                checked={this.state.gsCheckedItems.get(item.value) === undefined ? false : this.state.gsCheckedItems.get(item.value)}
                                id={item.value}
                                onChange={(e) => this.handleChange(e, "gsCheckedItems")}
                                name="gen_studies"
                                mylabel={item.label}
                                key={index}
                              />

                            })
                          }
                          
                        </>

                      }
                    </>
                    <hr className="mt-3 mb-3" />
                    {
                        collegeData.length > 0 &&
                        <>
                        <h4>Colleges and Schools</h4>
                        {
                          collegeData.map((item, index) => {
                            return <Form.Check
                              type="checkbox"
                              label={item.label}
                              checked={this.state.collegeCheckedItems.get(item.value) === undefined ? false : this.state.collegeCheckedItems.get(item.value)}
                              id={item.value}
                              onChange={(e) => this.handleChange(e, "collegeCheckedItems")}
                              name="college"
                              mylabel={item.label}
                              key={index}
                            />

                          })
                        }
                      
                      <hr className="mt-3 mb-3" />
                      </>
                    }
                    <>
                      {
                        daysOfWeekData.length > 0 &&
                        <>
                          <h4>Days of the Week</h4>
                          <Row>
                            {
                              daysOfWeekData.map((item, index) => {
                                return <Col sm={"auto"} key={index} className="mb-2"><Form.Check
                                  type="checkbox"
                                  label={item.label}
                                  checked={this.state.daysOfWeekCheckedItems.get(item.value) === undefined ? false : this.state.daysOfWeekCheckedItems.get(item.value)}
                                  id={item.value}
                                  onChange={(e) => this.handleChange(e, "daysOfWeekCheckedItems")}
                                  name="daysOfWeek"
                                  mylabel={item.label}

                                /></Col>

                              })
                            }
                          </Row>
                          <hr className="mt-3 mb-3" />
                        </>
                      }
                    </>


                    {
                      this.props.showDates &&
                      <>
                      <h4>Dates</h4>
                      <Row>
                        <Col sm={"auto"}>
                          <label>Start date</label>
                          <Form.Control
                            type="date"
                            placeholder="MM/DD/YYYY"
                            onChange={this.handleUniqueValueFilterChange}
                            name="startDate"
                            value={this.state.startDate}
                            onFocus={this.handleDateFocus}
                            className="asu"
                          />
                        </Col>
                        <Col sm={"auto"}>
                          <label>End date</label>
                          <Form.Control
                            type="date"
                            placeholder="MM/DD/YYYY"
                            onChange={this.handleUniqueValueFilterChange}
                            name="endDate"
                            value={this.state.endDate}
                            onFocus={this.handleDateFocus}
                            className="asu"
                          />
                        </Col>
                        {/* removed on CSCC-140
                    <Col sm={"auto"}>
                      <label>Start time</label>
                      <Form.Control
                        type="time"
                        onChange={this.handleUniqueValueFilterChange}
                        name="startTime"
                        value={this.state.startTime}
                      />
                    </Col>
                    <Col sm={"auto"}>
                      <label>End time</label>
                      <Form.Control
                        type="time"
                        onChange={this.handleUniqueValueFilterChange}
                        name="endTime"
                        value={this.state.endTime}
                      />
                    </Col> */}
                      </Row>
                    
                    <hr className="mt-3 mb-3" />
                    </>
                    }
                    {
                      this.props.showHonors &&
                      <>
                      <h4>Honors</h4>
                      <Row>
                        <Col sm={"auto"}>
                          {/* <p>Honors</p> */}
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="honors"
                              onChange={this.handleUniqueValueFilterChange}
                              checked={this.state.honors}
                              onFocus={this.handleHonorsFocus}
                              onBlur={this.handleHonorsBlur}
                              tabIndex={0}
                            />
                            <span className="slider round" id="honorsSlider" tabIndex={-1} ></span>
                          </label>
                        </Col>
                              {/* <Col sm={"auto"}>
                          <p>Project-based</p>
                          <label className="switch">
                            <input 
                            type="checkbox"
                            name="promod"
                            onChange={this.handleUniqueValueFilterChange}
                            checked={this.state.promod}
                            />
                            <span className="slider round"></span>
                          </label>
                        </Col> */}
                      </Row>
                    </>
                    }
                    <button type="button" className="btn btn-maroon btn-md pull-right" onClick={this.handleApply}>Apply filters</button>



                  </fieldset>
                </form>
              </div>


            </Card>

          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>




        {/* <Dropdown show={this.state.open} onToggle={this.onToggle} >
        <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className="rounded-pill btn btn-outline-dark filter-button" active={this.state.active}>
        <i className="glyphicon glyphicon-user form-control-feedback pr-2"><FontAwesomeIcon icon={faSlidersH } /></i> More Filters
        </Dropdown.Toggle>
        <Dropdown.Menu >
          
          {
            //   this.props.data.map((item, index) => {
            //     return <Dropdown.Item
            //       key={index}
            //       eventKey={item.value}
            //     >
            //       <Form.Check
            //         type="checkbox"
            //         label={item.label}
            //         checked={this.state.checkedItems.get(item.value)}
            //         id={item.value}
            //         onChange={this.handleChange}
            //         name={this.props.name}
            //         mylabel={item.label}
            //       />
            //     </Dropdown.Item>
            //   })
          }
        </Dropdown.Menu>
      </Dropdown> */}
      </React.Fragment>

    );
  }
}


export default SearchFilterMoreFilters;

function convertDate(date) {
  try {
    var d = date.split("/")
    // console.log(d[2] + "-" + d[0] + "-" + d[1])
    return d[2] + "-" + d[0] + "-" + d[1]
  } catch (error) { }
  return date
}

function sortData(data, property) {
  // console.log(data)
  data.sort((a, b) => (a[property] > b[property]) ? 1 : -1)
  return data
}

function sortDays(data) {
  const sorter = {
    // "sunday": 0, // << if sunday is first day of week
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    "Sunday": 7
  }

  data.sort(function sortByDay(a, b) {
    return sorter[a.label] - sorter[b.label];
  });

  return data
}


// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Modal heading
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <h4>Centered Modal</h4>
//         <p>
//           Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
//           dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
//           consectetur ac, vestibulum at eros.
//         </p>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// //returns true if at least one selected filter
// function isButtonActive(selectedList,startDate,endDate,startTime,endTime,honors,promod){
//   //console.log("something selected: "+(selectedList.length > 0 && selectedList[0] !== ""))
//   let active =  !selectedList.every(element => element === "")
//   if(!active){
//     if(!isEmpty(startDate) ||
//        !isEmpty(endDate) || 
//        !isEmpty(startTime)  || 
//        !isEmpty(endTime)  || 
//         (!isEmpty(honors) && honors === "T") ||
//         (!isEmpty(promod)  && promod === "T")){
//         active = true
//       }
//   }
//   return active
// }

function getCheckedItemsFromURL(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems, startDate, endDate, startTime, endTime, honors, props) {
  //units
  props.creditUnitsData.map(checkBox => {
    return creditUnitsCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  });
  //gs
  props.gsData.map(checkBox => {
    return gsCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  });

  //college
  props.collegeData.map(checkBox => {
    return collegeCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  });

  //dayOfWeek
  props.daysOfWeekData.map(checkBox => {
    return daysOfWeekCheckedItems.set(checkBox.value, props.selectedList.includes(checkBox.value));
  });

  startDate[0] = (props.searchParams.startDate === undefined || props.searchParams.startDate === "") ? "" : convertDate(props.searchParams.startDate)
  endDate[0] = (props.searchParams.endDate === undefined || props.searchParams.endDate === "") ? "" : convertDate(props.searchParams.endDate)
  // startTime = props.searchParams.startTime === undefined ? "" : props.searchParams.startTime
  // endTime = props.searchParams.endTime === undefined ? "" : props.searchParams.endTime
  honors[0] = (props.searchParams.honors === undefined ? false : props.searchParams.honors === "T")

}


//returns true if at least one selected filter
function isButtonActive(creditUnitsCheckedItems, gsCheckedItems, collegeCheckedItems, daysOfWeekCheckedItems, startDate, endDate, startTime, endTime, honors, promod) {
  // console.log("isButtonActive " + honors)
  let active = Array.from(creditUnitsCheckedItems.values()).includes(true)
  if (!active)
    active = Array.from(gsCheckedItems.values()).includes(true)
  if (!active)
    active = Array.from(collegeCheckedItems.values()).includes(true)
  if (!active)
    active = Array.from(daysOfWeekCheckedItems.values()).includes(true)

  // console.log(creditUnitsCheckedItems)
  // console.log(gsCheckedItems)
  // console.log(collegeCheckedItems)
  // console.log(daysOfWeekCheckedItems)
  // console.log(active)
  if (!active) {
    if (!isEmpty(startDate) ||
      !isEmpty(endDate) ||
      // !isEmpty(startTime) ||
      // !isEmpty(endTime) ||
      ((!isEmpty(honors) && honors === "T") || honors) ||
      (!isEmpty(promod) && promod === "T")) {
      active = true
    }
  }
  // console.log("button is active5 "+   startDate[0])
  // console.log(startDate)
  // console.log(isEmptyArray(endDate))
  // console.log(isEmptyArray(startTime))
  // console.log(isEmptyArray(endTime))
  // console.log("honors inside function is "+honors +" and active is "+active)
  // console.log((isEmpty(honors) ))

  return active
}

//write a function that check if at least one value in a map is true
function isGoldGsSelected(map) {
  let gsSelected = false
  map.forEach((value, key) => {
    if (key.length === 7 && value)
      gsSelected = true
  })
  return gsSelected
}


// function isEmptyArray(array){
//   if(!array)
//     return true

//   //remove empty spaces
//   var filtered = array.filter(function (el) {
//     return el != null && el !== "";
//   });

//   console.log(filtered)
//   return filtered.length === 0

// }


// function getButtonText(buttonText, checkedItems, data) {
//   var selectedValue, selectedLabel, text = ""
//   var count = 0


//   //This loop will tell us how many options are checked, and allow us to save the selection if it is only one
//   checkedItems.forEach(function (value, key) {
//     if (value) {//if checked
//       count++
//       selectedValue = key
//     }
//   })

//   selectedLabel = getLabel(data, selectedValue)

//   if (count === 0) {
//     text = buttonText.placeholder
//   } else if (count === 1) {
//     text = selectedLabel
//   } else {
//     text = buttonText.selectedLabel + " +" + (count - 1)
//   }


//   buttonText = {
//     selectedValue: selectedValue,
//     selectedLabel: selectedLabel,
//     selectedCount: count,
//     placeholder: buttonText.placeholder,
//     text: text
//   }





//   return buttonText
// }

// function getLabel(data, value) {
//   var label = ""
//   data.forEach((item, index) => {
//     if (item.value === value)
//       label = item.label
//     return
//   });

//   return label
// }