import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class SuccessDialogBox extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <Modal
                show={this.props.show} onHide={this.props.onHide} 
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{backgroundColor:"#D4EDDA"}}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{color:"#006600"}}>
                        Success!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:"#D4EDDA"}}>
                    <p style={{color:"#006600"}}>{this.props.successMessage}</p>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:"#D4EDDA"}}>
                    <Button onClick={this.props.onHide} variant="success">Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default SuccessDialogBox;
