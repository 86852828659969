
import React, { Component } from 'react';
import UtilFunctions from './UtilFunctions';
import isEqual from 'lodash.isequal';
import { spinnerService } from '@simply007org/react-spinners';
// import { spinnerService } from '@chevtek/react-spinners';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

import ClassTable from './components/ClassTable';

import StaticData from './StaticData';
import serviceauth from "./auth/serviceauth";


class Routed extends Component {
    controller = null
    signal = null
    constructor(props) {
        super()
        // console.log("entered constructor")

        this.state = {
            subject: "",
            searchParams: null,
            classResults: null,
            paginatedClassResults: null,
            // spinner: true,
            aggregations: null,
            locationList: null,
            campusSelectedFilters: [],
            loadForMount: false,
            seatList: null,
            filters: {},
            classTotal: 0,
            backgroundLoad:false,//used to control if the seats api needs to be called again. If only loading background data, this API doesn't have to be called
        }

        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handleSortingChange = this.handleSortingChange.bind(this)
        this.handleUniqueValueFilterChange = this.handleUniqueValueFilterChange.bind(this)
        this.handleApplyMoreFiltersChange = this.handleApplyMoreFiltersChange.bind(this)
        this.handleClearFilters = this.handleClearFilters.bind(this)
    }






    componentDidMount() {
    //    console.log("entered component did mount for class results")

        
        this.setState({
            locationList: StaticData.locationList,
            loadForMount: true,

        })



    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("entered componend did update")

       // spinnerService.hide('mySpinner');
        //const before = Date.now();
       
        var newParams = UtilFunctions.parseUrl(this.props.location.search)
        
        

        const oldParams = UtilFunctions.parseUrl(prevProps.location.search)
        // console.log("oldState " + JSON.stringify(prevState.searchParams))
        // console.log("oldParams " + JSON.stringify(oldParams))
        //console.log("newState-params " + JSON.stringify(newParams))

        // // //console.log("Are they equal? "+isEqual(prevState.searchParams, newParams))


        if (!isEqual(oldParams, newParams) || this.state.loadForMount || !isEqual(prevState.filters, this.state.filters)
        // || this.state.sortColumn !== prevState.sortColumn || this.state.sortDirection !== prevState.sortDirection
        ) {

            spinnerService.show('mySpinner');
            if (this.controller != null) {
                this.controller.abort()
            }
            this.controller = new AbortController()
            this.signal = this.controller.signal
            this.setState({
                // searchParams: newParams,
                // spinner: true,
                loadForMount: false,
                scrollId: null
            }, () => {
               // console.log("FETCH WITH NO SCROLL ID")
                fetch(serviceauth.getRequestObject(UtilFunctions.getClassSearchUrl(newParams, this.state.filters),"GET"))
                .then((response) => {
                    if (response.status === 401) {
                      serviceauth.reauth();
                    }
                    
                    return response.json();
                  })
                    .then(async data => {
                         //console.log(JSON.stringify(newParams))
                        this.setState((prevState) => {
                            const state = {
                                classResults: data.classes,
                                //  spinner: false,
                                searchParams: newParams,
                                // filters: (!newParams.hasFilter || prevState.filters.length === 0) ? getFilters(data.classes) : prevState.filters,
                                classTotal: data.total.value,
                                scrollId: data.scrollId,
                                filters: !isEqual(oldParams, newParams) ? {} : prevState.filters,//clean filters if new search
                                backgroundLoad:false,//used to control if the seats api needs to be called again. If only loading background data, this API doesn't have to be called
                            }
                            
                           

                            return state
                        },() =>{
                            if(data.classes && data.classes.length === 0){//cscc-238 Show spinner when no classes found
                                spinnerService.show('mySpinner');
                                setTimeout(()=>{spinnerService.hide('mySpinner')}, 1000)
                            }else{
                                spinnerService.hide('mySpinner');
                            }
                        })

                        
                        


                    }).catch(function (e) {
                        //console.log(e);
                    });

                    if(newParams.honors === "T"){
                        //get honors enrichment contract
                        newParams["honorsEnrichmentContracts"] = "T"
                        fetch(serviceauth.getRequestObject(UtilFunctions.getClassSearchUrl(newParams, this.state.filters),"GET"))
                            .then((response) => {
                                if (response.status === 401) {
                                    serviceauth.reauth();
                                }
                                return response.json();
                            })
                                .then(async honorsEnrichmentContracts => {
                                    // console.log(honorsEnrichmentContracts.classes.length)
                                    // if(honorsEnrichmentContracts.classes.length > 0){
                                        this.setState((prevState) => {
                                            const state = {
                                                honorsContracts: honorsEnrichmentContracts.classes,
                                                honorsContractsScrollId: honorsEnrichmentContracts.scrollId,
                                                honorsContractsClassTotal: honorsEnrichmentContracts.total.value,
                                            }



                                            return state
                                        })
                                    // }

                                    


                                }).catch(function (e) {
                                    console.log(e);
                                });
                    }else if(this.state.honorsContracts){
                        this.setState({honorsContracts:null})
                    }

            });

        } else {
            //console.log(this.state.classTotal)
            
            if (this.state.classResults != null && this.state.classResults.length < this.state.classTotal && this.state.scrollId !== null) {
               // console.log("Need to get the rest "+ this.state.classResults.length + " < " + this.state.classTotal)
                // console.log("getting the rest of the data")
                // console.log(isEqual(oldParams, newParams) )
                // console.log("=====>prev state scroll id: "+prevState.scrollId);
                // console.log("=====>this state scroll id: "+this.state.scrollId);
                fetch(serviceauth.getRequestObject(UtilFunctions.getClassSearchUrlWithScroll(newParams, this.state.scrollId),"GET"))
                .then((response) => {
                    if (response.status === 401) {
                      serviceauth.reauth();
                    }
                    return response.json();
                  })
                    .then(data => {
                        var oldClasses = this.state.classResults

                        var newClasses = oldClasses.concat(data.classes)    

                        // console.log(JSON.stringify(data.classes))

                        this.setState({
                            classResults: newClasses,
                            // spinner: false,
                           // scrollId: data.scrollId,//no need to updaate this because it is just one per search
                            backgroundLoad:true,//used to control if the seats api needs to be called again. If only loading background data, this API doesn't have to be called
                            // filters: (!newParams.hasFilter || prevState.filters.length === 0) ? getFilters(newClasses) : prevState.filters,


                        })
                        

                    }).catch(function (e) {
                        console.log(e);
                    });





            }

            if (this.state.honorsContracts != null && this.state.honorsContracts.length < this.state.honorsContractsClassTotal) {
                // console.log("Need to get the rest "+ this.state.classResults.length + " < " + this.state.classTotal)
                 // console.log("getting the rest of the data")
 
                 newParams["honorsEnrichmentContracts"] = "T"
                 fetch(serviceauth.getRequestObject(UtilFunctions.getClassSearchUrlWithScroll(newParams, this.state.honorsContractsScrollId),"GET"))
                 .then((response) => {
                     if (response.status === 401) {
                       serviceauth.reauth();
                     }
                     return response.json();
                   })
                     .then(honorsContracts => {
 
                         var oldClasses = this.state.honorsContracts
 
                         var newClasses = oldClasses.concat(honorsContracts.classes)
 
                         this.setState({
                            honorsContracts: newClasses,
                             // spinner: false,
                             honorsContractsScrollId: honorsContracts.scrollId,
                            //  backgroundLoad:true,//used to control if the seats api needs to be called again. If only loading background data, this API doesn't have to be called
                             // filters: (!newParams.hasFilter || prevState.filters.length === 0) ? getFilters(newClasses) : prevState.filters,
 
 
                         }, () => {
                             //this.forceUpdate();
                             //console.log("New Data size " + newClasses.length)
                         })
                         
 
                     }).catch(function (e) {
                         console.log(e);
                     });
 
 
 
 
 
             }


        }


    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("shouldComponentUpdate")
        const oldParams = this.state.searchParams
        const newParams = UtilFunctions.parseUrl(nextProps.location.search)

        //if sorting
        // if(this.state.sortColumn !== nextState.sortColumn || this.state.sortDirection !== nextState.sortDirection){
        //     //console.log("shouldComponentUpdate true")
        //     return true
        // }

        // else 
        if (!isEqual(oldParams, newParams)) {//if it is a new search
            //console.log("shouldComponentUpdate true")
            return true
        } else {


            if (!isEqual(nextState.classResults, this.state.classResults)) {
                //if classes were updated, usually after a filter selection
               // console.log("shouldComponentUpdate true")
                return true
            }


        }

        // console.log("Nothing changed, so triggering no change")
     //   spinnerService.hide('mySpinner');
        //console.log("shouldComponentUpdate false")
        return false

    }

    componentWillUnmount() {
        // console.log("in component will unmount")
    }

    //for checkboxes lists
    handleFilterChange(event, checkedItems) {
        //console.log(checkedItems)
        event.persist();
        
        this.setState({
            scrollId:null,//this helps us interrupting any scrolling happening when entering a filter
            classTotal:0
        },() => {
            // console.log(checkedItems)
            var array = [];
            checkedItems.forEach((value, key) => {
                if (value) {
                    array.push(key);
                }
            })


            var name = event.target.name
            var filters = array.join(',')
            const searchParams = UtilFunctions.parseUrl(this.props.location.search)
            searchParams[name] = filters
            // console.log(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))
            this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))
        })


       

    }

    handleApplyMoreFiltersChange(allFilters) {

        
        this.setState({
            scrollId:null,//this helps us interrupting any scrolling happening when entering a filter
            classTotal:0
        },() => {
            //console.log(allFilters)
            var searchParams = UtilFunctions.parseUrl(this.props.location.search)
            for (var [name, checkedItems] of allFilters) {
                
               
                var array = [];
                if(checkedItems instanceof Map){
                    for (const [key, value] of checkedItems){
                        
                        if (value) {
                            array.push(key);
                        }
                    }
                    var filters = array.join(',')
                   // if(filters !== "")
                        searchParams[name] = filters
                }else{//individual or text values like date or honors
                    if((name === "startDate" || name === "endDate") && checkedItems !== "" ){//format date
                        var x = checkedItems.split("-")
                        checkedItems = x[1] + "/" + x[2] + "/" + x[0]
                    }
                    searchParams[name] = checkedItems
                }

            }
            //spinnerService.show('mySpinner');
            // console.log(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))
            this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))
        })


       

    }

    // handleFilterChange(name, checkedItems) {

    //     // console.log(checkedItems)
    //     var array = [];
    //     checkedItems.forEach((value, key) => {
    //         if (value) {
    //             array.push(key);
    //         }
    //     })


    //     //var name = event.target.name
    //     var filters = array.join(',')
    //    // console.log(filters)




    //     const searchParams = UtilFunctions.parseUrl(this.props.location.search)
    //     searchParams[name] = filters
    // //    console.log(searchParams)



    //     console.log(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))

    //     this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))

    // }

    //for filters with unique values like date
    handleUniqueValueFilterChange(e) {
        var name = e.target.name
       //  console.log(name)
        var value = e.target.value
       //  console.log(value)
        var type = e.target.type
        // console.log(type)
        // var value = e.target.value
        // console.log(value)
        this.setState({
            scrollId:null,//this helps us interrupting any scrolling happening when entering a filter
            classTotal:0
        },() => {
            
            if(type === "checkbox" && name !== "searchType")
                value = e.target.checked ? "T" : "F"


            if((name === "startDate" || name === "endDate") && value !== "" ){
               value = UtilFunctions.convertDateForUrl(value)
            }



            const searchParams = UtilFunctions.parseUrl(this.props.location.search)
            searchParams[name] = value

            // console.log("new URL HERE: "+this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))

            this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))
        })

    }

    handleSortingChange(column, direction) {

        const searchParams = UtilFunctions.parseUrl(this.props.location.search)
        if(column === ""){
            delete searchParams.sortby
            delete searchParams.direction
        }else{
            searchParams.sortby = column
            searchParams.direction = direction
        }
        
        // this.setState({
        //     sortColumn:column,
        //     sortDirection:direction
        // })

        this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(searchParams))


    }

    handleClearFilters(){
        var searchParams = UtilFunctions.parseUrl(this.props.location.search)
        var newSearchParams = {}
        
        if(searchParams.campusOrOnlineSelection)
            newSearchParams.campusOrOnlineSelection = searchParams.campusOrOnlineSelection;

        if(searchParams.honors)
            newSearchParams.honors = "F";

        if(searchParams.promod)
            newSearchParams.promod = "F";
        
        if(searchParams.catalogNbr)
            newSearchParams.catalogNbr = searchParams.catalogNbr;

        if(searchParams.keywords)
            newSearchParams.keywords = searchParams.keywords;

        if(searchParams.subject)
            newSearchParams.subject = searchParams.subject;

        if(searchParams.advanced)
            newSearchParams.advanced = searchParams.advanced;
        
        newSearchParams.term = searchParams.term;
        
        newSearchParams.searchType = "all";
        
        var oldParams = Object.fromEntries(new URLSearchParams(this.props.location.search.replace("?","")));
        var newParams =  Object.fromEntries(new URLSearchParams(UtilFunctions.getQueryString(newSearchParams)));
        
        if(!isEqual(oldParams,newParams))
            this.props.history.push(this.props.match.path + '?' + UtilFunctions.getQueryString(newSearchParams))
        
    }


    render() {
        //var searchType = UtilFunctions.parseUrl(this.props.location.search).searchType

        return (


            <React.Fragment>
              
                {this.state.classResults != null &&
                   

                    <ClassTable classResults={this.state.classResults}
                    searchParams={UtilFunctions.parseUrl(this.props.location.search)}
                    onChange={this.handleFilterChange}
                    onSorting={this.handleSortingChange}
                    onUniqueValueFilterChange={this.handleUniqueValueFilterChange}
                    onApplyMoreFiltersChange={this.handleApplyMoreFiltersChange}
                    backgroundLoad={this.state.backgroundLoad}
                    honorsContracts={(this.state.honorsContracts && this.state.honorsContracts.length > 0) ? "T" : "F"}
                    honorsContractsClassResults={this.state.honorsContracts}
                    classTotal={this.state.classTotal}
                    clearFilters={this.handleClearFilters}
                    />
                                
                }
                {/* {
                    this.state.honorsContracts && this.state.honorsContracts.length > 0 &&
                    <>
                        <div className="font-weight-bold container">The classes listed below offer Honors Enrichment Contracts; to inquire about contracts for classes not identified below, please contact the instructor.</div>
                        <ClassTable classResults={this.state.honorsContracts}
                        searchParams={UtilFunctions.parseUrl(this.props.location.search)}
                        onChange={this.handleFilterChange}
                        onSorting={this.handleSortingChange}
                        onUniqueValueFilterChange={this.handleUniqueValueFilterChange}
                        backgroundLoad={this.state.backgroundLoad}
                        honorsContracts="T"/>
                    </>
                } */}

            </React.Fragment>
        );
    }
}

export default Routed;
