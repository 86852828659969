import React, { Component } from "react";
import "../css/App.css";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import serviceauth from "../auth/serviceauth";
import AdminUtils from "./AdminUtils";
import SuccessDialogBox from "./SuccessDialogBox";
import ErrorAlert from "./ErrorAlert";

class OnOffStuff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemEnabled: 'Y',
            systemDownMessage: "",
            bannerStatus: 'N',
            bannerText: "",
            bannerStatusTwo: 'N',
            bannerTextTwo: "",
            bannerStatusThree: 'N',
            bannerTextThree: "",
            recommendedSubjectsStatus: 'N',
            recommendedSubjectsUrl: "",
            modalShow: false,
            showErrorAlert:false
        };

        this.loadSettings = this.loadSettings.bind(this);
        this.updateSetting = this.updateSetting.bind(this);
    }

    componentDidMount() {
        this.loadSettings();
    }

    loadSettings() {
        fetch(AdminUtils.getRequestObject(AdminUtils.getSettingsListUrl(), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(
                (settingsList) => {
                    this.setState({
                        systemEnabled: settingsList.find(setting => setting.KEY_NAME === 'system_enabled').KEY_VALUE,
                        systemDownMessage: settingsList.find(setting => setting.KEY_NAME === 'system_down_message').KEY_VALUE,
                        bannerStatus: settingsList.find(setting => setting.KEY_NAME === 'banner_status').KEY_VALUE,
                        bannerText: settingsList.find(setting => setting.KEY_NAME === 'banner_text').KEY_VALUE,
                        bannerStatusTwo: settingsList.find(setting => setting.KEY_NAME === 'banner_status_2').KEY_VALUE,
                        bannerTextTwo: settingsList.find(setting => setting.KEY_NAME === 'banner_text_2').KEY_VALUE,
                        bannerStatusThree: settingsList.find(setting => setting.KEY_NAME === 'banner_status_3').KEY_VALUE,
                        bannerTextThree: settingsList.find(setting => setting.KEY_NAME === 'banner_text_3').KEY_VALUE,
                        recommendedSubjectsStatus: settingsList.find(setting => setting.KEY_NAME === 'rec_subjects_status').KEY_VALUE,
                        recommendedSubjectsUrl: settingsList.find(setting => setting.KEY_NAME === 'rec_subjects_url').KEY_VALUE
                    });
                }
            ).catch(err => { 
                console.log(err);
                this.setState({
                    showErrorAlert:true
                });
            });
    }

    async updateSetting(settingKey, settingValue) {
        fetch(AdminUtils.getRequestObject(AdminUtils.getUpdateSettingUrl(), "POST", JSON.stringify({
            key: settingKey,
            value: settingValue
        })))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then((responseData) => {
                if (responseData.output === 1) {
                    // console.log("updated %s setting successully", settingKey);
                    return true;
                } else {
                    console.log("Failed to update %s setting!", settingKey);
                    return false;
                }
            }).catch(err => { console.log(err); return false; });
    }

    async updateSystemSetting(e) {
        e.preventDefault();

        const responses = await Promise.all([
            this.updateSetting('system_enabled', this.state.systemEnabled),
            this.updateSetting('system_down_message', this.state.systemDownMessage)
        ]);

        if (responses.includes(false)) {
            console.log("failed to update some settings");
            this.setState({
                showErrorAlert:true
            });
            this.loadSettings(); // reload settings from server
        } else { 
            this.setState({
                modalShow: true
            });
        }
    }

    async updateBannersSetting(e) {
        e.preventDefault();

        const responses = await Promise.all([
            this.updateSetting('banner_status', this.state.bannerStatus),
            this.updateSetting('banner_text', this.state.bannerText),
            this.updateSetting('banner_status_2', this.state.bannerStatusTwo),
            this.updateSetting('system_enabled',this.state.systemEnabled),
            this.updateSetting('banner_text_2', this.state.bannerTextTwo),
            this.updateSetting('banner_status_3', this.state.bannerStatusThree),
            this.updateSetting('banner_text_3', this.state.bannerTextThree)
        ]);

        if (responses.includes(false)) {
            console.log("failed to update some settings");
            this.setState({
                showErrorAlert:true
            });
            this.loadSettings(); // reload settings from server
        } else { 
            this.setState({
                modalShow: true
            });
        }
    }

    async updateRecommendedSubjectsSetting(e) {
        e.preventDefault();

        const responses = await Promise.all([
            this.updateSetting('rec_subjects_status', this.state.recommendedSubjectsStatus),
            this.updateSetting('rec_subjects_url', this.state.recommendedSubjectsUrl)
        ]);

        if (responses.includes(false)) {
            console.log("failed to update some settings");
            this.setState({
                showErrorAlert:true
            });
            this.loadSettings(); // reload settings from server
        } else { 
            this.setState({
                modalShow: true
            });
        }
    }

    render() {
        return (<Container>

            {/* <Table bordered hover>
                <tbody>
                    <tr>
                        <td>
                            <Form>
                                 <Form.Group as={Row}>
                                    <Form.Label as="legend" column sm={2}>System status</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Up"
                                            name="formHorizontalRadios"
                                            id="statusRadioOne"
                                            checked={this.state.systemEnabled === 'Y'}
                                            onChange={() => this.setState({ systemEnabled: 'Y' })} />
                                        <Form.Check
                                            type="radio"
                                            label="Down"
                                            name="formHorizontalRadios"
                                            id="statusRadioTwo"
                                            checked={this.state.systemEnabled === 'N'}
                                            onChange={() => this.setState({ systemEnabled: 'N' })} />
                                    </Col>
                                </Form.Group> 

                                <Form.Group as={Row} controlId="statusMessage">
                                    <Form.Label column sm={2}>System status Message</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="textarea" rows={3}
                                            value={this.state.systemDownMessage}
                                            onChange={(event) => this.setState({ systemDownMessage: event.target.value })} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a status message.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </td>
                    </tr>

                    <tr style={{ backgroundColor: "#D3D3D3" }}>
                        <td align="center">
                            <button type="button" className="btn btn-maroon " onClick={(e) => this.updateSystemSetting(e)}>
                                Submit
                            </button>
                        </td>
                    </tr>

                </tbody>
            </Table> */}

            <Table bordered hover>
                <tbody>
                    <tr>
                        <td>
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label as="legend" column sm={2}>Show Banner</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="formHorizontalRadios"
                                            id="bannerRadioOne"
                                            checked={this.state.bannerStatus === 'Y'}
                                            onChange={() => this.setState({ bannerStatus: 'Y' })} />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="formHorizontalRadios"
                                            id="bannerRadioTwo"
                                            checked={this.state.bannerStatus === 'N'}
                                            onChange={() => this.setState({ bannerStatus: 'N' })} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bannerText">
                                    <Form.Label column sm={2}>Banner Text</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="textarea" rows={3}
                                            value={this.state.bannerText}
                                            onChange={(event) => this.setState({ bannerText: event.target.value })} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label as="legend" column sm={2}>Show 2nd Banner</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="formHorizontalRadios"
                                            id="secBannerRadioOne"
                                            checked={this.state.bannerStatusTwo === 'Y'}
                                            onChange={() => this.setState({ bannerStatusTwo: 'Y' })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="formHorizontalRadios"
                                            id="secBannerRadioTwo"
                                            checked={this.state.bannerStatusTwo === 'N'}
                                            onChange={() => this.setState({ bannerStatusTwo: 'N' })}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bannerText">
                                    <Form.Label column sm={2}>2nd Banner Text</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="textarea"
                                            rows={3} value={this.state.bannerTextTwo}
                                            onChange={(event) => this.setState({ bannerTextTwo: event.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label as="legend" column sm={2}>Show ENG 101/ASU Sync Banner</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="formHorizontalRadios"
                                            id="thirdBannerRadioOne"
                                            checked={this.state.bannerStatusThree === 'Y'}
                                            onChange={() => this.setState({ bannerStatusThree: 'Y' })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="formHorizontalRadios"
                                            id="thirdBannerRadioTwo"
                                            checked={this.state.bannerStatusThree === 'N'}
                                            onChange={() => this.setState({ bannerStatusThree: 'N' })}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bannerText">
                                    <Form.Label column sm={2}>ENG 101/ASU Sync Banner Text</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="textarea"
                                            rows={3} value={this.state.bannerTextThree}
                                            onChange={(event) => this.setState({ bannerTextThree: event.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label as="legend" column sm={2}>System Enabled</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="formHorizontalRadios"
                                            id="systemEnabledRadioOne"
                                            checked={this.state.systemEnabled === 'Y'}
                                            onChange={() => this.setState({ systemEnabled: 'Y' })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="formHorizontalRadios"
                                            id="systemEnabledRadioTwo"
                                            checked={this.state.systemEnabled === 'N'}
                                            onChange={() => this.setState({ systemEnabled: 'N' })}
                                        />
                                    </Col>
                                </Form.Group>
                               
                            </Form>
                        </td>
                    </tr>

                    <tr style={{ backgroundColor: "#D3D3D3" }}>
                        <td align="center">
                            <button type="button" className="btn btn-maroon "onClick={(e) => this.updateBannersSetting(e)}>
                                Submit
                            </button>
                        </td>
                    </tr>

                </tbody>
            </Table>

            {/* <Table bordered hover>
                <tbody>
                    <tr>
                        <td>
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label as="legend" column sm={2}>Show Recommend Subjects</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check 
                                            type="radio" 
                                            label="Yes" 
                                            name="formHorizontalRadios" 
                                            id="formThreeRadioOne" 
                                            checked={this.state.recommendedSubjectsStatus === 'Y'} 
                                            onChange={() => this.setState({ recommendedSubjectsStatus: 'Y' })}
                                        />
                                        <Form.Check 
                                            type="radio" 
                                            label="No" 
                                            name="formHorizontalRadios" 
                                            id="formThreeRadioTwo" 
                                            checked={this.state.recommendedSubjectsStatus === 'N'} 
                                            onChange={() => this.setState({ recommendedSubjectsStatus: 'N' })}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formThreeAwsUrl">
                                    <Form.Label column sm={2}>AWS URL</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" 
                                            value={this.state.recommendedSubjectsUrl} 
                                            onChange={(event) => this.setState({ recommendedSubjectsUrl: event.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </td>
                    </tr>
                    <tr style={{ backgroundColor: "#D3D3D3" }}>
                        <td align="center">
                            <button type="button" className="btn btn-maroon " onClick={(e) => this.updateRecommendedSubjectsSetting(e)}>
                                Submit
                            </button>
                        </td>
                    </tr>

                </tbody>
            </Table> */}

            <SuccessDialogBox
                show={this.state.modalShow}
                onHide={() => this.setState({ modalShow: false })}
                successMessage="Settings updated successfully"
            />

            <ErrorAlert
                show={this.state.showErrorAlert}
                onHide={() => this.setState({ showErrorAlert: false })}
            />

        </Container>)

    }
}

export default OnOffStuff;