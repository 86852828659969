import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngry, faGrinHearts,faThumbsDown,faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga4';
import {taggedCourses,getSuggestions} from './sugestions-engine'


const SCI = "Sci";
const TECH = "Tech";
const ENG = "Eng";
const MATH = "Math";
const LANG = "Lang";
const LIT = "Lit";
const BUS = "Bus";
const CULTHUM = "CultHum";
const ART = "Arts";
const LAW = "Law";
const SOCSCI = "SocSci";
const EDUC = "Educ";
const DESARCH = "DesArch";
const PUBSVC = "Pubsvc";
const HEA = "Hea";
const MEDIACOMM = "MediaCom";
const EXP = "Exp";
const ENTRE = "Entre";
const INT = "Int";
const COMP = "Comp";
const ENGNR = "Engnr";
const SUSTAIN = "Sustain";
const COMM = "Comm";

const MAX_RESULTS = 12;
const MAX_ANSWERS = 5

const ENV = process.env.REACT_APP_HOST_ENV


//var taggedCourses = null;
class InteractiveCourseRecommendation extends Component {
  constructor(props) {
    super(props)

    // console.log(props.open);
    this.state = {
      question: getQuestion(0),
      gradingClass: new Map(),
      selectedSubject: "",
      scores: getPrefInit(),
      subjects: [],
      open: props.open ? props.open : false,
      subjectsClass: Array(MAX_RESULTS).join(".").split("."),
      resultsOpen: false,
      circleHovered:false,
      cardFadeIn:true,
      wasThisUseful:''
      //questionIndex:0
    }
  }

  async componentDidMount() {
    ReactGA.initialize(ENV === "prod" ? 'G-TEHJR60KD9' : ' ');
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: window.document.title
    });
  }

  async handleClick(grade, element) {
    this.setState({
      cardFadeIn:false
    },async ()=>{
      // if(element.target === undefined)
      // return false

      var gradingClass = new Map()
      var arr = Array(MAX_ANSWERS).join(".").split(".")
      arr[grade] = "background-asu"


      //1. Update Scores, send current scores, the new grade, and the prefs it should update for this question
    // console.log(this.state.scores)

      //making 1 and 2 grades into 0 so first results are better
      if(grade === 1 || grade === 2)
        grade = 0
      const newScores = getUpdatedScores(this.state.scores, grade, this.state.question.prefs)

      //2 get subjects from service
      const results = await processSubjectsForAnswers(newScores)

      //Communicate with catalog
      postMessage("OR")//open with results

      this.setState((prevState) => {
        var next = prevState.question.index + 1

        if (next === questions.length){
            //console.log('Complete Quiz')
            GAEvent('Complete Quiz','User completed quiz','')
        }

        GAEvent('Question-Answer Mapping',prevState.question.question,'',grade)
       // console.log("Sending to GA: question: "+prevState.question.question +", grade: "+grade)

        gradingClass.set(prevState.question.index,arr)

        return {
          question: getQuestion(next),
          gradingClass: gradingClass,
          scores: newScores,
          subjects: results,
          subjectsClass:Array(MAX_RESULTS).join(".").split("."),
          resultsOpen:true,
          cardFadeIn:true,
          //questionIndex:prevState.questionIndex + 1

        }
      }
      )

    })
  }

  handleKeyDown(grade, event){
    if(event.keyCode === 13)
      this.handleClick(grade, event)
  }

  handleSelectSubject(subject,index,element) {
    // console.log(element.target.className)
    var classes = Array(MAX_RESULTS).join(".").split(".")
    classes[index] = "card-selected"
    var q = "End of Quiz"
    try {
      q = this.state.question.question + " ("+this.state.question.index+")"
    } catch (error) {}


    GAEvent('Selected Subject',subject,q)


    this.setState({
      selectedSubject: subject,
      subjectsClass:classes
    })
    //postMessage(subject)
    this.props.onClick(subject)


  }

  handleSelectSubjectKeyDown(subject,index,event){
    if(event.keyCode === 13){
      this.handleSelectSubject(subject,index,event)
    }
  }

  handleStartOverClick(){
    GAEvent('Start Over','User clicked on Start Over link')

    this.setState({
      question: getQuestion(0),
      gradingClass: new Map(),
      selectedSubject: "",
      scores: getPrefInit(),
      subjects: [],
      open: true,
      subjectsClass: Array(MAX_RESULTS).join(".").split("."),
      resultsOpen:false,
      wasThisUseful:''
    })
    postMessage("O");//open without results
  }

  handleCollapse(){
    const { open } = this.state;
    this.setState({ open: !open })
    const index = this.state.question.index //0 if no questions have been answered
    if(!open){
      if(index === 0)
        postMessage("O");//open without results
      else
        postMessage("OR")//open with results
    }else
        postMessage("C") //closed

  }

  handleMouseEnter(answer,questionIndex,element){


    var gradingClass = new Map()
    var arr = Array(MAX_ANSWERS).join(".").split(".")
    arr[answer] = "border-asu"
    gradingClass.set(questionIndex,arr)

   // console.log(gradingClass)
    this.setState({
      gradingClass: gradingClass,
    })

  }

  handleMouseLeave(grade,element){
    //var gradingClass = get2DArray()


    this.setState({
      gradingClass: new Map(),
    })

  }

  async handleWasThisUseful(answer,element) {
    this.setState((prevState)=>{

      if(prevState.wasThisUseful !== answer){
        GAEvent("Was this helpful button","Helpful: "+answer)
        //wasThisHelpfulService(answer)

       return {
         wasThisUseful:answer
       }
      }
    })
  }

  handleWasThisUsefulKeyDown(answer,event){
    if(event.keyCode === 13){
      this.handleWasThisUseful(answer,event)
    }
  }


  render() {
    const numbers = [1, 2, 3, 4, 5];

    //const subjects = ["Psychology", "Bioengineering", "Biology", "Accountancy", "English", "Medical Studies"];
    return (
      <Container id="course-recommend" className="shadow-none pt-2 pr-sm-5 quiz-bar bg-gray-7 text-light" fluid='true'>
        <Container>
        <div className="pb-2">
        <span className='mr-2'>Take a quiz to discover subjects that interest you.  </span>

           {!this.state.open &&<button onClick={this.handleCollapse.bind(this)} aria-controls="collapse-text" aria-expanded={this.state.open}
           variant="link" className="text-gold button-link underline" id='show-quiz ml-2' tabIndex={0}> Start the course quiz. <FontAwesomeIcon icon={faCaretDown} className="ml-1"/>

           </button>}
           {this.state.open && <button onClick={this.handleCollapse.bind(this)} aria-controls="collapse-text" aria-expanded={this.state.open}
           variant="link" className="text-gold button-link underline" id='hide-quiz'> Hide <FontAwesomeIcon icon={faCaretUp} className="ml-1" />

           </button>}
        </div>


        <Collapse in={this.state.open} className="mt-2">
          <div>

            {
              this.state.question != null &&
                  <Carousel  indicators={true} controls={false} activeIndex={this.state.question != null ? this.state.question.index : questions.length}  direction={'next'} interval={null} onSelect={this.handleClick.bind(this)}>
                {questions.map((q) => {
                  return <Carousel.Item key={q.index} >
                    <div className="p-1  p-sm-3 bg-white  questions-panel  pt-sm-4">
                        <p className="text-center mt-5" style={{color: "black"}}>
                          {q.question}
                          {/* Tag: {q.prefs} */}


                        </p>
                        {q.question != null &&

                          <div className=" gray-font">


                              <Row className="no-gutters justify-content-center  ">
                                {
                                  numbers.map((number) => {

                                    return   <Col xs={2} lg={1} key={number} className="grading ">
                                    <div
                                    className={` circle  carousel-control-next ${this.state.gradingClass.get(q.index) != null ? this.state.gradingClass.get(q.index)[number] : ""} `}
                                    onClick={this.handleClick.bind(this, number)}
                                    onKeyDown={this.handleKeyDown.bind(this, number)}
                                    onMouseEnter={this.handleMouseEnter.bind(this,number,q.index)}
                                    onMouseLeave={this.handleMouseLeave.bind(this,number,q.index)}
                                    name={`answer-${number}`}
                                    tabIndex={0}>
                                      <p className="text-center  mt-3 ">{number}</p>
                                    </div>

                                      </Col>


                                  }
                                  )
                                }
                              </Row>

                            <Row className="justify-content-lg-center justify-content-sm-between pt-3">
                              <Col xs={6} sm={5}  lg={3} >I don't like it <FontAwesomeIcon icon={faAngry} /></Col>
                              <Col xs={6} sm={7}  lg={3} ><p className="pull-right pr-md-5">I love it! <FontAwesomeIcon icon={faGrinHearts} /></p></Col>
                            </Row>
                          </div>
                        }
                      </div>
                  </Carousel.Item>
                })}

              </Carousel>
            }


          {
              this.state.question == null &&
              <div className="shadow p-4 bg-white questions-panel">
                <p className="text-center mt-3 pt-3 text-dark">
                  Looks like we've reached the end! Click on one of the<br/>subjects below to find courses just for you!
                </p>
                <p className="text-center mt-5 text-dark">
                      Didn't find what you are looking for?<br/>
                      <button onClick={this.handleStartOverClick.bind(this)}  className="button-link underline"><h5 className="font-weight-bold">Start quiz over</h5></button>
                </p>

              </div>
          }

            <Row>
              <Col>
                <button onClick={this.handleStartOverClick.bind(this)}
                className="text-gold pull-right button-link mt-2 underline mb-2">Start quiz over</button>
              </Col>

            </Row>

            <Collapse in={this.state.resultsOpen}>
              <Row className="justify-content-center">
                {
                  this.state.subjects.map((subject,index) => {
                    //console.log(subject)
                    return <Col  xs={6} lg={3} key={subject.crse} className="grading">

                      <Card key={subject.crse}
                      className={` shadow  mt-3  font-color-asu  font-weight-bold ${this.state.cardFadeIn?'fadeIn':'fadeOut'} `}
                      onClick={this.handleSelectSubject.bind(this, subject.crse,index)}
                      >
                        <Card.Body key={subject.crse} className={` border-0 text-center text-secondary   ${this.state.subjectsClass[index]}`}  tabIndex={0} onKeyDown={this.handleSelectSubjectKeyDown.bind(this,subject.crse,index)}>{subject.desc} ({subject.crse})
                        {/* ({subject.score}) <br/> Tags: {subject.tags.join(" ")} */}
                      </Card.Body>
                      </Card>

                    </Col>

                  }
                  )
                }

              </Row>
            </Collapse>

            <div className="mt-4">
            <div className={`wasThisUseful font-weight-bold ${this.state.wasThisUseful !== '' ? 'd-none' : ''}`}>
              Did you find this tool helpful?
              <div className='badges'>
                <Badge variant="light" className="border border-white pl-2 pr-2"
                onClick={this.handleWasThisUseful.bind(this, 'true')}
                onKeyDown={this.handleWasThisUsefulKeyDown.bind(this, 'true')}
                tabIndex={0}>
                  <FontAwesomeIcon icon={faThumbsUp}  />
                </Badge>
                <Badge variant="light"
                className="border border-white pl-2 pr-2 ml-1"
                onClick={this.handleWasThisUseful.bind(this, 'false')}
                onKeyDown={this.handleWasThisUsefulKeyDown.bind(this, 'false')}
                tabIndex={0}>
                  <FontAwesomeIcon icon={faThumbsDown}  />
                </Badge>
              </div>

            </div>
            </div>
          </div>

        </Collapse>
        </Container>

      </Container>
    );
  }
}

export default InteractiveCourseRecommendation;

function getQuestion(question) {
  return questions[question];
}

async function getSubjectsForAnswers(newScores) {

  return await getSuggestions(newScores,taggedCourses)
}


/*
async function getSubjectsForAnswers(newScores) {

  //fetch subjects
  console.log(JSON.stringify(newScores))
  //var request = new Request('https://szj3keq5fb.execute-api.us-west-1.amazonaws.com/default/asu-bi-interactive-course-suggestions');
  var request = new Request('https://e9agwj2hje.execute-api.us-west-2.amazonaws.com/default/lcabre-interactive-course-suggestions');

  var obj = {
    action: "prefs",
    prefs: newScores
  }

  return fetch(request, {

    method: 'POST',
    body: JSON.stringify(obj)

  })
    .then(response => response.json())

}*/

async function processSubjectsForAnswers(newScores) {
  const data = await getSubjectsForAnswers(newScores);
  var results = []
  if (data != null) {
   // console.log(JSON.stringify(data))
    var count = 0;
    Object.keys(data).forEach((key) => {
      if(count < MAX_RESULTS){
        count++
        var subject = taggedCourses.find(obj => obj.crse === key)
        subject.score = data[key]
        //console.log(JSON.stringify(subject))
        results.push(subject)
        // console.log(Object.keys(result))
      }else{
        return;
      }
    })

  }


  return results
}





const questions = [
  q("I enjoy science classes. ", 0, SCI),
  //q("I enjoy English classes.", 1, ENG),
  q("I enjoy learning about technology.", 1, TECH),
  //q("I enjoy learning about people.", 2, CULTHUM),
  q("I enjoy learning about humanity and social matters.", 2, CULTHUM),
  //q("I enjoy math.", 4, MATH),
  q("I'm interested in the arts.", 3, ART),
  q("I'm interested in business.", 4, BUS),
  q("I'm interested in engineering.", 5, ENGNR),
  q("I'm interested in languages.", 6, LANG),
  q("I enjoy media and communication.", 7, MEDIACOMM),
  q("I'm interested in health.", 8, HEA),
  q("I'm interested in public service.", 9, PUBSVC),
  q("I'm interested in the environment.", 10, SUSTAIN),
  q("I'm interested in teaching and instructing.", 11, EDUC),
  q("I'm interested in the law.", 12, LAW),
]





function q(q, index, ...prefs) {
  const question = {
    question: q,
    prefs: prefs,
    index: index
  }

  return question;
}

function getPrefInit() {
  var prefs = {};
  prefs[SCI] = 0
  prefs[TECH] = 0
  prefs[ENG] = 0
  prefs[MATH] = 0
  prefs[LANG] = 0
  prefs[LIT] = 0
  prefs[BUS] = 0
  prefs[CULTHUM] = 0
  prefs[ART] = 0
  prefs[LAW] = 0
  prefs[SOCSCI] = 0
  prefs[EDUC] = 0
  prefs[DESARCH] = 0
  prefs[PUBSVC] = 0
  prefs[HEA] = 0
  prefs[MEDIACOMM] = 0
  prefs[EXP] = 0
  prefs[ENTRE] = 0
  prefs[INT] = 0
  prefs[COMP] = 0
  prefs[ENGNR] = 0
  prefs[SUSTAIN] = 0
  prefs[COMM] = 0
  return prefs;
}

function getUpdatedScores(prevScores, score, prefs) {

  prefs.forEach((prefs) => {
    prevScores[prefs] = prevScores[prefs] + score;
  });

  return prevScores;
}



function postMessage(message){
  try {

    window.parent.postMessage(message, '*');

  } catch (error) {
    //alert("error aha")
  }
}

function GAEvent(category,action,label,value){
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value:value
  });
}


