import React, { Component } from "react";
import "../css/App.css";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import serviceauth from "../auth/serviceauth";
import AdminUtils from "./AdminUtils";
import SuccessDialogBox from "./SuccessDialogBox";
import ErrorAlert from "./ErrorAlert";

class NewTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termNumber: "",
            termDescription: "",
            modalShow: false,
            showErrorAlert:false
        };
    }

    updateTermNumber(e) {
        //console.log("updated term number event:", e)
        this.setState({
            termNumber: e.target.value
        });
    }

    updateTermDescription(e) {
        //console.log("updated term description event:", e)
        this.setState({
            termDescription: e.target.value
        });
    }

    createNewTerm(e) {
        e.preventDefault();
        
        // console.log("Submitted term number is:%s and description is:%s", this.state.termNumber, this.state.termDescription)

        fetch(AdminUtils.getRequestObject(AdminUtils.getSaveNewTermUrl(), "POST", JSON.stringify({
            term: this.state.termNumber,
            description: this.state.termDescription
        })))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                if (response.status === 200) {
                    // console.log('Saved new term successfully!');
                    this.setState({
                        termNumber: "",
                        termDescription: "",
                        modalShow: true
                    });
                } else {
                    // console.log('Failed to save new term information!');
                    this.setState({
                        showErrorAlert:true
                    });
                }
            }).catch(err => { 
                console.log(err);
                this.setState({
                    showErrorAlert:true
                });
            });
    }

    render() {
        return (<Container>
            <h4 className="mb-3">
                <mark>Terms have up to 5 min caching delay.</mark>
            </h4>
            <Form>
                <Form.Group controlId="newTermNumber">
                    <Form.Label>New Term</Form.Label>
                    <Form.Control type="number" aria-describedby="termHelpInline"
                        value={this.state.termNumber} onChange={evt => this.updateTermNumber(evt)} />
                    <Form.Text id="termHelpInline" muted>
                        "2211", "2214", "2217"
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="newTermDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" aria-describedby="descrHelpInline" 
                        value={this.state.termDescription} onChange={evt => this.updateTermDescription(evt)}
                    />
                    <Form.Text id="descrHelpInline" muted>
                        "Spring 2021", "Summer 2021", "Fall 2021"
                    </Form.Text>
                </Form.Group>
                <button type="button" className="btn btn-maroon " onClick={(e) => this.createNewTerm(e)}>
                    Submit
                </button>
            </Form>

            <SuccessDialogBox
                show={this.state.modalShow}
                onHide={() => this.setState({modalShow: false})}
                successMessage="Added new term successfully"
            />

            <ErrorAlert
                show={this.state.showErrorAlert}
                onHide={() => this.setState({ showErrorAlert: false })}
            />

        </Container>)
    }
}

export default NewTerm;