import React, { Component } from 'react';
//import isEqual from 'lodash.isequal';
import Autosuggest from 'react-autosuggest';
import UtilFunctions from './../UtilFunctions';
import serviceauth from "../auth/serviceauth";


class Autosuggestions extends Component {
    constructor(props) {
      super(props);
        
      this.state = {
        value: '',
        suggestions: []
      };
      this.onKeyDown = this.onKeyDown.bind(this)
      this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    }
    
    suggestions = null;

    componentDidUpdate(prevProps, prevState, snapshot){
       
        if((prevProps.term !== this.props.term) && this.props.term !== undefined && this.props.term !== ""){

            fetch(serviceauth.getRequestObject(UtilFunctions.getSubjectSimpleListUrl(this.props.term),"GET"))
            .then((response) => {
              if (response.status === 401) {
                serviceauth.reauth();
              }
              return response.json();
            })
                .then( subjects => {
                  // console.log(subjects)
                    this.suggestions = subjects
        
                })
                    
    
               
        }
        if(prevProps.value !== this.props.value){
            this.setState({
                value:this.props.value
            })
        }
    }

    //calculate suggestions for any given input value.
   getSuggestions = value => {
    const inputValue = value.trim().toUpperCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0 ? [] : this.suggestions.filter(s => 
      s.CODE.toUpperCase().indexOf(inputValue) >= 0
    );
  };
  
  // When suggestion is clicked, Autosuggest needs to populate the input
   getSuggestionValue = suggestion => suggestion.SUBJECT.toUpperCase().split(":")[0];
  
  // render suggestions.
   renderSuggestion = suggestion => (
    <div><strong>{suggestion.CODE} </strong>{suggestion.DESCR}</div>
  );

   
  
    onChange = (event, { newValue }) => {
      this.setState({
        value: newValue.toUpperCase()
      },()=>{
        var event2 = {
            target:{
                type: "input",
                name: this.props.name,
                value: newValue
            }
        }
          this.props.onChange(event2)
      });
    };
  
    // Autosuggest will call this function every time you need to update suggestions.
    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    };
  
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };
    
    //Used so that the search is not activated when hitting enter on one of the suggestions.
    suggestionSelected = false;
    onSuggestionSelected(event){
      if(event.keyCode === 13)
        this.suggestionSelected = true;
    }

    onKeyDown(event){
      if(!this.suggestionSelected)
        this.props.onKeyDown(event)

      this.suggestionSelected = false;
    }
  
    render() {
      const { value } = this.state;
  
      // Autosuggest will pass through all these props to the input.
      const inputProps = {
        placeholder: this.props.placeholder,
        value,
        onChange: this.onChange,
        name: this.props.name,
        onKeyDown:this.onKeyDown,
        className:"form-control asu upper"
      };
  
    
      return (
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
        />
      );
    }
  }

// const languages = [
//     {
//       name: 'C',
//       year: 1972
//     },
//     {
//       name: 'Elm',
//       year: 2012
//     },
//   ];
  

  
  

export default Autosuggestions
