import React from 'react';
import serviceauth from './serviceauth'
// import $ from "jquery";

// import {
    
//     Redirect,
    
//   } from 'react-router-dom'
  
// const queryString = require('query-string');



class Logout extends React.Component {
    constructor(props) {
      super(props)
  
  
  
      this.state = {
        redirectToReferrer: false,
        urlFromState:""
      }
  
  
  
  
    }
  
    componentDidMount() {
  
      }
  
      render() {
        console.log("signing out")
        sessionStorage.removeItem(serviceauth.SS_JWT_TOKEN);
        sessionStorage.removeItem(serviceauth.SS_SA_CODE_VERIFIER);
        sessionStorage.removeItem(serviceauth.SS_SA_STATE);
        sessionStorage.removeItem(serviceauth.SS_USER);
        sessionStorage.removeItem(serviceauth.SS_NAME);
        sessionStorage.removeItem(serviceauth.SS_VIEW_AS);
        sessionStorage.removeItem(serviceauth.SS_VIEW_AS_USER);
        sessionStorage.removeItem(serviceauth.SS_IS_ONLINE);
        sessionStorage.removeItem(serviceauth.SS_IS_STUDENT);
        sessionStorage.removeItem(serviceauth.SS_CAREER);
        sessionStorage.removeItem("app.settings")
        window.location.href = "https://weblogin.asu.edu/cas/logout"
       
        return null
  
        
      }
    }

    export default Logout