import React, { Component } from 'react';
import HashSet from 'hashset'
import isEmpty from 'lodash.isempty';
import StaticData from '../StaticData';
// import MultipleChoiceSelect from './MultipleChoiceSelect'
import isEqual from 'lodash.isequal';
import SearchFilterDropdown from "./SearchFilterDropdown"
import SearchFilterMoreFilters from "./SearchFilterMoreFilters"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Button } from 'react-bootstrap';
import { spinnerService } from '@simply007org/react-spinners';
import UtilFunctions from '../UtilFunctions';

// import { json } from 'sjcl';

const CAMPUS_FILTER = "campus"
const COLLEGE_FILTER = "college"
const SESSION_FILTER = "session"
const LEVEL_FILTER = "level"
const UNITS_FILTER = "units"
const GS_FILTER = "gen_studies"
const DAYS_FILTER = "daysOfWeek"
const SEARCH_TYPE = "searchType"
// const HONORS = "honors"
// const STARTDATE = "startDate"
// const ENDDATE = "enddate"
const filtersList = [CAMPUS_FILTER, COLLEGE_FILTER, SESSION_FILTER, LEVEL_FILTER, UNITS_FILTER, DAYS_FILTER, GS_FILTER]


class FilterPane extends Component {
    constructor(props) {
        super()

        this.state = {
            filters: getFilters(props.data),
            selectedFilters: UtilFunctions.getSelectedFiltersFromSearchParams(props.searchParams, filtersList),
            searchType: props.searchParams.searchType,
        }
        this.handleDropdownFilterChange = this.handleDropdownFilterChange.bind(this)
        // this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handleUniqueValueFilterChange = this.handleUniqueValueFilterChange.bind(this)
        this.handleApplyMoreFilters = this.handleApplyMoreFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.handleSwitchFocus = this.handleSwitchFocus.bind(this);
        this.handleSwitchBlur = this.handleSwitchBlur.bind(this);
        this.switchRef = React.createRef();

    }

    componentDidUpdate(prevPros) {
        // spinnerService.show('mySpinner');
        // console.log(this.props.searchParams )
        // console.log(prevPros.searchParams)
        const newSelectedFilters = UtilFunctions.getSelectedFiltersFromSearchParams(this.props.searchParams, filtersList)
        if (!isEqual(prevPros.data, this.props.data)) {

            // spinnerService.hide('mySpinner');
            // const newSelectedFilters = UtilFunctions.getSelectedFiltersFromSearchParams(this.props.searchParams,filtersList)
            // if (!isEqual(this.props.searchParams,prevPros.searchParams) 
            //     && (isEmpty(newSelectedFilters) || isEqual(newSelectedFilters, this.state.selectedFilters)) ) {
            //     console.log("if")
            //     this.setState({
            //         filters: getFilters(this.props.data),
            //         selectedFilters: newSelectedFilters,
            //     })
            // } else {
            //     console.log("else")
            //     this.setState({
            //         selectedFilters: newSelectedFilters,
            //     })
            // }

            if (isEmpty(newSelectedFilters)) {
                //console.log(newSelectedFilters)

                this.setState({
                    filters: getFilters(this.props.data),
                    selectedFilters: newSelectedFilters,
                    searchType: this.props.searchParams.searchType,
                })
            } else {
                // console.log(addToFilters(this.props.data,this.state.filters))

                this.setState({
                    filters: addToFilters(this.props.data, this.state.filters),
                    selectedFilters: newSelectedFilters,
                    searchType: this.props.searchParams.searchType,
                })
            }



        } else {// when user selects a filter and there are no actual results to show CSCC-367
            if (!isEqual(this.state.selectedFilters, newSelectedFilters)) {
                this.setState({
                    selectedFilters: newSelectedFilters,
                })
            }
        }


    }


    handleDropdownFilterChange(event, newCheckedItems) {
        spinnerService.show('mySpinner');
        // console.log(newCheckedItems)

        this.props.onChange(event, newCheckedItems);
    }

    // handleDropdownFilterChange(name, newCheckedItems) {
    //     //console.log("here")
    //      this.props.onChange(name, newCheckedItems);
    //  }

    handleUniqueValueFilterChange(e) {
        // alert(e.target.name)
        spinnerService.show('mySpinner');
        // console.log(e.target.value)
        e.persist()
        if (e.target.name === "searchType") {
            var value = "open"
            if (e.target.value === "open")
                value = "all"
            e.target.value = value
        }
        this.setState({
            searchType: value
        }, () => {
            this.props.onUniqueValueFilterChange(e)
        })

        //    }

    }

    handleApplyMoreFilters(event, filters) {
        this.props.onApplyMoreFiltersChange(filters);
    }

    handleClearFilters() {
        this.props.clearFilters();
    }

    handleSwitchFocus(){
        this.switchRef.current.className = "btn-color-mode-switch-inner btn-color-mode-switch-inner-focus"
    }

    handleSwitchBlur(){
        this.switchRef.current.className = "btn-color-mode-switch-inner"
    }


    render() {
        // console.log("in render "+this.state.searchType)
        // var buttonClass = "rounded-pill btn btn-outline-dark filter-button btn-primary"
        // if (this.state.searchType === "open")
        //     buttonClass += " active"



        return (
            (this.props.resultsCount > 0 || !hideFilters()) &&
            <Row className="mb-4 filter mt-3 filter-dropdown " >
                {this.state.filters &&
                    this.state.filters.map((filter, index) => {
                        return (filter[4] === "dropdown" &&
                            <Col xs={"auto"} key={index} className="mb-2">
                                <SearchFilterDropdown label={filter[0]}
                                    data={filter[2]}
                                    placeholder={filter[0]}
                                    onChange={this.handleDropdownFilterChange}
                                    selectedList={this.state.selectedFilters}
                                    name={filter[1]}
                                    className={filter[1]}
                                    filter="true"
                                    additionalLabelMapping={filter[6]} /></Col>)

                            ||


                            // (filter[4] === "button" &&
                            // <Col xs={"auto"} key={"open"} className="mb-2">
                            //     <Button 
                            //     className={buttonClass}
                            //     variant="outline-light" 
                            //     name={filter[1]}
                            //     onClick={this.handleUniqueValueFilterChange}
                            //     value={this.state.searchType}  
                            //     >
                            //         {filter[0]}
                            //     </Button>

                            // </Col>)

                            // ||
                            (
                                filter[4] === "button" &&
                                <Col xs={"auto"} key="open" className='mb-2'>
                                    <div className="btn-container" tabIndex={-1}>
                                        <label className="switch btn-color-mode-switch" tabIndex={-1}>
                                            <input role="switch" type="checkbox" name={filter[1]} id="color_mode" 
                                                onClick={this.handleUniqueValueFilterChange} 
                                                value={this.state.searchType} checked={this.state.searchType === "open"} tabIndex={0} 
                                                onFocus={this.handleSwitchFocus} onBlur={this.handleSwitchBlur}
                                                aria-label="Check to filter by open seats"/>
                                                <label aria-hidden="true"  ref={this.switchRef} for="color_mode" data-on="Open Seats" 
                                                data-off="All Classes" className="btn-color-mode-switch-inner">
                                                    
                                                </label>
                                        </label>
                                    </div>
                                </Col>
                            )

                    })
                }
                <Col xs={"auto"} className="mb-2">

                    <SearchFilterMoreFilters
                        creditUnitsData={this.state.filters[4][2]}
                        selectedList={this.state.selectedFilters}
                        gsData={this.state.filters[5][2]}
                        collegeData={this.state.filters[6][2]}
                        daysOfWeekData={this.state.filters[7][2]}
                        onChange={this.handleApplyMoreFilters}
                        // onChange={this.handleDropdownFilterChange}
                        // onUniqueValueFilterChange={this.handleUniqueValueFilterChange}
                        searchParams={this.props.searchParams}
                        filter="true" 
                        title="More Filters"
                        buttonTitle="More Filters"
                        showHonors={true}
                        showDates={true}/>
                </Col>

                <Col className='pt-2' xs={"auto"}>
                    <h6><a className=' pl-0 ' href='#!' onClick={this.handleClearFilters}>Clear filters</a></h6>
                </Col>
            </Row>


            

        )
    }

}

export default FilterPane

function getFilters(classResults) {
    //console.log("in get filters")
    if (classResults !== undefined) {
        var locationSet = new HashSet();
        var collegeSet = new HashSet();
        var sessionSet = new HashSet();
        var searchTypeSet = new HashSet();
        var gsSet = new HashSet();
        //var collegeSet = new HashSet();
        // var sessionDatesSet = new HashSet();
        //var levelIdSet = new HashSet();
        var levelSet = new HashSet();
        var unitsSet = new HashSet();
        var daysOfTheWeekSet = new HashSet()
        var hasOpenSeats = false;
        var sessionDatesMap = new Map()
        classResults.forEach((myClass, index) => {

            if (myClass.LOCATIONBUILDING) {
                myClass.LOCATIONBUILDING.forEach(building => {
                    if (building.NAME === "iCourse")
                        locationSet.add("ICOURSE")
                    else if (building.NAME === "ASU Sync")
                        locationSet.add("ASUSYNC")
                    else
                        locationSet.add(myClass.CLAS.LOCATION)
                });
            }

            // locationSet.add(myClass.CLAS.LOCATION)


            collegeSet.add(myClass.CLAS.ACADGROUP)

            //+ " ("+  myClass.CLAS.MEETINGDATES[0] + " - " +  myClass.CLAS.MEETINGDATES[1] + ")"

            sessionSet.add(myClass.CLAS.SESSIONCODE)
            // console.log(myClass.CLAS.SESSIONCODE)
            if (myClass.CLAS.SESSIONCODE !== "DYN") {
                sessionDatesMap.set(myClass.CLAS.SESSIONCODE, " (" + myClass.CLAS.MEETINGDATES[0][0] + " - " + myClass.CLAS.MEETINGDATES[0][1] + ")")
            }
            var units = 0;
            try {
               
                units = Math.trunc(myClass.CLAS.UNITSMAXIMUM);
                if(units > 7)
                    units = 7;
                else if(units < 1)
                    units = 0;
            } catch (error) {}

            var unitsMinimum = myClass.CLAS.UNITSMINIMUM < myClass.CLAS.UNITSMAXIMUM ? myClass.CLAS.UNITSMINIMUM : units;
            
            //because we want the full range of numbers be options for filtering
            // console.log("myClass.CLAS.UNITSMAX "+myClass.CLAS.UNITSMAXIMUM)
            // console.log("myClass.CLAS.UNITSMINIMUM "+myClass.CLAS.UNITSMINIMUM)
            // console.log("units " +units)

            // if(myClass.CLAS.CLASSTYPE && myClass.CLAS.CLASSTYPE === 'N'){
            //     unitsSet.add("0");
            // }else{
                for (let index = unitsMinimum; index <= units; index++) {
                    unitsSet.add(index.toString())
                }
            // }
            
            
            
            levelSet.unionWith(getClassLevel(myClass.CLAS.CATALOGNBR))
            // const levels = getClassLevel(myClass.CLAS.CATALOGNBR)
            // levels.forEach((level) => {
            //     if (!levelIdSet.contains(level.id)) {
            //         levelIdSet.add(level.id)

            //     }

            // })

            //days of the week
            // console.log( myClass.DAYLIST)
            myClass.DAYLIST.map((day) => {
                let dayArray = day.split(" ")
                if (dayArray.includes("M"))
                    daysOfTheWeekSet.add("MON")
                if (dayArray.includes("T"))
                    daysOfTheWeekSet.add("TUES")
                if (dayArray.includes("W"))
                    daysOfTheWeekSet.add("WED")
                if (dayArray.includes("Th"))
                    daysOfTheWeekSet.add("THURS")
                if (dayArray.includes("F"))
                    daysOfTheWeekSet.add("FRI")
                if (dayArray.includes("Sa"))
                    daysOfTheWeekSet.add("SAT")
                if (dayArray.includes("Su"))
                    daysOfTheWeekSet.add("SUN")
                return daysOfTheWeekSet
            })

            if (!hasOpenSeats && (myClass.seatInfo !== undefined && myClass.seatInfo.ENRL_CAP > myClass.seatInfo.ENRL_TOT))
                hasOpenSeats = true



            //General Studies
            var gsArray = getCleanGsList(myClass.GSMAROON)
            if (gsArray !== null) {
                gsArray.forEach((gs, i) => {
                    if (gs !== "")
                        gsSet.add("GS-" + gs)
                })
            }
            
            if(myClass.GSGOLD && myClass.GSGOLD !== "")
                gsSet.add("GS-"+myClass.GSGOLD);

         // console.log(gsSet.toArray())

        })



        searchTypeSet.add("Open")





        //label,id,list
        // console.log(sessionDatesMap)
        return [
            ["Open Seats", SEARCH_TYPE, hasOpenSeats, searchTypeSet, "button", ""],
            ["Location", CAMPUS_FILTER, mapNames(locationSet.toArray(), "locationList"), locationSet, "dropdown", "locationList"],
            ["Level", LEVEL_FILTER, mapNames(levelSet.toArray(), "levelList"), levelSet, "dropdown", "levelList"],
            ["Session", SESSION_FILTER, mapNames(sessionSet.toArray(), "sessionsList"), sessionSet, "dropdown", "sessionsList", sessionDatesMap],
            ["Number of Units", UNITS_FILTER, mapNames(unitsSet.toArray(), "unitsList"), unitsSet, "", "unitsList"],
            ["General Studies", GS_FILTER, mapNames(gsSet.toArray(), "gsList"), gsSet, "", "gsList"],
            ["Colleges and Schools", COLLEGE_FILTER, mapNames(collegeSet.toArray(), "collegeList"), collegeSet, "", "collegeList"],
            ["Days of the Week", DAYS_FILTER, mapNames(daysOfTheWeekSet.toArray(), "daysList"), daysOfTheWeekSet, "", "daysList"]
        ]

        // 
        // ,]


    }

    return false
}

function addToFilters(classResults, oldFilters) {

    var newFilters = getFilters(classResults)
    //console.info(newFilters)

    for (let i = 0; i < newFilters.length; i++) {
        oldFilters[i][3].unionWith(newFilters[i][3])


        if (oldFilters[i][5] !== "")
            oldFilters[i][2] = mapNames(oldFilters[i][3].toArray(), oldFilters[i][5])

        if (oldFilters[i].length === 7) {//join session dates

            oldFilters[i][6] = combineMaps(oldFilters[i][6], newFilters[i][6])
        }

    }


    return oldFilters
}

function combineMaps(map1, map2) {
    var myNewMap = new Map();

    for (const [key, value] of map1) {
        myNewMap.set(key, value)
    }

    for (const [key, value] of map2) {
        myNewMap.set(key, value)
    }


    return myNewMap
}


function getClassLevel(catalogNbr) {

    var levels = new HashSet()
    if (catalogNbr != null && catalogNbr.length > 0) {
        try {
            const nbr = parseInt(catalogNbr.substring(0, 3))
            if (0 <= nbr && nbr <= 499) {
                // console.log(JSON.stringify(clas))
                levels.add("undergrad")
            }

            if (500 <= nbr) {
                levels.add("grad")
            }

            if (0 <= nbr && nbr <= 299) {
                levels.add("lowerdivision")
            }

            if (300 <= nbr && nbr <= 499) {
                levels.add("upperdivision")
            }

            if (100 <= nbr && nbr <= 199) {
                levels.add("100-199")
            } else if (200 <= nbr && nbr <= 299) {
                levels.add("200-299")
            } else if (300 <= nbr && nbr <= 399) {
                levels.add("300-399")
            } else if (400 <= nbr && nbr <= 499) {
                levels.add("400-499")
            }

        } catch (error) {
            console.log("Error getting level filters " + error.stack)
        }
    }

    // if(levels.length === 0)
    //     levels.push({value:"x",name:"x"})

    //console.log("Loading filters  took:" + (Date.now() - before) / 1000 + " seconds");

    // console.log(levels)
    return levels
}




function mapNames(array, map) {

    var results = new HashSet();
    const nameList = StaticData[map]

    // console.log(array)
    // console.log(map)
    array.forEach(element => {

        var name = null;
        name = nameList.find(item => {
            const id = item.value.split(",")//continue here split the array of ids to use === instead of indexoff
            if (id.includes(element))
                return element
            else return null
        })


        if (name != null) {
            results.add(name)
        }
    })

    // console.log(array)
    // console.log(results.toArray()) 
    return results.toArray()
}




function getCleanGsList(gs) {
    if (gs && gs !== "") {
        gs = gs.replace(/\(/g, "")
        gs = gs.replace(/\)/g, "")
        gs = gs.replace(/&/g, "")
        gs = gs.replace(/or/g, "")
        return gs.split(" ");
    }
    return null
}

//returns false if at least one filter is found
function hideFilters() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    for (const [key, value] of urlParams) {

        if (filtersList.includes(key) ||
            (key === "honors" && value === "T") ||
            (key === "startdate" && value !== "") ||
            (key === "enddate" && value !== "")) {
            return false
        }

    }
    return true

}