import React, { Component } from "react";
import "./css/App.css";
import "./css/Autosuggest.css";
import UtilFunctions from "./UtilFunctions";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Jumbotron from "react-bootstrap/Jumbotron";
import Form from "react-bootstrap/Form";
import { Route, Switch,Link} from "react-router-dom";
import CourseResults from "./CourseResults";
import isEmpty from "lodash.isempty";
//import { Link } from 'react-router-dom'
// import Button from "react-bootstrap/Button";
import StaticData from "./StaticData";
import Background from "./images/course-catalog-background.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import Menu from "./components/Menu";
import BrowseBySubject from "./components/BrowseBySubject";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import ReactMultiselect from "./components/ReactMultiselect";
// import Accordion from "react-bootstrap/Accordion";
import isEqual from "lodash.isequal";
import Autosuggestions from "./components/Autosuggestions";
import "./css/InteractiveCourseRecommendation.css";
import InteractiveCourseRecommendation from "./components/courserecommendation/InteractiveCourseRecommendation";
import serviceauth from "./auth/serviceauth";
import CatalogHomeSearch from "./CatalogHomeSearch";
import scrollToComponent from 'react-scroll-to-component';
import ClassBanner from "./components/ClassBanner";


const jumbotronBackground = {
  backgroundPosition: "0% 68%",
  backgroundSize: "cover",
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(" + Background + ")",
};


class Search extends Component {
  constructor(props) {
    super(props);
    const data = UtilFunctions.parseUrl(this.props.location.search);
    var quizOpen = data.quizOpen ? data.quizOpen === 'Y' : false;
    this.refToQuiz = React.createRef();

    this.state = {
      term: "",
      subject: "",
      catalogNbr: "",
      searchType: "all",
      keywords: "",
      college: [],
      gsList: StaticData.gsList,
      level: [],
      termList: [],
      gs: [],
      advancedSearchOpen: localStorage.getItem("advancedSearchOpen"),
      browseBySubject: false,
      suggestions: [],
      value: "",
      navTree: [],
      quizOpen:quizOpen,
      showBanner2: "N",
      banner2: null,
    };

    //console.log(this.state.advancedSearchOpen)

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.pushToHistory = this.pushToHistory.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleBrowseBySubject = this.handleBrowseBySubject.bind(this);
    this.handleOpenAdvancedSearch = this.handleOpenAdvancedSearch.bind(this);
    this.getSearchBySubjectURL = this.getSearchBySubjectURL.bind(this);
    this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
    this.handleInteractiveCourseSelection = this.handleInteractiveCourseSelection.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.originalState = this.state;
  }

  async componentDidMount() {
    window.document.title = "Course Catalog Search | Arizona State University";

    const appSettings = await UtilFunctions.getAppSettings()

    //load term list
    // console.log("in component did mount catalog search ")
    fetch(serviceauth.getRequestObject(UtilFunctions.getTermListUrl(),"GET"))
    .then((response) => {
      if (response.status === 401) {
        serviceauth.reauth();
      }
      return response.json();
    })
      .then(async (termList) => {
        //update fields from url
        const data = UtilFunctions.parseUrl(this.props.location.search);
        const term = isEmpty(data.term)
          ? termList.currentTerm[0].value
          : data.term;
        //  const subjectsList = await getSubjectsList(this.state.term);
        // console.log(term)
      //  console.log(termList.futureList)
        this.setState({
          term: term,
          subject: isEmpty(data.subject) ? "" : data.subject,
          catalogNbr: isEmpty(data.catalogNbr) ? "" : data.catalogNbr,
          searchType: isEmpty(data.searchType) ? "all" : data.searchType,
          keywords: isEmpty(data.keywords) ? "" : data.keywords,
          instructor: isEmpty(data.instructorName) ? "" : data.instructorName,
          classNbr: isEmpty(data.classNbr) ? "" : data.classNbr,
          daysOfWeek: isEmpty(data.daysOfWeek) ? [] : data.daysOfWeek.join(","),
          termList: termList.futureList.concat(termList.fullList),
          gs: isEmpty(data.gen_studies) ? [] : data.gen_studies.split(","),
          gsList: getGsEnableDisable(
            isEmpty(data.gen_studies) ? [] : data.gen_studies.split(",")
          ),
          college: isEmpty(data.college) ? [] : [data.college],
          level: isEmpty(data.level) ? [] : [data.level],
          navTree: await UtilFunctions.getHeaderNavTree(term,"courses"),
          // subjectsList:subjectsList
          // check appSettings
          showBanner2: appSettings ? appSettings.get("banner_status_2") : 'N',
          banner2: appSettings? appSettings.get("banner_text_2") : 'Important information pending.',
        });
        //this.pushToHistory2(term);
      });

      if(this.state.quizOpen){
        scrollToComponent(this.refToQuiz.current);
      }
  }

  componentDidUpdate(prevProps) {
    const newParams = UtilFunctions.parseUrl(this.props.location.search);
    const oldParams = UtilFunctions.parseUrl(prevProps.location.search);

    //update subject when it comes from browse by subject, or when deleting a filter
    if (
      !isEqual(oldParams, newParams) &&
      newParams.subject !== oldParams.subject &&
      newParams.subject !== undefined
      //||
      //!UtilFunctions.arraysAreEqual(oldParams.gs, newParams.gs )  ||
      //!UtilFunctions.arraysAreEqual(oldParams.college, newParams.college ) ||
      //!UtilFunctions.arraysAreEqual(oldParams.level, newParams.level )
    ) {
      this.setState({
        subject: isEmpty(newParams.subject) ? "" : newParams.subject,
      });
    }
  }

  handleChange(event, checkedItems) {

    const type = event.target.type;
    var name = event.target.name;

    if (type === "radio" || type.indexOf("select") >= 0) {
      this.setState({ [name]: event.target.value }, () => {
        this.pushToHistory();
      });
    } else if (type === "checkbox") {
      var val = [];
      var gsList = this.state.gsList;
      if (checkedItems != null) {
        //multiple select

        checkedItems.forEach((value, key) => {
          if (value) {
            val.push(value.value);
          }
        });

        // GS disabling options
        if (name === "gs") {
          gsList = getGsEnableDisable(checkedItems);
        }
      } else {
        //regular checkbox

        val = event.target.checked;
      }
      this.setState(
        {
          [name]: val === undefined ? "" : val,
          gsList: gsList,
        },
        () => {
          this.pushToHistory();
        }
      );
    } else {
      var value = event.target.value;
      if (name !== "keywords") value = value.toUpperCase();
      this.setState({ [name]: value });
    }
  }

  handleDateTimeChange(type, start, end) {
    if (type === "date") {
      this.setState(
        {
          startDate: start == null ? "" : start.toLocaleDateString(),
          endDate: end == null ? "" : end.toLocaleDateString(),
        },
        () => {
          // console.log("pushing history date " + this.state.startDate)
          this.pushToHistory();
        }
      );
    } else {
      this.setState(
        {
          startTime: start == null ? "" : start.toTimeString().split(" ")[0],
          endTime: end == null ? "" : end.toTimeString().split(" ")[0],
        },
        () => {
          this.pushToHistory();
        }
      );
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.pushToHistory();
    }
  }

  handleButtonClick() {
    this.pushToHistory();
  }

  pushToHistory() {
    this.props.history.push(
      this.props.match.path +
        "/courselist?" +
        UtilFunctions.getCatalogQueryStringFromState(this.state)
    );
  }

  pushToHistory2(term) {
    this.props.history.push(
      this.props.match.path +
        "/courselist?term=" + term
    );
  }

  handleClearAll() {
    this.setState(
      {
        subject: "",
        catalogNbr: "",
        searchType: "all",
        keywords: "",
        college: [],
        level: [],
        gs: [],
        gsList: getGsEnableDisable([]),
        browseBySubject: false,
      },
      () => {
        this.props.history.push(this.props.match.path);
      }
    );
  }
  handleTermChange(e) {

    this.setState({
      term: e.target.value,
    });
    this.props.history.push(
      this.props.match.path +
      "?term=" + e.target.value
    );
  }
  handleBrowseBySubject() {
    this.setState(
      {
        subject: "",
        catalogNbr: "",
        searchType: "all",
        keywords: "",
        college: [],
        level: [],
        gs: [],
        browseBySubject: false,
      },
      () => {
        this.props.history.push(
          this.props.match.path +
            "/browsebysubject?" +
            UtilFunctions.getCatalogQueryStringFromState(this.state)
        );
      }
    );
  }

  getSearchBySubjectURL(subject,collegeCode) {
    // this.setState({
    //   subject: subject,
    //   college:[collegeCode]
    // },()=>{
    //   this.pushToHistory()
    // });

    const filters = {
      subject: subject,
      term:this.state.term
    }
    return this.props.match.path +
    "/courselist?" + UtilFunctions.getCatalogQueryStringFromState(filters);

  }

  handleOpenAdvancedSearch() {
    var open =
      localStorage.getItem("advancedSearchOpen") === null ||
      localStorage.getItem("advancedSearchOpen") === "false"
        ? "true"
        : "false";
    //open = (open === null || !open) ? true : false

    localStorage.setItem("advancedSearchOpen", open);

    // console.log("panel is now "+localStorage.getItem("advancedSearchOpen") )
  }

  handleDeleteFilter(filter) {
    if (filter !== undefined && filter !== "") {
      const filterText = filter.split("-");
      const filterId = filterText[0];
      var value = null;
      if (filterId === "gs") {
        const gs = filterText[1];

        this.setState(
          (prevState) => {
            const newGsList = prevState.gs;
            newGsList.splice(newGsList.indexOf(gs), 1);
            return {
              gs: newGsList,
              gsList: getGsEnableDisable(newGsList),
            };
          },
          () => {
            this.pushToHistory();
          }
        );
      } else {
        value = [];
        this.setState(
          {
            [filterId]: value,
          },
          () => {
            this.pushToHistory();
          }
        );
      }
    }
  }

  handleInteractiveCourseSelection(subjectSelected) {
    this.setState(
      {
        subject: subjectSelected,
        catalogNbr: "",
      },
      () => {
        this.pushToHistory();
      }
    );
  }

  render() {
    //const path = this.props.location.pathname
    // console.log(path)

    return (
      <Container fluid={true} className="p-0">
        <Header navTree={this.state.navTree}/>
        {/* <Header
          title="Course Catalog Search"
          navTree={[
            { href: "/catalog/classes", text: "Classes", type: "icon", class:"home"},
            { href: "/catalog/classes", text: "Class Search" },
            { href: "/catalog/courses", text: "Course Catalog Search" },

          ]}
        /> */}
        {/**<Menu page="courses" />**/}
        <section style={jumbotronBackground} className="mb-0 pt-5 pb-5" id="main">

              {
                sessionStorage.getItem(serviceauth.SS_VIEW_AS) === "Y" &&
                <div className=" alert-warning container" role="alert">
                  <p>You are viewing as user: {sessionStorage.getItem(serviceauth.SS_VIEW_AS_USER)}</p>
                  {
                    sessionStorage.getItem(serviceauth.SS_IS_STUDENT) === "Y" &&
                    sessionStorage.getItem(serviceauth.SS_IS_ONLINE) === "Y" &&
                    <p>{sessionStorage.getItem(serviceauth.SS_VIEW_AS_USER)} is an ONLINE student</p>
                  }
                  {
                    sessionStorage.getItem(serviceauth.SS_IS_STUDENT) === "Y" &&
                    sessionStorage.getItem(serviceauth.SS_IS_ONLINE) === "N" &&
                    <p>{sessionStorage.getItem(serviceauth.SS_VIEW_AS_USER)} is NOT an ONLINE student</p>

                  }
                  <p>AcadCareer: {sessionStorage.getItem(serviceauth.SS_CAREER)}</p>
                  <p>
                      Staff:
                      {
                        sessionStorage.getItem(serviceauth.SS_IS_STAFF) === "Y"
                        &&
                        " Yes"
                      }
                      {
                        sessionStorage.getItem(serviceauth.SS_IS_STAFF) === "N"
                        &&
                        " No"
                      }

                  </p>

                </div>
              }

              <Container>

                <Row>

                  <Col xs={"auto"} className="p-0">
                    <h1 className=" mt-0">
                      <span className="highlight-white">
                        Course Catalog
                      </span>
                    </h1>
                    {
                      this.state.showArchive &&
                      <a className="white mt-2" href="/catalog/classes">Return to current class search</a>
                    }
                  </Col>
                </Row>

                {/* <Row className="mt-md-1 mt-lg-4 p-3 align-items-center white-box">
                  <Col lg=""> */}
                    <Row className="mt-md-1 mt-lg-4 align-items-center white-box p-3">
                      <Col lg="2" className="p-0 pt-lg-0 pt-2">
                        <Form.Group className=" mb-0">
                          <Form.Label>Term</Form.Label>
                          <Form.Control
                            as="select"
                            className="asu form-control form-select"
                            onChange={this.handleTermChange}
                            usevalueaslabel="false"
                            id="term"
                            closemenuonselect="true"
                            isclearable="false"
                            value={this.state.term}
                            tabIndex={0}
                            aria-label="Select Term"
                          >
                            {this.state.termList != null &&
                              this.state.termList.map((term) => {
                                return (
                                  <option value={term.value} key={term.value}>
                                    {term.label}
                                  </option>
                                );
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg="1" xs="6" className="p-0 pr-1 pr-lg-0 pt-lg-0 pt-2 pe-1 pe-lg-0">
                        <Form.Group className=" mb-0">
                          <Form.Label>Subject</Form.Label>
                          <Autosuggestions
                            term={this.state.term}
                            name="subject"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            value={this.state.subject}
                            className="asu form-control"
                            id="subject"
                            placeholder="Subject"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="1" xs="6" className="p-0 pt-lg-0 pt-2">
                        <Form.Group className=" mb-0">
                          <Form.Label>Number</Form.Label>
                          <Form.Control
                            type="text"
                            inputMode="numeric"
                            placeholder="Number"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            name="catalogNbr"
                            value={this.state.catalogNbr}
                            className="asu"
                            id="catalogNbr"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="3" className="p-0 pt-lg-0 pt-2 ">
                        <Form.Group className=" mb-0">
                          <Form.Label id="keyword-label">Keyword search</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter keywords"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            name="keywords"
                            className="asu form-control"
                            value={this.state.keywords}
                            id="keyword"
                          />
                        </Form.Group>
                      </Col>
                      <Col  className="p-0 pl-lg-2  mt-3 ">
                        <button id="search-button"
                          type="button"
                          className="btn-xl btn btn-gold ms-0 ms-lg-2"
                          onClick={this.handleButtonClick}
                        >
                          <FontAwesomeIcon icon={faSearch} className="me-2" />
                          Search Courses
                        </button>
                      </Col>
                      <Col className="pt-3 ps-0 ps-lg-2">

                              <Link
                              to={{
                                pathname: "/catalog/advanced/courses",
                                search: this.props.location.search,
                              }}
                               >
                                  <span className="text-nowrap">
                                    {
                                      this.props.location.search.indexOf("advanced=true") >= 0 &&
                                      <>Continue Advanced Search</>
                                    }
                                    {
                                      this.props.location.search.indexOf("advanced=true") < 0  &&
                                      <>Advanced Search</>
                                    }


                                    </span>
                              </Link>





                        </Col>
                    </Row>
              </Container>
            </section>

        {
            this.state.showBanner2 === "Y" &&
            <ClassBanner bannerColor="gray" bannerHtml={this.state.banner2}></ClassBanner>
        }

        <InteractiveCourseRecommendation
            onClick={this.handleInteractiveCourseSelection}
            open={this.state.quizOpen}
            ref={this.refToQuiz}
        ></InteractiveCourseRecommendation>

        <Switch>
          <Route
            exact path="/catalog/courses"
            render={() => <CatalogHomeSearch term={this.state.term} />}
          />
          <Route
            exact path="/catalog/courses/courselist/browsebysubject"
            render={() => <BrowseBySubject
            searchBySubjectURL={this.getSearchBySubjectURL}
            term={this.state.term}/>}
          />
          <Route path="/catalog/courses/courselist" component={CourseResults} />
        </Switch>

        <Footer />
      </Container>
    );
  }
}

function getGsEnableDisable(selectedValues) {
  var gsList = StaticData.gsList;
  if (selectedValues.length > 0) {
    for (let i = 0; i < gsList.length; i++) {
      const gs = gsList[i];
      if (
        !selectedValues.includes(gs) &&
        gs.value !== "C" &&
        gs.value !== "G" &&
        gs.value !== "H"
      ) {
        gs.disabled = "yes";
      }
    }
  } else {
    for (let i = 0; i < gsList.length; i++) {
      gsList[i].disabled = "no";
    }
  }

  return gsList;
}



export default Search;