import React, { Component } from "react";
import '../css/App.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Header from "../components/Header";
import Footer from "../components/Footer";
import serviceauth from "../auth/serviceauth";
// import Jumbotron from "react-bootstrap/Jumbotron";
import NewTerm from './NewTerm';
import ActivateTerm from './ActivateTerm';
import DeleteTerm from './DeleteTerm';
import OnOffStuff from './OnOffStuff';
import Scheduler from "./Scheduler";
// import ElasticAdmin from "./ElasticAdmin";
import UserImpersonation from "./UserImpersonation";
import './AdminStyles.css';
import UtilFunctions from '../UtilFunctions';

class AdminTabs extends Component {
    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        this.state = {
            page: localStorage.getItem('activeAdminNavLink') || "newTerm",
            showAdminPage: false,
            showAuthError:false,
            navTree:[]
        }
    }

    async componentDidMount() {
        //localStorage.setItem('activeAdminNavLink', "newTerm");
        fetch(serviceauth.getRequestObject(UtilFunctions.getCheckAdminUserURL(), "GET"))
            .then((response) => {
                
                if (response.status === 401) {
                    serviceauth.reauth();
                }else if(response.status === 403){
                    return {
                        output: false
                    }
                }else
                    return response.json();
            })
            .then(async data => {
                if (data.output){
                    this.setState({
                        showAdminPage: true,
                        navTree:await UtilFunctions.getHeaderNavTree(null)
                    })
                }else{
                    this.setState({
                        showAuthError: true
                    })
                }


            }).catch(function (e) {
                console.log(e);
            });

            
           
    }

    handleClick(e, page) {
        // console.log(page);
        localStorage.setItem('activeAdminNavLink', page);
        this.setState({
            page: page
        })
    }

    render() {
        // const navTree = UtilFunctions.getHeaderNavTree(null)
        return (
            <>
                {
                    this.state.showAuthError &&
                    <Container className="pt-5">FORBIDDEN</Container>
                }
                {
                    this.state.showAdminPage &&
                    <>
                        <Container fluid={true} className="p-0" >
                        <Header navTree={this.state.navTree} /> 

                        
                            <Container id="main" className="mb-5">
                                <h2 className=" pt-4 mb-5">
                                    <span className="highlight-black">
                                        Class Search Settings
                                    </span>
                                </h2>
                                <Row >
                                    <Col xs={2}>
                                        <Nav defaultActiveKey="/home" className="flex-column" variant="pills">
                                            <Nav.Link onClick={(e) => this.handleClick(e, "newTerm")} active={this.state.page === "newTerm"}>New Term</Nav.Link>
                                            <Nav.Link onClick={(e) => this.handleClick(e, "activateTerm")} active={this.state.page === "activateTerm"}>Activate Term</Nav.Link>
                                            <Nav.Link onClick={(e) => this.handleClick(e, "deleteTerm")} active={this.state.page === "deleteTerm"}>Delete Term</Nav.Link>
                                            <Nav.Link onClick={(e) => this.handleClick(e, "onOffStuff")} active={this.state.page === "onOffStuff"}>Turn on/off</Nav.Link>
                                            <Nav.Link onClick={(e) => this.handleClick(e, "scheduler")} active={this.state.page === "scheduler"}>Scheduler</Nav.Link>
                                            <Nav.Link onClick={(e) => this.handleClick(e, "userImpersonation")} active={this.state.page === "userImpersonation"}>User Impersonation</Nav.Link>
                                        </Nav>
                                    </Col>
                                    <Col>
                                        {
                                            (this.state.page === "newTerm" &&
                                            <NewTerm />)

                                            ||

                                            (this.state.page === "activateTerm" &&
                                            <ActivateTerm />)

                                            ||

                                            (this.state.page === "deleteTerm" &&
                                            <DeleteTerm />)

                                            ||

                                            (this.state.page === "onOffStuff" &&
                                            <OnOffStuff />)

                                            ||

                                            (this.state.page === "scheduler" &&
                                            <Scheduler />)

                                            ||

                                            // this.state.page === "elasticAdmin" &&
                                            // <ElasticAdmin />

                                            // ||

                                            (this.state.page === "userImpersonation" &&
                                            <UserImpersonation />)
                                        }
                                    </Col>
                                </Row>
                            </Container>
                            </Container>
                        <Footer/>
                    </>
                }


            </>
        )
    }
}

export default AdminTabs;