import React, { Component } from 'react';
import '../css/App.css'
import serviceauth from "../auth/serviceauth";
import UtilFunctions from '../UtilFunctions';
// import isEqual from 'lodash.isequal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse'
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ClassRow from './ClassRow';
import { Button } from 'react-bootstrap';
import { spinnerService } from '@simply007org/react-spinners';

class RelatedClasses extends Component {
    constructor(props) {
        super()
        this.state = {
            title: [],
            classComponents: null,
            selectedRelatedClasses: [],
            toggleAccordion: [],//to control closing dropdown when selecting  class
            areAllSelected: false, //to disable/enable add button
            modalShow: false//to control late add popup
        }
        this.handleSelectRelatedClass = this.handleSelectRelatedClass.bind(this)
        this.toggleAccordion = this.toggleAccordion.bind(this)
        this.handleCancelRelatedClass = this.handleCancelRelatedClass.bind(this)
        this.handleAddClasses = this.handleAddClasses.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.handleModalHide = this.handleModalHide.bind(this)
    }

    componentDidMount() {
        // if(prevProps.crseId !== this.props.crseId ||!isEqual(this.props.classItem,prevProps.classItem)){
        //if (this.props.relatedClassesOpen && (prevProps.crseId !== this.props.crseId || !isEqual(this.props.classItem, prevProps.classItem))) {
        // console.log("going to update related classes details")
        var classItem = this.props.classItem
        const newParams = {
            term: classItem.CLAS.STRM,
            campus: classItem.CLAS.CAMPUS !== null && classItem.CLAS.CAMPUS === "ASUONLINE" ? classItem.CLAS.CAMPUS : null,
            searchType: this.props.searchType,
            course_id: classItem.CLAS.CRSEID,
            campusOrOnlineSelection: classItem.CLAS.LOCATION != null && classItem.CLAS.LOCATION.trim() === ("ASUONLINE") ? "O" : "C",
            crseOffrNbr: classItem.CLAS.CRSEOFFERNBR,
            associatedClass: classItem.CLAS.ASSOCIATEDCLASS !== null ? classItem.CLAS.ASSOCIATEDCLASS : " "
        }
        // //Load related classes
        // console.log(UtilFunctions.getClassSearchUrl(newParams, []))
        fetch(serviceauth.getRequestObject(UtilFunctions.getClassSearchUrl(newParams, []), "GET"))
            .then((response) => {
                if (response.status === 401) {
                    serviceauth.reauth();
                }
                return response.json();
            })
            .then(async classes => {
                // console.log(classes)
                var classComponents = []//new LinkedHashMap<String,List<AsuPsClassViewBean>>()
                var componentsDescr = new Set()//LinkedHashSet<String> componentsDescr
                var text = []


                if (classes !== null && classes.classes.length > 0) {

                    const classesWithSeats = classes.classes


                    var componentTree = new Map();
                    var lectures = [];

                    for (let index = 0; index < classesWithSeats.length; index++) {

                        const clas = classesWithSeats[index];
                        // console.log(clas.CLAS.CLASSNBR)
                        if (clas.CLAS.COMPONENTPRIMARY !== null && clas.CLAS.COMPONENTPRIMARY !== classItem.CLAS.COMPONENTPRIMARY) {
                            if (clas.CLAS.COMPONENTPRIMARY === "LEC")
                                lectures.push(clas)
                            else {
                                var key = clas.CLAS.DESCR2 + " (" + clas.CLAS.COMPONENTPRIMARY + ")"
                                var myComponents = componentTree.get(key)
                                if (myComponents === undefined)
                                    myComponents = []

                                myComponents.push(clas);
                                componentTree.set(key, myComponents)

                            }
                            componentsDescr.add(clas.CLAS.DESCR2);
                        }
                    }

                    if (lectures.length > 0)
                        classComponents.push(["Lecture (LEC)", lectures]);


                    for (let [key, value] of componentTree) {
                        classComponents.push([key, value])

                    }

                    for (let componentDescr of componentsDescr) {//for (String componentDescr : componentsDescr)  {
                        text.push(componentDescr)
                    }

                    //initializing the selected classes
                    var selectedRelatedClasses = []
                    var toggleAccordion = []
                    for (let index = 0; index < classComponents.length; index++) {
                        selectedRelatedClasses.push(null)
                        toggleAccordion.push(false)
                    }



                    this.setState((prevState) => {
                        const state = {
                            title: text,
                            classComponents: classComponents,
                            selectedRelatedClasses: selectedRelatedClasses,
                            toggleAccordion: toggleAccordion
                        }

                        spinnerService.hide('mySpinner');

                        return state
                    })


                    //console.log(text)

                }










                //     return state


            }).catch(function (e) {
                console.log(e);
            });






        // }else console.log("not going to update related classes details")

    }

    handleSelectRelatedClass(e, classItem, index) {
        if (e.target.nodeName !== 'A') {//only select if the click is not coming from a link  click. 
            var selectedRelatedClasses = this.state.selectedRelatedClasses
            selectedRelatedClasses[index] = classItem
            var toggleAccordion = this.state.toggleAccordion
            toggleAccordion[index] = !toggleAccordion[index]

            var areAllSelected = true
            selectedRelatedClasses.forEach(clas => {
                if (clas === null)
                    areAllSelected = false
            })

            this.setState({
                selectedRelatedClasses: selectedRelatedClasses,
                toggleAccordion: toggleAccordion,
                areAllSelected: areAllSelected
            })
        }

    }

    toggleAccordion(e, index) {
        var toggleAccordion = this.state.toggleAccordion
        toggleAccordion[index] = !toggleAccordion[index]
        this.setState({
            toggleAccordion: toggleAccordion
        })
    }

    handleCancelRelatedClass(e, index) {
        var selectedRelatedClasses = this.state.selectedRelatedClasses
        selectedRelatedClasses[index] = null

        var areAllSelected = true
        selectedRelatedClasses.forEach(clas => {
            if (clas === null)
                areAllSelected = false
        })

        this.setState({
            selectedRelatedClasses: selectedRelatedClasses,
            areAllSelected: areAllSelected,
        })
    }

    handleAddClasses(e) {
        //url should always have the lec class first, so we need to find it
        var classItem = null;
        var relClass1 = this.state.selectedRelatedClasses[0].CLAS.CLASSNBR
        var relClass2 = this.state.selectedRelatedClasses[1] !== undefined ? this.state.selectedRelatedClasses[1].CLAS.CLASSNBR : ""

        if (this.props.classItem.CLAS.CLASSTYPE === "E") {
            classItem = this.props.classItem;
        } else {
            //search the selected related classes for the lec
            if (this.state.selectedRelatedClasses[0].CLAS.CLASSTYPE === "E") {
                classItem = this.state.selectedRelatedClasses[0];
                relClass1 = this.props.classItem.CLAS.CLASSNBR;
            } else if (this.state.selectedRelatedClasses[1] !== undefined && this.state.selectedRelatedClasses[1].CLAS.CLASSTYPE === "E") {
                classItem = this.state.selectedRelatedClasses[1];
                relClass2 = this.props.classItem.CLAS.CLASSNBR;
            }
        }

        // console.log("class "+classItem.CLAS.CLASSNBR + " rel1: "+relClass1 + " rel2: "+relClass2)


        this.props.onClick(e, classItem.CLAS.STRM, classItem.CLAS.CLASSNBR, relClass1, relClass2)
    }

    handleModalShow() {
        this.setState({
            modalShow: true
        })

    }

    handleModalHide() {
        this.setState({
            modalShow: false
        })

    }

    render() {
        //to control css background of each row
        var odd = false;
        const isPastEnrollmentDeadline = UtilFunctions.isPastEnrollmentDeadline(this.props.classItem.CLAS.ENROLLDEADLINE)
      
        return (
            <Container>
                <Row>
                    <Col>
                        {this.state.title.length > 0 &&
                            <p className="font-italic">
                                This class requires a
                                {this.state.title.map((type, i) => {
                                    if (i > 0)
                                        return <> and a <span className='font-weight-bold'> {type}</span> related class</>
                                    else
                                        return <><span className='font-weight-bold'> {type}</span> related class</>
                                })}.
                            </p>
                        }


                    </Col>
                </Row>
                <Row>
                    <Col className="related-classes">
                        {
                            this.state.classComponents !== null &&


                            //getDropdown(this.state.classComponents, this.props.searchType,this.state.selectedRelatedClasses)
                            this.state.classComponents.map((keyValue, classComponentTypeIndex) => {
                                const key = keyValue[0]
                                const componentsList = keyValue[1]
                                // const dropdownId = getDropdownId(key)
                                // console.log(keyValue);
                                odd = !odd
                                const icon = this.state.toggleAccordion[classComponentTypeIndex] ? faChevronUp : faChevronDown
                                return (
                                    <Card className="mb-2 border-bottom-0 ">
                                        <Card.Header onClick={(e) => this.toggleAccordion(e, classComponentTypeIndex)} className='border-bottom'>
                                            {this.state.selectedRelatedClasses[classComponentTypeIndex] === null &&
                                                <div className='row ms-1 '>
                                                    <div className="col-10 col-sm-11 select-title pb-1 ">Select {key}</div>
                                                    <div className="col-1   " >
                                                        <button aria-pressed="false" className=" transparent" tabIndex={0} >
                                                            <FontAwesomeIcon icon={icon} />
                                                        </button>
                                                    </div>

                                                </div>
                                            }

                                            {
                                                this.state.selectedRelatedClasses[classComponentTypeIndex] !== null &&
                                                <div className='row ms-2'>
                                                    <div className="col-10 col-md-11 ">
                                                        <ClassRow
                                                            classItem={this.state.selectedRelatedClasses[classComponentTypeIndex]}
                                                            searchType={this.props.searchType}
                                                            showMoreInfo={true}
                                                            isRelatedClass={true}
                                                            cancelRelatedClasses={(e) => this.handleCancelRelatedClass(e, classComponentTypeIndex)}
                                                            oddEvenClass={odd} />
                                                    </div>
                                                    <div className="col-2 col-md-1 pt-2">
                                                        <button aria-pressed="false" className=" transparent" tabIndex={0} >
                                                            <FontAwesomeIcon icon={icon} />
                                                        </button>
                                                    </div>
                                                </div>
                                            }




                                        </Card.Header>



                                        <Collapse in={this.state.toggleAccordion[classComponentTypeIndex]} mountOnEnter={true} >
                                            <ListGroup variant="flush" className='ps-1'>
                                                <table class="table table-hover related-classes-tbl mt-3 " >
                                                    {
                                                        componentsList !== null &&
                                                        componentsList.map((component, index) => {
                                                            odd = !odd
                                                            return (
                                                                    //do not show row if already selected
                                                                   
                                                                    !isClassSelected(this.state.selectedRelatedClasses,component.CLAS.CLASSNBR) &&
                                                                    <ClassRow classItem={component}
                                                                        classComponentTypeIndex={classComponentTypeIndex}
                                                                        key={index} searchType={this.props.searchType}
                                                                        showMoreInfo={true}
                                                                        isRelatedClass={true}
                                                                        onClick={this.handleSelectRelatedClass}
                                                                        oddEvenClass={odd} />
                                                                
                                                            )
                                                        })
                                                    }

                                                </table>
                                            </ListGroup>
                                        </Collapse>

                                    </Card>
                                )
                            })

                        }
                    </Col>
                </Row>
                {
                    this.state.classComponents !== null &&

                    <Row >
                        <Col>
                            {
                                // this.props.classItem.CLAS.PASSENROLLDEADLINE === "Y" &&
                                isPastEnrollmentDeadline &&
                                // <OverlayTrigger trigger="click" placement="right" overlay={UtilFunctions.getPopover(this.props.classItem)} rootClose={true}>
                                <button
                                    type="button"
                                    className="btn btn-gray btn-md  mb-2 "
                                    onClick={this.handleModalShow}>
                                    Info
                                </button>
                                // </OverlayTrigger>


                            }
                            {
                                // this.props.classItem.CLAS.PASSENROLLDEADLINE !== "Y" &&
                                !(isPastEnrollmentDeadline) &&
                                <Button className="btn btn-maroon btn-md  mb-2 mt-2 " onClick={this.handleAddClasses} disabled={!this.state.areAllSelected}>Add</Button>
                            }
                            {
                                UtilFunctions.getLateAddModal(this.props.classItem, this.state.modalShow, this.handleModalHide)
                            }
                        </Col>

                    </Row>
                }

            </Container>
        )

    }

}

export default RelatedClasses





function isClassSelected(list, checkThisClass) {
    // console.log(list)
    // console.log(checkThisClass)
    if(list && checkThisClass){
        if (list.some(e => e !== null && e.CLAS.CLASSNBR === checkThisClass)) {
            return true;
        }
    }   
    
   
    return false;
}