import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons'

const classSearchSortingFilters = [
    { id: "course", order: "asc", label: "Course A-Z" },
    { id: "course", order: "desc", label: "Course Z-A" },
    { id: "instructor", order: "asc", label: "Instructor A-Z" },
    { id: "instructor", order: "desc", label: "Instructor Z-A" },
    { id: "location", order: "asc", label: "Location A-Z" },
    { id: "location", order: "desc", label: "Location Z-A" },
    { id: "starttime", order: "asc", label: "Start time" },
    // {id:"openseats",order:"desc",label:"Open Seats high-low"},
    // {id:"openseats",order:"asc",label:"Open Seats low-high"},

]

const courseCatalogSortingFilters = [
    { id: "course", order: "asc", label: "Course A-Z" },
    { id: "course", order: "desc", label: "Course Z-A" },
    { id: "title", order: "asc", label: "Title A-Z" },
    { id: "title", order: "desc", label: "Title Z-A" },
    { id: "units", order: "asc", label: "Units A-Z" },
    { id: "units", order: "desc", label: "Units Z-A" },

]
class SortingColumns extends Component {

    constructor(props) {
        super(props)
        let id = "course";
        let order = "asc"
        let sortingFilters = props.isCourseCatalog ? courseCatalogSortingFilters : classSearchSortingFilters;
        this.state = {
            sortBy:id,
            order:order,
            placeholder: getPlaceholder(id,order,sortingFilters),
            sortingFilters: sortingFilters
        }
        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {
        if (typeof this.props.sortby === 'undefined') {
            this.setState({
                sortingBy: "course-asc"
            })
        } else {//bookmarking or reload
            this.setState({
                sortingBy: this.props.sortby + "-" + this.props.direction
            })
        }
    }

    // shouldComponentUpdate(nextProps, nextState){
    //     console.log(JSON.stringify(nextState) + " - " + JSON.stringify(this.state)) 
    //     console.log(JSON.stringify(nextProps.sortby) + " - " + this.props.sortby) 
    //     if((nextState.sortingBy !== this.state.sortingBy) ||
    //         ( typeof nextProps.sortby  === 'undefined' && typeof this.props.sortby !== 'undefined')){
    //             console.log("will update")
    //         return true
    //     }else{
    //          console.log("will not update")
    //         return false
    //     }
    // }

    // static getDerivedStateFromProps(props, state){
    //     // return{
    //     //     sortingBy: getSortId(props.sortby,props.direction)
    //     // }
    // }

    componentDidUpdate(prevProps, prevState) {
        // console.log("in componentDidUpdate for sorting") 
        if ((prevProps.sortby !== this.props.sortby) || (prevProps.direction !== this.props.direction)) {
            console.log("in componentDidUpdate for sorting")
            this.setState({
                sortingBy: getSortId(this.props.sortby, this.props.direction),
            })
        }


    }

    handleChange(id, order) {

        this.setState({
            placeholder : getPlaceholder(id,order,this.state.sortingFilters),
            sortBy:id,
            order: order,
        },()=>{
            this.props.onChange(id, order);
        })
        

    }


    render() {
        return (
                <Dropdown className="filter-dropdown sort-dropdown" >
                    <Dropdown.Toggle active={true} bsPrefix="custom-dropdown-toggle " variant="sort" >
                        {this.state.placeholder}
                        <div className='pull-right ms-1'><FontAwesomeIcon icon={faChevronDown} /></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            this.state.sortingFilters.map((column, index) => {
                                return<Dropdown.Item 
                                onClick={() => this.handleChange(column.id,column.order)}  
                                key={column.label}
                                className={(column.id === this.state.sortBy && column.order === this.state.order) ? "selected" : ""}>
                                    <>
                                    {column.id === this.state.sortBy && column.order === this.state.order &&
                                        <FontAwesomeIcon icon={faCheck} className='icon' />
                                    
                                    }
                                    {column.label}
                                    </>
                                    
                                </Dropdown.Item> //<div value={`${column.id}-${column.order}`} key={column.label} className='mt-1'>{column.label}</div>
                            })

                        }
                    </Dropdown.Menu>

                </Dropdown>


        )

    }





}

export default SortingColumns


function getSortId(sortBy, direction) {
    if (!sortBy && !direction)
        return "course-asc"
    else
        return sortBy + "-" + direction
}

const getPlaceholder = (id,order,sortingFilters) =>{
   let placeholder =  sortingFilters.find(obj => {return obj.id === id && obj.order === order}); 
   return "Sort by: "+placeholder.label;
}