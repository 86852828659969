import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./css/CatalogHomeSearch.css";
import StaticData from './StaticData';
import UtilFunctions from './UtilFunctions';
import serviceauth from "./auth/serviceauth";
// import ViewAllSubjects from "./components/ViewAllSubjects";
import { useHistory, useLocation,Link } from "react-router-dom";

function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('./images/colleges', false, /\.(png|jpe?g|svg)$/));

const CatalogHomeSearch = (props) => {

    const history = useHistory();
    const location = useLocation();

    const [collegeSubjectsMap, setCollegeSubjectsMap] = useState({});
    // const [showAllSubjectsList, setShowAllSubjectsList] = useState(false);
    //const [activeTabIndex, setActiveTabIndex] = useState(0);

    useEffect(() => {
        const term = props.term;
        //const term = 2227; 
        // console.log(Object.keys(collegeSubjectsMap).length);
        if (term !== undefined && term !== "" && Object.keys(collegeSubjectsMap).length === 0) {
            fetch(serviceauth.getRequestObject(UtilFunctions.getSubjectListUrl(term), "GET"))
                .then(response => {
                    if (response.status === 401) {
                        console.log("error 401")
                        serviceauth.reauth();
                    }
                    return response.json();
                })
                .then(responseMap => {
                    // console.log(responseMap)
                    setCollegeSubjectsMap(responseMap)
                }).catch(err => {
                    console.log(err.response)
                });
        } 
        // else {
        //     console.log("term info missing");
        // }
    }, [props,collegeSubjectsMap])


    const handleViewAllSubjectsClick = (e) => {
        // e.preventDefault();
        // setShowAllSubjectsList(true);
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth",
        // });
        history.push(
            location.pathname + "/courselist/browsebysubject"
        );
    }

    // const loadCoursesForCollege = (collegeCode) => {
    //     const params = {
    //         'college': collegeCode,
    //         'term': props.term,
    //     }
    //     pushToHistory(params);
    // }

    // const loadCoursesForCollegeAndSubject = (subjectCode, collegeCode) => {

    //     const params = {
    //         'college': [collegeCode],
    //         'subject': subjectCode,
    //         'term': props.term,
    //     }
    //     pushToHistory(params);
    // }

    // const pushToHistory = (params) => {
    //     history.push(
    //         location.pathname + "/courselist?" + UtilFunctions.getCatalogQueryStringFromState(params)
    //     );
    // }

    // const showSubjectsForCollege = (collegeSubjectsList, collegeCode) => <div>
    //     {collegeSubjectsList.map(({ SUBJECT, SUBJECTDESCR }) =>
    //         <div class="row pb-2 college-subject" key={SUBJECT} onClick={() => loadCoursesForCollegeAndSubject(SUBJECT, collegeCode)}>
    //             <div class="col-2 font-weight-bold font-color-asu">{SUBJECT}</div>
    //             <div class="col-10 font-color-asu">{SUBJECTDESCR}</div>
    //         </div>
    //     )}
    // </div>


    const collegesCardsAndTabView =
        <Container className="mt-md-3 pr-md-0 pl-md-0" id="catalog-home-search" >
            <div id="catalog-college-cards">

                <div className="row">
                    <div className="col-sm-9"><p className="bigTitle mt-5 mb-4">Explore courses by College or School </p></div>
                    <div className="col-sm-3">
                        <button
                            className="btn btn-maroon float-lg-end mt-sm-5 "
                            type="button"
                            onClick={handleViewAllSubjectsClick}>
                            View all subjects
                        </button>
                    </div>
                </div>

                <div className="row cards-row row-spaced pt-2 pb-2 mb-0">
                    {StaticData.collegeList.map(({ value, label }) =>
                        <div className="col col-12 col-md-6 col-lg-3" key={value}>
                            <Link to={`/catalog/courses/courselist?${UtilFunctions.getCatalogQueryStringFromState({
                                'college': [value],
                                'term': props.term,
                            })}`}
                                className="card h-100">
                                <img
                                    alt=""
                                    className="card-img-top"
                                    src={images['college-' + value + '.jpg'] || images['college-default.jpg']}
                                    max-height="160"
                                />
                                <div className="card-body">
                                    <h3 className="card-title mt-0 mb-0">
                                        {label}
                                    </h3>
                                </div>
                            </Link>
                        </div>
                    )}

                </div>
            </div>

            {/* <div id="catalog-college-tabs" class="mb-10 mt-5">
                <div class="row justify-content-between ml-0 mr-0 align-items-center">
                    <p className="bigTitle mt-5 mb-4">Browse by subject</p>
                    <button
                        class="btn btn-maroon h-50"
                        type="button"
                        onClick={handleViewAllSubjectsClick}
                    >
                        View all subjects
                    </button>
                </div>

                

                <div class="row row-spaced pt-1 pb-2">
                    <div class="col-12">
                        <div class="">
                            <nav class="uds-tabbed-panels uds-content-align">
                                <div class="nav nav-tabs" data-scroll-position="0" id="nav-tab" role="tablist">
                                    {StaticData.collegeList.map((collegeInfo, index) =>
                                        <a
                                            aria-controls={"nav-" + collegeInfo.value}
                                            aria-selected={activeTabIndex === index ? "true" : "false"}
                                            class={activeTabIndex === index ? "nav-item nav-link active" : "nav-item nav-link"}
                                            data-ga={collegeInfo.label}
                                            data-ga-action="click"
                                            data-ga-event="link"
                                            data-ga-name="onclick"
                                            data-ga-type="internal link"
                                            data-toggle="tab"
                                            href={"#nav-" + collegeInfo.value}
                                            id={"nav-" + collegeInfo.value + "-tab"}
                                            role="tab"
                                            onClick={() => setActiveTabIndex(index)}
                                            key={collegeInfo.value}
                                        >
                                            {collegeInfo.label}
                                        </a>
                                    )}
                                </div>

                                <a
                                    class="scroll-control-prev"
                                    data-ga="left chevron"
                                    data-ga-action="click"
                                    data-ga-event="select"
                                    data-ga-name="onclick"
                                    data-ga-region="main content"
                                    data-ga-type="carousel"
                                    data-scroll="prev"
                                    href="#carouselExampleControls"
                                    role="button"
                                    tabIndex="-1"
                                >
                                    <span
                                        aria-hidden="true"
                                        class="carousel-control-prev-icon"
                                    />
                                    <span class="sr-only">
                                        Previous
                                    </span>
                                </a>
                                <a
                                    class="scroll-control-next"
                                    data-ga="right chevron"
                                    data-ga-action="click"
                                    data-ga-event="select"
                                    data-ga-name="onclick"
                                    data-ga-region="main content"
                                    data-ga-type="carousel"
                                    data-scroll="next"
                                    href="#carouselExampleControls"
                                    role="button"
                                    tabIndex="-1"
                                >
                                    <span
                                        aria-hidden="true"
                                        class="carousel-control-next-icon"
                                    />
                                    <span class="sr-only">
                                        Next
                                    </span>
                                </a>

                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                {StaticData.collegeList.map((collegeInfo, index) =>
                                    <div
                                        aria-labelledby={"nav-" + collegeInfo.value + "-tab"}
                                        class={activeTabIndex === index ? "subjects-tab tab-pane fade show active" : "tab-pane fade"}
                                        id={"nav-" + collegeInfo.value}
                                        role="tabpanel"
                                        key={collegeInfo.value}
                                    >
                                        {collegeSubjectsMap[collegeInfo.value] !== undefined ? showSubjectsForCollege(collegeSubjectsMap[collegeInfo.value], collegeInfo.value) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Container>


    return <React.Fragment>
       {collegesCardsAndTabView}
        {/* {showAllSubjectsList === false ? collegesCardsAndTabView : <ViewAllSubjects
            collegeWiseSubjectsMap={collegeSubjectsMap}
            loadCoursesForListCollegeAndSubject={loadCoursesForCollegeAndSubject}
        />} */}
    </React.Fragment>

}

export default CatalogHomeSearch;